import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SentryCategories from 'constants/sentryCategories';
import type { AxiosError } from 'axios';
import { get, includes, isEmpty, isNil, toString } from 'lodash';
import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import useStateMachine from '@cassiozen/usestatemachine';
import { useIonModal } from '@ionic/react';
import { Device as DeviceIdentityVault } from '@ionic-enterprise/identity-vault';
import * as Sentry from '@sentry/capacitor';
import useFindActiveEmployees from 'ActivitiesApp/api/useFindActiveEmployees';
import { and, or } from 'common/utils/logicHelpers';
import {
  RateLimitErrorURL,
  TeaPotErrorURL,
  loginURL,
  tabsURL,
} from 'navigation';
import { useDebounce } from 'use-debounce';
import useFindSlidesBulletins from 'api/bulletins/useFindSlidesBulletins';
import useGetProductGroups from 'api/productgroups/useGetProductGroups';
import useGetAccessControl from 'api/user/useGetAccessControl';
import useGetUnreadNoticesCount from 'api/user/useGetUnreadNoticesCount';
import useGetWhoAmI from 'api/user/useGetWhoAmI';
import useSignin, { ModelLoginEnum } from 'api/user/useSignin';
import usePushNotifications from 'hooks/usePushNotifications';
import type { SignInResponse, WhoAmIResponse } from 'models/Signin';
import AuthTokenStorage from 'storage/AuthTokenStorage';
import UserInfoStorage from 'storage/UserInfoStorage';
import UsernameStorage from 'storage/UsernameStorage';
import type { RootState } from 'store/reducers';
import {
  logout,
  clearUserData,
  setUserData,
  setAppMode,
  AppModeEnum,
} from 'store/user';
import { RATE_LIMIT_ERROR_CODE, TEA_POT_ERROR_CODE } from 'utils/constants';
import { setAsyncTimeout } from 'utils/helpers';
import Blank from 'navigation/MainNavigator/BlankPage';
import AuthPinModal from 'components/Modals/AuthPinModal/AuthPinModal';
import { useAxios } from './AxiosProvider';
import { useDevice } from './DeviceProvider';
import classes from './Providers.module.scss';
import { UnlockMode, useVault } from './VaultProvider';

export enum AuthStates {
  VALIDATING_SESSION = 'VALIDATING_SESSION',
  HAS_VALID_SESSION = 'HAS_VALID_SESSION',
  INIT_APP = 'INIT_APP',
  APP_IS_READY = 'APP_IS_READY',
  NO_VALID_SESSION = 'NO_VALID_SESSION',
  LOGIN_IS_READY = 'LOGIN_IS_READY',
  VALIDATING_VAULT_LOGIN = 'VALIDATING_VAULT_LOGIN',
  LOGIN_WITHOUT_VAULT_CREDENTIALS = 'LOGIN_WITHOUT_VAULT_CREDENTIALS',
  SENDING_LOGIN_CREDENTIALS = 'SENDING_LOGIN_CREDENTIALS',
  ASKING_TO_SET_VAULT_CREDENTIALS = 'ASKING_TO_SET_VAULT_CREDENTIALS',
  SAVING_VAULT_CREDENTIALS = 'SAVING_VAULT_CREDENTIALS',
  LOGOUT_BY_RATE_LIMIT = 'LOGOUT_BY_RATE_LIMIT',
  LOGOUT_BY_TEA_POT_ERROR = 'LOGOUT_BY_TEA_POT_ERROR',
}

export enum AuthStateEvents {
  TOKEN_IS_VALID = 'TOKEN_IS_VALID',
  TOKEN_IS_NOT_VALID = 'TOKEN_IS_NOT_VALID',
  INITIALIZE_APP = 'INITIALIZE_APP',
  GO_TO_DEFAULT_TAB = 'GO_TO_DEFAULT_TAB',
  GO_TO_LOGIN = 'GO_TO_LOGIN',
  VALIDATE_VAULT_LOGIN = 'VALIDATE_VAULT_LOGIN',
  VAULT_LOGIN_NOT_ENABLED = 'VAULT_LOGIN_NOT_ENABLED',
  RETRY_VAULT_LOGIN = 'RETRY_VAULT_LOGIN',
  SEND_LOGIN_CREDENTIALS = 'SEND_LOGIN_CREDENTIALS',
  ERROR_WITH_LOGIN_CREDENTIALS = 'ERROR_WITH_LOGIN_CREDENTIALS',
  ASK_TO_SET_VAULT_CREDENTIALS = 'ASK_TO_SET_VAULT_CREDENTIALS',
  SAVE_VAULT_CREDENTIALS = 'SAVE_VAULT_CREDENTIALS',
  DO_NOT_SAVE_VAULT_CREDENTIALS = 'DO_NOT_SAVE_VAULT_CREDENTIALS',
  SUCCESSFUL_LOGIN = 'SUCCESSFUL_LOGIN',
  LOGOUT = 'LOGOUT',
  RATE_LIMIT_ERROR = 'RATE_LIMIT_ERROR',
  TEA_POT_ERROR = 'TEA_POT_ERROR',
}

export interface AuthStateEvent {
  type: AuthStateEvents;
  payload?: unknown;
}

interface AuthStateEffect {
  event?: AuthStateEvent;
  send: (event: AuthStateEvent) => void;
}

interface AuthStateContextProps {
  state: AuthStates;
  loginError?: AxiosError;
  send: (event: AuthStateEvent) => void;
  event?: AuthStateEvent;
}

const AuthStateContext = createContext<AuthStateContextProps>({
  state: AuthStates.VALIDATING_SESSION,
  loginError: undefined,
  send: () => null,
  event: undefined,
});

const AuthStateProvider = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { triggerInvalidToken } = useAxios();

  const {
    subscribePushNotifications,
    listenNotifications,
    unsubscribeNotifications,
  } = usePushNotifications();
  const {
    showPinModal,
    isSetPasscodeMode,
    isLockedAfterBackgrounded,
    getAuthToken,
    setAuthToken,
    getUnlockMode,
    updateUnlockMode,
    handlePasscodeRequest,
    clearVault,
  } = useVault();
  const { appMode, notificationRegistered } = useSelector(
    (state: RootState) => state.user
  );
  const [signInData, setSignInData] = useState<SignInResponse>();
  const [loginError, setLoginError] = useState<AxiosError>();
  const [redirectOverrideURL, setRedirectOverrideURL] = useState<string>();

  // TODO: consider alternative approaches to handling Sentry username
  useEffect(() => {
    Sentry.setUser({
      username: signInData?.userInfo?.userid,
    });
  }, [signInData?.userInfo]);

  const { getWhoAmI } = useGetWhoAmI();
  const { signin } = useSignin();
  const { lastVisitedURL } = useDevice();

  useGetAccessControl();
  useGetProductGroups();
  useFindSlidesBulletins();
  useGetUnreadNoticesCount({});

  const [machine, rootSend] = useStateMachine()({
    initial: AuthStates.VALIDATING_SESSION,
    states: {
      [AuthStates.VALIDATING_SESSION]: {
        on: {
          [AuthStateEvents.TOKEN_IS_VALID]: AuthStates.HAS_VALID_SESSION,
          [AuthStateEvents.TOKEN_IS_NOT_VALID]: AuthStates.NO_VALID_SESSION,
          [AuthStateEvents.VAULT_LOGIN_NOT_ENABLED]:
            AuthStates.LOGIN_WITHOUT_VAULT_CREDENTIALS,
        },
        effect: ({ send }: AuthStateEffect) => {
          const getToken = async () => {
            // handle the migration of capacitor storage v2 to v3
            const migrationResults = await Preferences.migrate();
            if (migrationResults.migrated) {
              Sentry.addBreadcrumb({
                type: 'debug',
                category: SentryCategories.STORAGE,
                message: `Preferences migration completed. The following keys were migrated and will now be removed: ${migrationResults.migrated.toString()}`,
                level: 'debug',
              });
              await Preferences.removeOld();
            }
            const unlockType = getUnlockMode();
            try {
              let token;
              // this if condition is start of migration
              if (unlockType === UnlockMode.BiometricsScreenLock) {
                const isBiometricEnabled =
                  await DeviceIdentityVault.isBiometricsEnabled();
                const isSystemPinEnabled =
                  await DeviceIdentityVault.isSystemPasscodeSet();
                if (!isBiometricEnabled) {
                  await updateUnlockMode(UnlockMode.NeverLock);
                } else {
                  if (isBiometricEnabled && !isSystemPinEnabled) {
                    await updateUnlockMode(UnlockMode.Biometrics);
                  }
                  token = await getAuthToken();
                  if (!token) {
                    token = await AuthTokenStorage.get();
                    if (token) {
                      await setAuthToken(token);
                    }
                  }
                }
              } else if (unlockType === UnlockMode.Biometrics) {
                token = await getAuthToken();
              }
              if (!isEmpty(token)) {
                send({ type: AuthStateEvents.TOKEN_IS_VALID });
              } else {
                send({ type: AuthStateEvents.TOKEN_IS_NOT_VALID });
              }
            } catch (err) {
              history.replace(loginURL());
              send({ type: AuthStateEvents.VAULT_LOGIN_NOT_ENABLED });
              setTimeout(() => {
                void SplashScreen.hide();
              }, 300);
            }
          };

          void getToken();
        },
      },
      [AuthStates.HAS_VALID_SESSION]: {
        on: {
          [AuthStateEvents.INITIALIZE_APP]: AuthStates.INIT_APP,
          [AuthStateEvents.TOKEN_IS_NOT_VALID]: AuthStates.NO_VALID_SESSION,
          [AuthStateEvents.ASK_TO_SET_VAULT_CREDENTIALS]:
            AuthStates.ASKING_TO_SET_VAULT_CREDENTIALS,
        },
        effect: ({ send }: AuthStateEffect) => {
          const doWhoAmI = async () => {
            try {
              const whoAmIData = await getWhoAmI();
              setSignInData(whoAmIData);
              if (
                getUnlockMode() === UnlockMode.BiometricsScreenLock ||
                getUnlockMode() === UnlockMode.Biometrics ||
                getUnlockMode() === UnlockMode.CustomPIN
              ) {
                send({ type: AuthStateEvents.INITIALIZE_APP });
              } else {
                send({
                  type: AuthStateEvents.ASK_TO_SET_VAULT_CREDENTIALS,
                });
              }
            } catch (e) {
              send({ type: AuthStateEvents.TOKEN_IS_NOT_VALID });
            }
          };
          void doWhoAmI();
        },
      },
      [AuthStates.INIT_APP]: {
        on: {
          [AuthStateEvents.GO_TO_DEFAULT_TAB]: AuthStates.APP_IS_READY,
          [AuthStateEvents.TOKEN_IS_NOT_VALID]: AuthStates.NO_VALID_SESSION,
        },
        effect: ({ send }: AuthStateEffect) => {
          const doInitApp = () => {
            try {
              const whoAmIData: WhoAmIResponse = {
                ...signInData,
              };
              dispatch(setUserData(whoAmIData));
              dispatch(
                setAppMode({
                  appMode:
                    appMode ||
                    (process.env.REACT_APP_FEATURE_SERVICES === 'true'
                      ? AppModeEnum.miproservices
                      : AppModeEnum.mipro),
                })
              );

              // Store userId, so we can keep track of new user signin
              send({ type: AuthStateEvents.GO_TO_DEFAULT_TAB });
            } catch (e) {
              send({ type: AuthStateEvents.TOKEN_IS_NOT_VALID });
            }
          };
          void doInitApp();
        },
      },
      [AuthStates.APP_IS_READY]: {
        on: {
          [AuthStateEvents.LOGOUT]: AuthStates.NO_VALID_SESSION,
          [AuthStateEvents.RATE_LIMIT_ERROR]: AuthStates.LOGOUT_BY_RATE_LIMIT,
          [AuthStateEvents.TEA_POT_ERROR]: AuthStates.LOGOUT_BY_TEA_POT_ERROR,
        },
        effect: () => {
          const appReadyAction = () => {
            // TODO this should be in a PushNotification provider file instead
            subscribePushNotifications();
            setTimeout(() => {
              void SplashScreen.hide();
            }, 300);
          };
          void appReadyAction();
        },
      },
      [AuthStates.NO_VALID_SESSION]: {
        on: {
          [AuthStateEvents.GO_TO_LOGIN]: AuthStates.LOGIN_IS_READY,
        },
        effect: ({ send, event }: AuthStateEffect) => {
          const handleLogout = () => {
            dispatch(logout());
            setSignInData(undefined);
            send({
              type: AuthStateEvents.GO_TO_LOGIN,
              // DOC: payload contains a flag to avoid prompting biometric auth after logout
              payload: event?.payload,
            });
          };
          void handleLogout();
        },
      },
      [AuthStates.LOGOUT_BY_RATE_LIMIT]: {
        on: {
          [AuthStateEvents.VAULT_LOGIN_NOT_ENABLED]:
            AuthStates.LOGIN_WITHOUT_VAULT_CREDENTIALS,
          [AuthStateEvents.GO_TO_LOGIN]: AuthStates.LOGIN_IS_READY,
        },
        effect: () => {
          const handleRateLimitLogout = () => {
            setSignInData(undefined);
            history.push(RateLimitErrorURL());
          };
          handleRateLimitLogout();
        },
      },
      [AuthStates.LOGOUT_BY_TEA_POT_ERROR]: {
        on: {
          [AuthStateEvents.VAULT_LOGIN_NOT_ENABLED]:
            AuthStates.LOGIN_WITHOUT_VAULT_CREDENTIALS,
          [AuthStateEvents.GO_TO_LOGIN]: AuthStates.LOGIN_IS_READY,
        },
        effect: () => {
          const handleTeaPotLogout = () => {
            setSignInData(undefined);
            history.push(TeaPotErrorURL());
          };
          handleTeaPotLogout();
        },
      },
      [AuthStates.LOGIN_IS_READY]: {
        on: {
          [AuthStateEvents.VALIDATE_VAULT_LOGIN]:
            AuthStates.VALIDATING_VAULT_LOGIN,
          [AuthStateEvents.VAULT_LOGIN_NOT_ENABLED]:
            AuthStates.LOGIN_WITHOUT_VAULT_CREDENTIALS,
        },
        effect: ({ send, event }: AuthStateEffect) => {
          // used to prevent biometric login when a user just logged out
          const disableBiometricLogin = get(
            event,
            'payload.disableBiometricLogin',
            false
          ) as boolean;
          history.replace(loginURL());
          void SplashScreen.hide();

          if (!disableBiometricLogin) {
            setRedirectOverrideURL(lastVisitedURL);
            send({ type: AuthStateEvents.VALIDATE_VAULT_LOGIN });
          } else {
            history.replace(loginURL());
            send({ type: AuthStateEvents.VAULT_LOGIN_NOT_ENABLED });
          }
        },
      },
      [AuthStates.VALIDATING_VAULT_LOGIN]: {
        on: {
          [AuthStateEvents.INITIALIZE_APP]: AuthStates.INIT_APP,
          [AuthStateEvents.VAULT_LOGIN_NOT_ENABLED]:
            AuthStates.LOGIN_WITHOUT_VAULT_CREDENTIALS,
          [AuthStateEvents.GO_TO_LOGIN]: AuthStates.LOGIN_IS_READY,
          [AuthStateEvents.RATE_LIMIT_ERROR]: AuthStates.LOGOUT_BY_RATE_LIMIT,
          [AuthStateEvents.TEA_POT_ERROR]: AuthStates.LOGOUT_BY_TEA_POT_ERROR,
          [AuthStateEvents.SEND_LOGIN_CREDENTIALS]:
            AuthStates.SENDING_LOGIN_CREDENTIALS,
        },
      },
      [AuthStates.LOGIN_WITHOUT_VAULT_CREDENTIALS]: {
        on: {
          [AuthStateEvents.SEND_LOGIN_CREDENTIALS]:
            AuthStates.SENDING_LOGIN_CREDENTIALS,
          [AuthStateEvents.INITIALIZE_APP]: AuthStates.INIT_APP,
          [AuthStateEvents.RETRY_VAULT_LOGIN]:
            AuthStates.VALIDATING_VAULT_LOGIN,
        },
      },
      [AuthStates.SENDING_LOGIN_CREDENTIALS]: {
        on: {
          [AuthStateEvents.ASK_TO_SET_VAULT_CREDENTIALS]:
            AuthStates.ASKING_TO_SET_VAULT_CREDENTIALS,
          [AuthStateEvents.ERROR_WITH_LOGIN_CREDENTIALS]:
            AuthStates.LOGIN_WITHOUT_VAULT_CREDENTIALS,
          [AuthStateEvents.RATE_LIMIT_ERROR]: AuthStates.LOGOUT_BY_RATE_LIMIT,
          [AuthStateEvents.TEA_POT_ERROR]: AuthStates.LOGOUT_BY_TEA_POT_ERROR,
        },
        effect: ({ send, event }: AuthStateEffect) => {
          const doSendLoginData = async () => {
            setLoginError(undefined);
            try {
              const username = toString(get(event, 'payload.username'));
              const password = toString(get(event, 'payload.password'));
              const model = toString(get(event, 'payload.model'));
              const { data: loginData, motionToken } = await signin({
                username,
                password,
                modelAR: model === ModelLoginEnum.modelAR,
                modelBM: model === ModelLoginEnum.modelBM,
                modelCAM: model === ModelLoginEnum.modelCAM,
              });
              setSignInData(loginData);

              const previousUserId = await UsernameStorage.get();
              const previousUserInfo = await UserInfoStorage.get();
              const previousUserRole = previousUserInfo?.userInfo?.jobCode;
              const currentUserRole = loginData?.userInfo?.jobCode;
              const userOrRoleHasChanged = and(
                !isNil(previousUserId),
                !isNil(previousUserRole),
                or(
                  username !== previousUserId,
                  currentUserRole !== previousUserRole
                )
              );
              await clearVault();
              await UsernameStorage.set(username);

              // If the user has changed, the data of the previous user is cleared
              if (userOrRoleHasChanged) {
                unsubscribeNotifications();
                dispatch(clearUserData());
                Sentry.addBreadcrumb({
                  type: 'debug',
                  category: SentryCategories.STORAGE,
                  message:
                    'Application storage was cleared due to a new app log in with a different username.',
                  level: 'debug',
                });
              }

              send({
                type: AuthStateEvents.ASK_TO_SET_VAULT_CREDENTIALS,
                payload: {
                  motionToken,
                },
              });
            } catch (error) {
              const err = error as AxiosError;
              if (err?.response?.status === RATE_LIMIT_ERROR_CODE) {
                send({ type: AuthStateEvents.RATE_LIMIT_ERROR });
              } else if (err?.response?.status === TEA_POT_ERROR_CODE) {
                send({ type: AuthStateEvents.TEA_POT_ERROR });
              } else {
                setLoginError(error as AxiosError);
                send({ type: AuthStateEvents.ERROR_WITH_LOGIN_CREDENTIALS });
              }
            }
          };
          void doSendLoginData();
        },
      },
      [AuthStates.ASKING_TO_SET_VAULT_CREDENTIALS]: {
        on: {
          [AuthStateEvents.SAVE_VAULT_CREDENTIALS]:
            AuthStates.SAVING_VAULT_CREDENTIALS,
        },
      },
      [AuthStates.SAVING_VAULT_CREDENTIALS]: {
        on: {
          [AuthStateEvents.INITIALIZE_APP]: AuthStates.INIT_APP,
          [AuthStateEvents.ERROR_WITH_LOGIN_CREDENTIALS]:
            AuthStates.LOGIN_WITHOUT_VAULT_CREDENTIALS,
        },
        effect: ({ send, event }: AuthStateEffect) => {
          const doSaveBiometricCredentials = async () => {
            try {
              const motionToken = toString(get(event, 'payload.motionToken'));
              if (motionToken) {
                await setAuthToken(motionToken);
              }
              send({ type: AuthStateEvents.INITIALIZE_APP });
            } catch (error) {
              setLoginError(error as AxiosError);
              send({ type: AuthStateEvents.ERROR_WITH_LOGIN_CREDENTIALS });
            }
          };
          void doSaveBiometricCredentials();
        },
      },
    },
  });

  void useFindActiveEmployees({
    enabled: machine.value === AuthStates.APP_IS_READY,
  });

  useEffect(() => {
    // TODO: auth states don't listen to changes in functions like whoAmI,
    // needs to be a hook to retrigger the request
    const doVaultAuth = async () => {
      try {
        setLoginError(undefined);
        const username = await UsernameStorage.get();
        let accessToken = await getAuthToken();
        if (and(!!username, !accessToken)) {
          await setAsyncTimeout(() => getAuthToken(), 5000);
          accessToken = await getAuthToken();
        }

        if (and(username, accessToken)) {
          const loginData = await getWhoAmI();
          setSignInData(loginData);

          Sentry.addBreadcrumb({
            category: SentryCategories.AUTH_STATE,
            message: `Successful biometric login with username: ${toString(
              loginData.userInfo?.userid
            )}`,
            level: 'info',
          });
          rootSend({ type: AuthStateEvents.INITIALIZE_APP });
        } else {
          rootSend({ type: AuthStateEvents.VAULT_LOGIN_NOT_ENABLED });
        }
      } catch (error) {
        const err = error as AxiosError;
        if (err?.response?.status === RATE_LIMIT_ERROR_CODE) {
          rootSend({ type: AuthStateEvents.RATE_LIMIT_ERROR });
          setTimeout(() => {
            void SplashScreen.hide();
          }, 300);
        } else if (err?.response?.status === TEA_POT_ERROR_CODE) {
          rootSend({ type: AuthStateEvents.TEA_POT_ERROR });
          setTimeout(() => {
            void SplashScreen.hide();
          }, 300);
        } else {
          setLoginError(error as AxiosError);
          rootSend({ type: AuthStateEvents.VAULT_LOGIN_NOT_ENABLED });
        }
      }
    };
    if (machine.value === AuthStates.VALIDATING_VAULT_LOGIN) {
      void doVaultAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machine.value, rootSend]);

  const [present, dismiss] = useIonModal(AuthPinModal, {
    setPasscodeMode: isSetPasscodeMode,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDismiss: (opts: { data: any; role?: string }) =>
      handlePasscodeRequest(opts),
    send: rootSend,
    event: machine.event,
    clearVault,
    getAuthToken,
    setAuthToken,
    updateUnlockMode,
  });

  useEffect(() => {
    if (isLockedAfterBackgrounded) {
      rootSend({
        type: AuthStateEvents.LOGOUT,
        // we still want to trigger automatic biometric/pin login
        payload: {
          disableBiometricLogin: false,
        },
      });
    }
  }, [isLockedAfterBackgrounded, rootSend]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (includes([AuthStates.APP_IS_READY], machine.value)) {
      timeout = setTimeout(() => {
        if (
          redirectOverrideURL &&
          !includes(['/', loginURL()], redirectOverrideURL)
        ) {
          history.replace(redirectOverrideURL);
          setRedirectOverrideURL(undefined);
        } else {
          history.replace(tabsURL());
        }
        // DOC: timeout to give login time to close loader
      }, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machine.value]);

  useEffect(() => {
    let eventDetails = '';
    if (machine.event) {
      eventDetails = `(via ${machine.event.type} event)`;
    }
    const message = `Entered ${machine.value} state ${eventDetails}`;

    // eslint-disable-next-line no-console
    console.debug(message, eventDetails);

    Sentry.addBreadcrumb({
      type: 'debug',
      category: SentryCategories.AUTH_STATE,
      message: `Entered ${machine.value} state ${eventDetails}`,
      level: 'debug',
    });
  }, [machine]);

  const [debouncedInvalidToken] = useDebounce(triggerInvalidToken, 300);

  useEffect(() => {
    if (debouncedInvalidToken > 0) {
      rootSend({
        type: AuthStateEvents.LOGOUT,
        payload: { disableBiometricLogin: true },
      });
    }
  }, [rootSend, debouncedInvalidToken]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    showPinModal ? present({ backdropDismiss: false }) : dismiss();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPinModal, machine]);

  useEffect(() => {
    if (
      notificationRegistered &&
      includes([AuthStates.APP_IS_READY], machine.value)
    ) {
      listenNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machine.value, notificationRegistered]);

  return (
    <AuthStateContext.Provider
      value={{
        state: machine.value,
        send: rootSend,
        loginError,
        event: machine.event,
      }}
    >
      {and(
        !includes(['/', loginURL()], pathname),
        !includes(['/', RateLimitErrorURL()], pathname),
        !includes(['/', TeaPotErrorURL()], pathname),
        !includes([AuthStates.APP_IS_READY], machine.value)
      ) ? (
        <Blank />
      ) : (
        <>
          {process.env.REACT_APP_ENV_BUILD !== 'prod' && (
            <div className={classes.modelLabel}>
              {process.env.REACT_APP_ENV_BUILD}
            </div>
          )}
          {children}
        </>
      )}
    </AuthStateContext.Provider>
  );
};
export default AuthStateProvider;

export const useAuthState = (): AuthStateContextProps => {
  const ctx = useContext(AuthStateContext);

  if (!ctx) {
    throw Error(
      'The `useAuthState` hook must be called inside a `AuthStateProvider`.'
    );
  }

  return {
    state: ctx.state,
    send: ctx.send,
    loginError: ctx.loginError,
    event: ctx.event,
  };
};
