import { forEach, get, isArray, isNil, map, toString } from 'lodash';
import type { MiProFile } from 'models/Attachment';
import type { QueryParamsType } from './useGetQueryFlags';

export interface MutationToastUtils {
  skipSuccessToast?: boolean;
  successToastText?: string;
}

// TUDU remove for BaseQueryProps
export interface BaseAPIHook {
  limit?: number;
  enabled?: boolean;
}

export interface BaseQueryProps {
  query?: string;
  sortField?: string;
  sortDir?: string;
  limit?: number;
  enabled?: boolean;
}

export const getURLParams = (params: QueryParamsType) => {
  const paramsGET = new URLSearchParams();
  forEach(params, (param, key) => {
    if (isArray(param)) {
      forEach(param, (val) => {
        paramsGET.append(key, toString(val));
      });
    } else if (!isNil(param)) {
      paramsGET.append(key, toString(param));
    }
  });
  return toString(paramsGET);
};

export const appendFilesData = async (
  formData: FormData,
  files?: MiProFile[]
) => {
  await Promise.all(
    map(files, async (f) => {
      const fileName = toString(get(f, 'fileName'));
      if (f.type.startsWith('image/')) {
        const fileData: ArrayBuffer = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (fileResult) => {
            resolve(fileResult.target?.result as ArrayBuffer);
          };
          reader.readAsArrayBuffer(f);
        });
        const fileBlob = new Blob([new Uint8Array(fileData)], {
          type: f.type,
        });
        formData.append(fileName, fileBlob, fileName);
      } else {
        formData.append(fileName, f);
      }
    })
  );
};
