import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { appendFilesData } from 'common/api/utils/apiHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { onSuccessMutation } from 'api/helpers';
import type { MiProFile } from 'models/Attachment';
import { ToastType } from 'models/Toast';
import { submitProTipAPI } from '.';

interface CreateProTipBody {
  reqEmployeeId?: string;
  industryId?: number[];
  revenue?: number;
  mfrCtlNo?: string;
  subject?: string;
  details?: string;
  miCorpLoc?: string;
  files?: MiProFile[];
}

const useSubmitProTip = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.notes);

  const response = useMutation({
    mutationFn: async ({ files, ...vars }: CreateProTipBody) => {
      const formData = new FormData();
      formData.append('data', JSON.stringify(vars));
      await appendFilesData(formData, files);
      const { data } = await axios.post<CreateProTipBody>(
        submitProTipAPI(),
        formData
      );
      return data;
    },
    onSuccess: async () => {
      addToast({
        type: ToastType.success,
        header: t('HomeApp/ProTips:submitSuccessTitle'),
        text: t('HomeApp/ProTips:submitSuccessMessage'),
        testid: 'submit-pro-tip-success-toast',
      });
      await onSuccessMutation(queryClient, findActivitiesQueryKey);
    },
    onError: () => {
      addToast({
        type: ToastType.error,
        text: t('HomeApp/ProTips:submitErrorMessage'),
        testid: 'submit-pro-tip-error-toast',
      });
    },
  });

  return { ...response, onSubmitProTip: response.mutate };
};

export default useSubmitProTip;
