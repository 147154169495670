import type { BaseQueryProps } from 'common/api/utils/apiHelpers';
import { getURLParams } from 'common/api/utils/apiHelpers';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import { pageSize } from 'utils/constants';
import { findManufacturersAPI } from '.';

export const findManufacturersQueryKey = 'find-manufacturers';

export interface Manufacturer {
  mfrCtlNo: string;
  mfrName: string;
}

const useFindManufacturers = ({
  query,
  limit = pageSize(),
}: BaseQueryProps) => {
  const { axios } = useAxios();
  const params = { fastFind: query, limit };

  const response = useQuerySetup<Manufacturer, Manufacturer[]>({
    queryKey: findManufacturersQueryKey,
    queryParams: params,
    isInfiniteQuery: true,
    enabled: !!query,
    onAPIRequest: async ({ pageParam = 1, signal }) => {
      const { data } = await axios.get<Manufacturer[]>(
        findManufacturersAPI(getURLParams({ ...params, page: pageParam })),
        { signal }
      );
      return data;
    },
  });

  return { manufacturers: response.items, ...response };
};

export default useFindManufacturers;
