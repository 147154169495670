import CommentsEn from 'ActivitiesApp/components/Comments/en.json';
import ActivitiesGroupContentEn from 'ActivitiesApp/components/common/en.json';
import ProTipFormEn from 'ActivitiesApp/components/ProTipForm/en.json';
import RelatedOpportunityEn from 'ActivitiesApp/components/RelatedOpportunity/en.json';
import ShopQuoteFormEn from 'ActivitiesApp/components/ShopQuoteForm/en.json';
import ActivityConfigEn from 'ActivitiesApp/config/en.json';

export default {
  en: {
    ...ShopQuoteFormEn,
    ...RelatedOpportunityEn,
    ...CommentsEn,
    ...ActivitiesGroupContentEn,
    ...ActivityConfigEn,
    ...ProTipFormEn,
  },
};
