import type { AxiosError } from 'axios';
import { isEmpty, isNil, toString } from 'lodash';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, getPlaceholderData, useKeyUserId } from 'api/helpers';
import type { ActionCardActivity } from 'models/ActivityModels';
import type { QueryFlags } from 'models/Search';
import { findActivitiesQueryKey, reduceFieldsData } from './useFindActivities';

export const getActivityQueryKey = 'activity';

interface UseGetActivityProps {
  historyId: number;
  userId: string;
  enabled?: boolean;
}

interface UseGetActivityResponse {
  data?: ActionCardActivity;
}

const useGetActivity = ({
  historyId,
  userId,
  enabled = true,
}: UseGetActivityProps): UseGetActivityResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getActivityAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const doGetActivity = () => {
    return doPromiseAPI<ActionCardActivity>(async (cancelToken) => {
      const { data } = await axios.get<ActionCardActivity>(
        getActivityAPI(toString(historyId)),
        { cancelToken }
      );
      return { ...data, fieldsData: reduceFieldsData(data.fields) };
    });
  };

  const { data, error, status, isFetching, refetch } = useQuery<
    ActionCardActivity,
    AxiosError
  >(createQueryKey(getActivityQueryKey, { historyId }), doGetActivity, {
    enabled: enabled && !isEmpty(toString(historyId)) && !isEmpty(userId),
    placeholderData: () =>
      getPlaceholderData<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        findPredicate: { historyId, userId },
      }),
  });

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data?.historyId)),
    isFetching,
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetActivity;
