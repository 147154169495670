import React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { IonRow } from '@ionic/react';
import {
  getOptionalRenderProp,
  type BaseComponentProps,
  type OptionalRenderProp,
} from 'common/components/utils/renderHelpers';
import Text from 'components/Text/Text';
import classes from './ItemDetailLines.module.scss';

interface DetailLineProps {
  hidden?: boolean;
  label?: OptionalRenderProp;
  value: OptionalRenderProp;
}

interface ItemDetailLinesProps extends BaseComponentProps {
  lines: DetailLineProps[];
  itemClass?: string;
}

const ItemDetailLines = ({
  lines,
  className,
  itemClass,
  testid,
}: ItemDetailLinesProps) => {
  return (
    <IonRow className={className} data-testid={testid}>
      <div className={classes.wrapper}>
        {map(lines, (line, idx) => {
          if (line.hidden === false) {
            return null;
          }

          const { text: label, props: labelProps } = getOptionalRenderProp(
            line.label
          );

          const { text: value, props: valueProps } = getOptionalRenderProp(
            line.value
          );

          return (
            <div key={idx} className={classNames(classes.item, itemClass)}>
              {label && (
                <Text
                  variant={labelProps?.variant || 'list-item-secondaryText'}
                  text={label}
                  testid={`label-${idx}`}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...labelProps}
                  className={classNames(classes.label, labelProps?.className)}
                />
              )}
              {value && (
                <Text
                  variant={valueProps?.variant || 'list-item-secondaryText'}
                  text={value}
                  testid={`value-${idx}`}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...valueProps}
                  className={classNames(classes.value, valueProps?.className)}
                />
              )}
            </div>
          );
        })}
      </div>
    </IonRow>
  );
};

export default ItemDetailLines;
