import React, { useCallback } from 'react';
import { getLongPressDuration } from 'constants/platformSpecificConstants';
import classNames from 'classnames';
import { debounce, isNil, isObject, toString } from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ToggleChangeEventDetail } from '@ionic/core/components';
import { IonItem, IonRow, IonToggle } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { OptionalRenderProp } from 'common/components/utils/renderHelpers';
import { or } from 'common/utils/logicHelpers';
import { LongPressDetectEvents, useLongPress } from 'use-long-press';
import useHapticFeedback from 'hooks/useHapticFeedback';
import { logFirebaseEvent } from 'utils/firebaseAnalytics';
import { withStringProp } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './ActionRow.module.scss';

interface ActionRowProps {
  text?: string;
  textQuery?: string;
  textVariant?: TextVariantType;
  alerts?: number;
  icon?: IconProp;
  leftButton?: OptionalRenderProp<React.ComponentProps<typeof Button>>;
  rightButton?: React.ComponentProps<typeof Button>;
  checked?: boolean;
  setChecked?: (b: boolean) => void;
  withArrow?: boolean;
  withoutHrefArrow?: boolean;
  isExternalLink?: boolean;
  testid: string;
  onLongPress?: () => void;
  track?: boolean;
  debounceClick?: boolean;
  googleAnalyticsId?: string;
}

const ActionRow = ({
  className,
  text = '',
  textQuery,
  textVariant = 'content-default',
  icon,
  alerts,
  href: propsHref,
  onClick,
  leftButton,
  rightButton,
  checked,
  setChecked,
  children,
  disabled,
  withArrow = true,
  withoutHrefArrow = false,
  isExternalLink = false,
  testid,
  googleAnalyticsId,
  onLongPress,
  track = true,
  // TODO: should be false by default and only set to true when needed
  debounceClick = false,
}: ActionRowProps &
  React.ComponentProps<typeof IonItem> &
  IonicReactProps): JSX.Element => {
  const withClick = !isNil(onClick);
  const withAlerts = !isNil(alerts);
  const withIcon = !isNil(icon);
  const withText = withStringProp(text);
  const withLink = withStringProp(propsHref);
  const withToggle = !isNil(setChecked) && !isNil(checked);
  const href = window.location.pathname !== propsHref ? propsHref : undefined;
  // automatically disable an action row which does not support user interaction
  const isDisabled = !isNil(disabled)
    ? disabled
    : !(withClick || withLink || withToggle || leftButton || rightButton);

  const handleButtonClick =
    (button?: React.ComponentProps<typeof Button>) =>
    (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
      if (button?.onClick) {
        e.stopPropagation();
        e.preventDefault();
        button?.onClick?.(e);
      }
    };

  let itemIcon: IconProp = findIcon('chevron-right');
  if (withIcon) {
    itemIcon = icon;
  }
  const { hapticsImpactLight } = useHapticFeedback();

  const callback = useCallback(() => {
    if (onLongPress) {
      void hapticsImpactLight();
      onLongPress();
    }
  }, [hapticsImpactLight, onLongPress]);

  const bind = useLongPress(callback, {
    captureEvent: true,
    threshold: getLongPressDuration(),
    cancelOnMovement: true,
    detect: LongPressDetectEvents.BOTH,
  });

  const onClickWithTracking = (
    e: React.MouseEvent<HTMLIonItemElement, MouseEvent>
  ) => {
    onClick?.(e);
    if (withToggle) {
      setChecked?.(!checked);
    }
    if (track) {
      logFirebaseEvent('action_row_click', {
        type: 'action_row',
        isExternalLink: false,
        testid: or(googleAnalyticsId, testid),
      });
    }
  };

  let rowActionClick = onClickWithTracking;
  if (debounceClick) {
    rowActionClick = debounce(onClickWithTracking, 300);
  }

  const leftButtonProps = isObject(leftButton) ? leftButton : undefined;

  return (
    <IonItem
      className={classNames(className, classes.item, {
        [classes.withAction]: withToggle,
      })}
      href={href}
      routerLink={!isExternalLink ? href : undefined}
      detail={false}
      lines="none"
      button
      onClick={rowActionClick}
      disabled={isDisabled}
      data-testid={testid}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...bind}
    >
      {leftButton && (
        <IonRow
          slot="start"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...leftButtonProps?.wrapperProps}
          className={classNames(
            classes.leftButtonWrapper,
            leftButtonProps?.wrapperProps?.className
          )}
        >
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...leftButtonProps}
            debounceClick={false}
            className={classNames(
              leftButtonProps?.className,
              classes.leftButton
            )}
            testid={toString(leftButtonProps?.testid)}
            onClick={handleButtonClick(
              leftButtonProps as React.ComponentProps<typeof Button>
            )}
          />
        </IonRow>
      )}
      {children}
      {withText && (
        <Text
          variant={textVariant}
          text={text}
          textQuery={textQuery}
          testid={`${testid}-text`}
        />
      )}
      {withAlerts && (
        <IonRow slot="end" className={classes.endRow}>
          <Text
            className={classes.alerts}
            text={toString(alerts)}
            testid={`${testid}-alerts`}
          />
        </IonRow>
      )}
      {(withLink || withIcon || withoutHrefArrow) && withArrow && (
        <IonRow
          slot="end"
          className={classNames(classes.endRow, classes.arrow, {
            [classes.endRowWithAlerts]: withAlerts,
          })}
        >
          <FontAwesomeIcon icon={itemIcon} />
        </IonRow>
      )}
      {withToggle && (
        <IonRow
          slot="end"
          className={classNames(classes.endRow, classes.arrow, {
            [classes.endRowWithAlerts]: withAlerts,
          })}
        >
          <IonToggle
            className={classes.toggle}
            name={testid}
            checked={checked}
            color="success"
            onIonChange={(e: CustomEvent<ToggleChangeEventDetail>) => {
              const { checked: eChecked } = e.detail;
              setChecked?.(eChecked);
            }}
            data-testid={`${testid}-toggle`}
          />
        </IonRow>
      )}
      {rightButton && (
        <IonRow
          slot="end"
          className={classNames(classes.rightButtonWrapper, {
            [classes.endRowWithAlerts]: withAlerts,
          })}
        >
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rightButton}
            debounceClick={false}
            className={classNames(rightButton.className, classes.rightButton)}
            onClick={handleButtonClick(rightButton)}
          />
        </IonRow>
      )}
    </IonItem>
  );
};

export default ActionRow;
