import React from 'react';
import { find, toString } from 'lodash';
import type { IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { IonRow } from '@ionic/react';
import useUpdateVote from 'ActivitiesApp/api/useUpdateVote';
import { choose } from 'common/utils/logicHelpers';
import type { ActionCardActivity } from 'models/ActivityModels';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './ActivityVotes.module.scss';

interface ActivityThumbsProps {
  title: string;
  activity: ActionCardActivity;
}

const ActivityVotes = ({ title, activity }: ActivityThumbsProps) => {
  const { onUpdateVote } = useUpdateVote({
    historyId: activity.historyId,
  });
  const votedUp = activity.thumbChoice === 'Y';
  const votedUpIcon = choose(votedUp, 'fas', 'far');
  const votedDown = activity.thumbChoice === 'N';
  const votedDownIcon = choose(votedDown, 'fas', 'far');
  return (
    <div className={classes.component}>
      <IonRow>
        <Text
          text={title}
          variant="list-item-subtitle"
          className={classes.title}
          testid="vote-title"
        />
      </IonRow>
      <IonRow>
        <Button
          variant="link"
          text={toString(
            find(activity.thumbCount, (i) => i.vote === 'Y')?.count ?? 0
          )}
          className={classes.buttons}
          testid="vote-like-button"
          icon={[votedUpIcon as IconPrefix, 'thumbs-up']}
          onClick={() => {
            onUpdateVote({
              like: choose(votedUp, undefined, true),
            });
          }}
        />
        <Button
          variant="link"
          className={classes.buttons}
          testid="vote-dislike-button"
          icon={[votedDownIcon as IconPrefix, 'thumbs-down']}
          onClick={() => {
            onUpdateVote({
              like: choose(votedDown, undefined, false),
            });
          }}
        />
      </IonRow>
    </div>
  );
};
export default ActivityVotes;
