import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { crmHasOpenStatus, isActivityOwner } from 'ActivitiesApp/utils/helpers';
import { and, choose, ifFunction } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { type ActionCardActivity, ActivityColor } from 'models/ActivityModels';
import ActivityActionCard from 'pages/Activities/ActivityActionCard/ActivityActionCard';
import type { CodeListOptionProps } from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import {
  getActivityConfig,
  getActivityType,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import classes from './ActivityItemContent.module.scss';

interface ActivityItemContentProps {
  multiSelectEnabled?: boolean;
  disableSwipe?: boolean;
  loggedInUserId: string;
  activity: ActionCardActivity;
  multiSelectList?: number[];
  codeList?: CodeListOptionProps[];
  testid: string;
  toggleSelection?: (itemId?: number, haptic?: boolean) => void;
  onDetailClick?: (data: { activity: ActionCardActivity }) => void;
  isSnapshotActivities: boolean;
}

const ActivityItemContent = ({
  multiSelectEnabled = false,
  disableSwipe = false,
  loggedInUserId,
  activity,
  multiSelectList,
  codeList,
  toggleSelection,
  onDetailClick,
  testid,
  isSnapshotActivities,
}: ActivityItemContentProps) => {
  const { t, i18n } = useTranslation(namespaces.activities);
  const isSelected = multiSelectList?.some((x) => {
    return x === activity.historyId;
  });

  const { cardType, icon, color } = getActivityType(activity.eventTagName, t);
  const { isUnsupportedType } = getActivityConfig({
    cardType,
    activityData: activity,
    i18n,
    t,
    loggedInUserId,
  });
  const activityColor = isUnsupportedType ? 'red' : color;
  let activityIcon: IconName | undefined = isUnsupportedType
    ? 'exclamation-triangle'
    : icon;
  if (isSelected) {
    activityIcon = 'check';
  } else if (multiSelectEnabled) {
    activityIcon = undefined;
  }
  const toggleSelectionWithHaptics = () =>
    toggleSelection?.(activity.historyId, true);

  const hasOpenStatus = crmHasOpenStatus(activity);
  const allowCardSelection = and(
    isActivityOwner(loggedInUserId, activity),
    !hasOpenStatus
  );
  const nonSelectableItem = multiSelectEnabled && !allowCardSelection;

  return (
    <ActivityActionCard
      key={`${activity.historyId}${activity.userId}`}
      isDisabled={!allowCardSelection}
      className={classNames({
        [classes.updatingCard]: activity.isOptimisticallyUpdating,
        [classes.multiSelected]: isSelected,
        [classes.nonSelectable]: nonSelectableItem,
      })}
      avatarContainerClass={classNames({
        [classes.multiSelectEnabled]: multiSelectEnabled,
        [classes.multiSelectCheck]: isSelected,
        [classes.nonSelectableCheck]: nonSelectableItem,
      })}
      historyId={activity.historyId}
      cardType={cardType}
      icon={activityIcon}
      color={isSelected ? ActivityColor.default : activityColor}
      onClick={choose(
        multiSelectEnabled && allowCardSelection,
        toggleSelectionWithHaptics,
        onDetailClick
      )}
      disableSwipe={multiSelectEnabled || disableSwipe || isSnapshotActivities}
      onLongPress={() =>
        ifFunction(allowCardSelection, () =>
          toggleSelection?.(activity.historyId)
        )
      }
      activity={{ ...activity }}
      testid={`${testid}-${activity.historyId}-${activity.userId}-content`}
      codeList={codeList}
      onMultiSelectAction={choose(
        allowCardSelection,
        toggleSelectionWithHaptics
      )}
      loggedInUserId={loggedInUserId}
    />
  );
};

export default ActivityItemContent;
