import React from 'react';
import { useTranslation } from 'react-i18next';
import { concat, filter } from 'lodash';
import { IonRow } from '@ionic/react';
import { FormikInput } from 'common/components/Forms/Input/Input';
import { choose, or } from 'common/utils/logicHelpers';
import { useFormikContext } from 'formik';
import EmployeeSelect from 'HomeApp/ProTips/components/EmployeeSelect.ts/EmployeeSelect';
import IndustrySegmentsSelect from 'HomeApp/ProTips/components/IndustrySegmentsSelect.ts/IndustrySegmentsSelect';
import ManufacturerSelect from 'HomeApp/ProTips/components/ManufacturerSelect/ManufacturerSelect';
import Attachments from 'components/Attachments/Attachments';
import Text from 'components/Text/Text';
import classes from './ShareProTip.module.scss';
import type { ShareProTipForm } from './ShareProTipSchema';

interface ProTipFormProps {
  isSubmitting?: boolean;
}

const ProTipForm = ({ isSubmitting }: ProTipFormProps) => {
  const { t } = useTranslation();

  const { setFieldValue, values } = useFormikContext<ShareProTipForm>();

  return (
    <div className={classes.formWrapper} data-testid="pro-tip-form">
      <IonRow className={classes.subTitle}>
        <Text
          text={t('HomeApp/ProTips:formSubtitle')}
          variant="mipro-body-copy"
        />
      </IonRow>
      <EmployeeSelect
        className={classes.formField}
        name="employee"
        required
        disabled={isSubmitting}
        label={t('HomeApp/ProTips:employee:inputLabel')}
        modalTitle={t('HomeApp/ProTips:employee:modalTitle')}
        searchPlaceholder={t('HomeApp/ProTips:employee:searchPlaceholder')}
        testid="employee-input"
      />
      <IndustrySegmentsSelect
        className={classes.formField}
        name="industrySegments"
        required
        multiple
        disabled={isSubmitting}
        label={t('HomeApp/ProTips:industrySegments:inputLabel')}
        modalTitle={t('HomeApp/ProTips:industrySegments:modalTitle')}
        searchPlaceholder={t(
          'HomeApp/ProTips:industrySegments:searchPlaceholder'
        )}
        testid="industry-segments-input"
      />
      <FormikInput
        className={classes.formField}
        label={t(`HomeApp/ProTips:revenue:inputLabel`)}
        name="revenue"
        currencyMask={{ scale: 0 }}
        required
        disabled={isSubmitting}
        testid="revenue-input"
      />
      <ManufacturerSelect
        className={classes.formField}
        name="manufacturer"
        disabled={isSubmitting}
        label={t('HomeApp/ProTips:manufacturer:inputLabel')}
        modalTitle={t('HomeApp/ProTips:manufacturer:modalTitle')}
        searchPlaceholder={t('HomeApp/ProTips:manufacturer:searchPlaceholder')}
        testid="manufacturer-input"
      />
      <FormikInput
        className={classes.formField}
        label={t(`HomeApp/ProTips:headline:inputLabel`)}
        name="headline"
        required
        disabled={isSubmitting}
        maxlength={80}
        textarea
        rows={1}
        autogrow
        testid="headline-input"
      />
      <FormikInput
        className={classes.formField}
        label={t(`HomeApp/ProTips:descriptionLabel`)}
        name="description"
        required
        disabled={isSubmitting}
        textarea
        autogrow
        testid="description-input"
      />
      <div className={classes.attachmentWrapper}>
        <Text text={t('email:attachments')} variant="content-heavy" />
        <Attachments
          domain="mprotip"
          name="files"
          files={values.files}
          disabled={isSubmitting}
          onAdd={(v) => {
            setFieldValue('files', concat(or(values.files, []), v));
          }}
          onRemove={(v) => {
            const filesFiltered = filter(values.files, (i) => {
              // FILE_NAME | fileName is used as the unique identifier for images
              if ('fileName' in v) {
                return choose('fileName' in v, i.fileName !== v.fileName, true);
              }
              return true;
            });
            setFieldValue('files', filesFiltered);
          }}
          enableSmallPreview
          prefetchAll
          testid="pro-tip-attachments"
        />
      </div>
    </div>
  );
};

export default ProTipForm;
