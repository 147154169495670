import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import type { UpdateMutationContext } from 'api/helpers';
import {
  onSuccessMutation,
  doPromiseAPI,
  onMutateUpdate,
  onErrorUpdate,
} from 'api/helpers';
import type { ActionCardActivity } from 'models/ActivityModels';
import { ToastType } from 'models/Toast';
import type { FirebaseUpdatePriceOverrideParams } from 'utils/firebaseAnalytics';
import { logFirebaseEvent } from 'utils/firebaseAnalytics';

interface UseUpdatePriceOverrideProps {
  miLoc: string;
  ocn: string;
  lineNo: string;
  userId?: string;
}

interface UpdatePriceOverrideBody {
  historyId: number;
  userId: string;
  action: string;
  overridePrice?: string;
  overrideGp?: string;
  overrideReason?: string;
  finalGp?: string;
  actionContract?: string;
  actionMargin?: string;
  marginGp?: string;
  contractGp?: string;
}

interface UpdatePriceOverrideAPIResponse {
  success: boolean;
  errorMsg: string;
}

interface UseUpdatePriceOverrideResponse {
  data?: UpdatePriceOverrideAPIResponse;
  status: MutationStatus;
  onUpdatePriceOverride: (body: UpdatePriceOverrideBody) => void;
}

const useUpdatePriceOverride = ({
  miLoc,
  ocn,
  lineNo,
}: UseUpdatePriceOverrideProps): UseUpdatePriceOverrideResponse => {
  const { axios } = useAxios();
  const { updatePriceOverrideAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.activities);

  const doUpdateActivity = (body: UpdatePriceOverrideBody) => {
    return doPromiseAPI<UpdatePriceOverrideAPIResponse>(async (cancelToken) => {
      const requestBody = omit(body, 'userId');
      const { data } = await axios.put<UpdatePriceOverrideAPIResponse>(
        updatePriceOverrideAPI(miLoc, ocn, lineNo),
        requestBody,
        { cancelToken }
      );
      return data;
    });
  };

  const { status, data, mutate } = useMutation(doUpdateActivity, {
    onMutate: async (vars) => {
      let action = '';
      switch (vars.action) {
        case 'A':
          action = 'accepted';
          break;
        case 'R':
          action = 'rejected';
          break;
        default:
          break;
      }

      const params: FirebaseUpdatePriceOverrideParams = {
        userId: vars.userId,
        miLoc,
        action,
        event: 'PriceOverride',
      };

      logFirebaseEvent('price_override_action', params);

      return onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        updatedItems: [{ done: 'Y' } as ActionCardActivity],
        findPredicates: [{ historyId: vars.historyId, userId: vars.userId }],
        isInfiniteQuery: true,
      });
    },
    onSuccess: () => {
      setTimeout(() => {
        void onSuccessMutation(queryClient, findActivitiesQueryKey);
      }, 1000);
    },
    onError: (error, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('priceOverrideFailedToast'),
        testid: 'update-price-override-error-toast',
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: context as UpdateMutationContext<ActionCardActivity>[],
        isInfiniteQuery: true,
      });
    },
  });

  return {
    data,
    status,
    onUpdatePriceOverride: (body: UpdatePriceOverrideBody) => mutate(body),
  };
};

export default useUpdatePriceOverride;
