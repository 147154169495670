import React, { useEffect } from 'react';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import AppIcons from 'constants/appIcons';
import SentryCategories from 'constants/sentryCategories';
import classNames from 'classnames';
import { includes, toNumber, toString } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import {
  getCrmActivityData,
  getCrmActivityConfig,
  getCrmActivityFilterIcon,
} from 'ActivitiesApp/config/CrmActivity';
import {
  getCustomerAccountConfig,
  getCustomerAccountData,
  getCustomerAccountFilterIcon,
} from 'ActivitiesApp/config/CustomerAccount';
import {
  getCustomerVisitConfig,
  getCustomerVisitData,
  getCustomerVisitFilterIcon,
} from 'ActivitiesApp/config/CustomerVisit';
import {
  getOrderQuoteConfig,
  getOrderQuoteData,
  getOrderQuoteFilterIcon,
} from 'ActivitiesApp/config/OrderQuote';
import {
  getPriceOverrideConfig,
  getPriceOverrideData,
  getPriceOverrideFilterIcon,
} from 'ActivitiesApp/config/PriceOverride';
import { getProTipConfig, getProTipData } from 'ActivitiesApp/config/ProTip';
import {
  getSalesOpportunityData,
  getSalesOpportunityConfig,
} from 'ActivitiesApp/config/SalesOpportunity';
import {
  getSalesPlayConfig,
  getSalesPlayData,
  getSalesPlayFilterIcon,
} from 'ActivitiesApp/config/SalesPlay';
import {
  getShopQuoteConfig,
  getShopQuoteData,
  getShopQuoteFilterIcon,
} from 'ActivitiesApp/config/ShopQuote';
import {
  getSourcingOverrideConfig,
  getSourcingOverrideData,
  getSourcingOverrideFilterIcon,
} from 'ActivitiesApp/config/SourcingOverride';
import {
  getWebActivityConfig,
  getWebActivityData,
  getWebActivityFilterIcon,
} from 'ActivitiesApp/config/WebActivity';
import { CrmActivityTypeEnum } from 'ActivitiesApp/models/CrmActivity';
import { getActivityDataValue } from 'ActivitiesApp/utils/helpers';
import type { OptionalComponentProps } from 'common/components/utils/renderHelpers';
import { or } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import type { i18n } from 'i18next';
import type {
  ActionCardActivity,
  ActivityCardDescription,
  ActivityIconEnum,
  ActivityTypeEnum,
  ActivityColorEnum,
  ActivityCardLines,
} from 'models/ActivityModels';
import {
  ActivityColor,
  ActivityIcon,
  ActivityType,
  ActivityFilter,
} from 'models/ActivityModels';
import type { Contact, Recipient } from 'models/Contact';
import { withStringProp } from 'utils/helpers';
import { getCustomerShortName, getIsCorpAccount } from 'utils/search';
import Badge from 'components/Badge/Badge';
import type Button from 'components/Button/Button';
import type HeaderActions from 'components/HeaderActions/HeaderActions';
import type { TextQueryType } from 'components/Text/Text';
import classes from './ActivityActionCard.module.scss';

export interface ActionCardConfig {
  card: {
    defaultTitle: string;
    body: ActivityCardDescription;
    highlight?: TextQueryType;
    customContent?: React.FC;
  };
  modal?: {
    title: string;
    description?: string;
    highlight?: TextQueryType;
    buttons?: React.ComponentProps<typeof Button>[];
    hideCustomerLink?: boolean;
    hideSaveButton?: boolean;
    refetchActivity?: boolean;
    defaultData?: Record<string, unknown>;
    customHeader?: React.FC;
    customContent?: React.FC;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customContentWithRef?: React.ForwardRefExoticComponent<any>;
    contactEmail?: Recipient[];
    headerActions?: (
      didChange: boolean
    ) => OptionalComponentProps<React.ComponentProps<typeof HeaderActions>>;
  };
}

export interface CodeListOptionProps {
  id: string;
  text: string;
}

interface GetActivityConfigProps {
  cardType: ActivityTypeEnum;
  activityData: ActionCardActivity;
  loggedInUserId?: string;
  onCloseModal?: () => void;
  codeList?: CodeListOptionProps[];
  compressedName?: string;
}

export interface GetActivityConfigExtendedProps extends GetActivityConfigProps {
  activityVersion: string;
  loggedInUserId?: string;
  t: TFunction;
  i18n: i18n;
  defaultFirstLine: ActivityCardLines[];
}

export interface GetActivityConfigResponse {
  activityConfig: ActionCardConfig;
  cardText: string;
  toastText: string;
  modalHeader: string;
  activityVersion: string;
  isUnsupportedType: boolean;
  unknownActivityText: string;
  emailContactName: string;
  emailContact: Contact;
}

// TODO: transform to hook
export const getActivityConfig = ({
  cardType,
  activityData,
  onCloseModal,
  t,
  i18n,
  codeList,
  loggedInUserId,
  compressedName,
}: GetActivityConfigProps & {
  t: TFunction;
  i18n: i18n;
}): GetActivityConfigResponse => {
  const { fieldsData, natlAcctNo, custNo } = activityData;
  const customerName = getActivityDataValue(
    activityData.customerName ||
      fieldsData?.['Customer Name'] ||
      fieldsData?.CUSTOMER_NAME
  );
  const followupIcon =
    activityData.followUp === 'Y' ? AppIcons.followUpIcon(true) : undefined;
  const pick12Icon = activityData.customerPick12;
  const defaultFirstLine = [
    {
      description: customerName,
      highlight: customerName,
      followupIcon,
      pick12Icon,
      className: classes.customerName,
      isCorpAccount:
        getIsCorpAccount(custNo, natlAcctNo) || withStringProp(natlAcctNo),
    },
  ];

  // baseConfigversion is now being sent.  by default though if not sent set to one
  const activityVersion = activityData.baseConfigVersion || '1';
  const unknownActivityText = t('unknownActivity', {
    type: activityData.eventTagName,
    activityVersion,
  });

  const eventConfigParams = {
    cardType,
    activityVersion: `${cardType}-${activityVersion}`,
    activityData,
    codeList,
    loggedInUserId,
    defaultFirstLine,
    onCloseModal,
    i18n,
    t,
  };

  let activityConfig = or(
    getOrderQuoteConfig(eventConfigParams),
    getCustomerVisitConfig(eventConfigParams),
    getWebActivityConfig(eventConfigParams),
    getCustomerAccountConfig(eventConfigParams),
    getSalesPlayConfig(eventConfigParams),
    getPriceOverrideConfig(eventConfigParams),
    getSourcingOverrideConfig(eventConfigParams),
    getCrmActivityConfig(eventConfigParams),
    getShopQuoteConfig(eventConfigParams),
    getSalesOpportunityConfig(eventConfigParams),
    getProTipConfig(eventConfigParams)
  );
  const isUnsupportedType = !activityConfig;

  if (!activityConfig) {
    activityConfig = {
      card: {
        defaultTitle: unknownActivityText,
        body: { description: t('unknownActivityDescription') },
      },
    };
  } else if (
    !includes(
      [CrmActivityTypeEnum.crmOpportunity, CrmActivityTypeEnum.crmTask],
      cardType
    )
  ) {
    const lines = activityConfig?.card.body.lines;
    const commentCount = activityData?.commentCount;
    if (commentCount !== '0') {
      const noteLine = {
        description: '',
        badge: (
          <Badge
            type="info"
            icon="comment"
            text={t('comments', {
              notesCount: commentCount,
              count: toNumber(commentCount),
            })}
            testid="notes"
            className={classes.badge}
            textVariant="content-smaller"
            textClassName={classNames(classes.linePrefixText, classes.noteText)}
            iconVariant="far"
            iconClassName={classNames(classes.prefixIcon, classes.note)}
          />
        ),
      };
      lines?.push(noteLine);
    }
  }
  const cardText = activityConfig.card.defaultTitle || customerName;
  const shortName = getCustomerShortName(compressedName);

  const modalHeader =
    `${toString(activityData?.custMiLoc)}${toString(
      activityData?.custNo
    )}${shortName}` || t('common:motionIndustries');
  const toastText = `${cardText}: ${toString(activityConfig.modal?.title)}`;

  return {
    activityConfig,
    cardText,
    toastText,
    modalHeader,
    activityVersion,
    isUnsupportedType,
    unknownActivityText,
    // #region activity params
    emailContactName: toString(
      activityConfig.modal?.defaultData?.emailContactName
    ),
    emailContact: activityConfig.modal?.defaultData?.emailContact as Contact,
    // #endregion
  };
};

export const useGetActionCardConfig = ({
  cardType,
  activityData,
  onCloseModal,
  codeList,
  loggedInUserId,
  compressedName,
}: GetActivityConfigProps): GetActivityConfigResponse => {
  const { t, i18n } = useTranslation(namespaces.activities);

  const activityConfig = getActivityConfig({
    cardType,
    activityData,
    loggedInUserId,
    codeList,
    onCloseModal,
    i18n,
    t,
    compressedName,
  });

  const { activityVersion, isUnsupportedType, unknownActivityText } =
    activityConfig;

  useEffect(() => {
    if (isUnsupportedType) {
      Sentry.withScope((scope) => {
        scope.setContext('activity', {
          historyId: activityData.historyId,
          eventTagName: cardType,
          version: activityVersion,
          eventTagNameId: activityData.eventTagName,
          userId: activityData.userId,
        });
        Sentry.addBreadcrumb({
          category: SentryCategories.ACTIVITIES,
          message: unknownActivityText,
          level: 'info',
        });
        Sentry.captureMessage(unknownActivityText, 'warning');
      });
    }
  }, [
    cardType,
    activityData.eventTagName,
    activityData.historyId,
    activityData.userId,
    activityVersion,
    isUnsupportedType,
    unknownActivityText,
  ]);

  return activityConfig;
};

export interface GetActivityTypeDataProps {
  eventTagName: string;
  t: TFunction;
}

export interface ActivityTypeConfig {
  cardType: ActivityTypeEnum;
  title: string;
  icon?: ActivityIconEnum;
  svgIcon?: string;
  color?: ActivityColorEnum;
}

export const getActivityType = (
  eventTagName: string,
  t: TFunction
): ActivityTypeConfig => {
  const eventDataParams = { eventTagName, t };
  // TODO: missing support for Important activities?
  return or(
    getOrderQuoteData(eventDataParams),
    getCustomerVisitData(eventDataParams),
    getWebActivityData(eventDataParams),
    getCustomerAccountData(eventDataParams),
    getSalesPlayData(eventDataParams),
    getPriceOverrideData(eventDataParams),
    getSourcingOverrideData(eventDataParams),
    getCrmActivityData(eventDataParams),
    getShopQuoteData(eventDataParams),
    getSalesOpportunityData(eventDataParams),
    getProTipData(eventDataParams),
    {
      cardType: ActivityType.unknown,
      title: t('unknown'),
      icon: ActivityIcon.unknown,
      color: ActivityColor.default,
    }
  );
};

export const getActivityFilterIcon = (type: string): ActivityIconEnum => {
  let defaultFilter = ActivityIcon.unknown;
  switch (type) {
    case ActivityFilter.important:
      defaultFilter = ActivityIcon.important;
      break;
    case ActivityFilter.followUp:
      defaultFilter = ActivityIcon.followUp;
      break;
    case ActivityFilter.snoozed:
      defaultFilter = ActivityIcon.snoozed;
      break;
    case ActivityFilter.done:
      defaultFilter = ActivityIcon.done;
      break;
    case ActivityFilter.unread:
      defaultFilter = ActivityIcon.unread;
      break;
    default:
  }
  return or<ActivityIconEnum>(
    getOrderQuoteFilterIcon(type),
    getCustomerVisitFilterIcon(type),
    getWebActivityFilterIcon(type),
    getCustomerAccountFilterIcon(type),
    getSalesPlayFilterIcon(type),
    getPriceOverrideFilterIcon(type),
    getSourcingOverrideFilterIcon(type),
    getCrmActivityFilterIcon(type),
    getShopQuoteFilterIcon(type),
    defaultFilter
  );
};
