import React from 'react';
import { isEmpty } from 'lodash';
import useFindActiveEmployees from 'ActivitiesApp/api/useFindActiveEmployees';
import EmployeeStatus from 'SearchApp/components/EmployeeStatus/EmployeeStatus';
import type { LoggedInStatusEnum } from 'SearchApp/utils/helpers';
import { calculateEmployeeStatusAging } from 'SearchApp/utils/helpers';
import type { Employee } from 'models/Employee';
import ActionRow from 'components/ActionRow/ActionRow';
import Text from 'components/Text/Text';
import classes from './ActiveEmployee.module.scss';

interface ActiveEmployeeItemProps {
  employee: Employee;
}

const ActiveEmployeeItem = ({
  employee,
}: ActiveEmployeeItemProps): JSX.Element => {
  const { name_empDisplayName: name, id, title, miLoc } = employee;
  const { activeEmployeeList } = useFindActiveEmployees({});
  const employeeFromCache = activeEmployeeList?.[id];
  const aging = calculateEmployeeStatusAging(
    employeeFromCache?.sinceLastLoggedIn
  );
  return (
    <ActionRow
      withArrow={false}
      disabled={false}
      testid="active-employee"
      className={classes.employeeActionRow}
    >
      <div className={classes.employeeWrapper}>
        <EmployeeStatus
          status={employeeFromCache?.status as LoggedInStatusEnum}
        />
        <div className={classes.employeeSpec}>
          <div className={classes.firstRow}>
            <Text text={name} variant="mipro-product-headline" />
            <Text
              text={aging}
              className={classes.aging}
              variant="content-smaller"
            />
          </div>

          <div className={classes.secondRow}>
            {!isEmpty(title) && (
              <>
                <Text
                  text={title}
                  variant="mipro-body-copy"
                  className={classes.title}
                  testid="title"
                />
                <Text
                  text="•"
                  variant="mipro-body-copy"
                  className={classes.separator}
                  testid="seperator"
                />
              </>
            )}
            {!isEmpty(miLoc) && (
              <Text text={miLoc} variant="mipro-body-copy" testid="miloc" />
            )}
          </div>
        </div>
      </div>
    </ActionRow>
  );
};

export default ActiveEmployeeItem;
