import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import {
  get,
  includes,
  isEmpty,
  isNil,
  kebabCase,
  map,
  size,
  toNumber,
  toString,
  values,
} from 'lodash';
import { IonRow } from '@ionic/react';
import { noActivityData } from 'ActivitiesApp/utils/helpers';
import Accordion from 'common/components/Accordion/Accordion';
import List from 'common/components/List/List';
import { or } from 'common/utils/logicHelpers';
import { formatNumber } from 'common/utils/numberHelper';
import { DataTypeEnum, formatKeyValue } from 'common/utils/valueFormatter';
import { namespaces } from 'i18n/i18n.constants';
import ListItem from 'InventoryApp/components/ListItem/ListItem';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import type { CartOrderItem } from 'ProductSearchApp/models/Order';
import {
  getProductFromOcnLine,
  isZcodedItem,
} from 'ProductSearchApp/util/ocnHelpers';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { ActionCardActivity } from 'models/ActivityModels';
import {
  goToProductDetail,
  goToReviewCart,
} from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import LongPressCopyGesture from 'components/LongPressCopyGesture/LongPressCopyGesture';
import Text from 'components/Text/Text';
import classes from './ActionCardModalData.module.scss';

export type ActivityModalData = Dictionary<{
  label: string;
  type: DataTypeEnum;
}>;

interface LineValue {
  value: unknown;
  customComponent: React.FC<unknown>;
}

export enum OcnTypeEnum {
  Order = 'Order',
  Quote = 'Quote',
}

interface ActionCardModalDataProps {
  activityData?: Dictionary<unknown>;
  activity?: ActionCardActivity;
  dataDefinition?: ActivityModalData;
  permitNotMappedValues?: boolean;
  showProductLines?: boolean;
  className?: string;
  rowClassName?: string;
  labelClassName?: string;
  ocn?: string;
  ocnType?: OcnTypeEnum;
  miLoc?: string;
  customerId?: string;
  onSendEmail?: () => void;
  onContactClick?: () => void;
  currency?: string;
  scrollParent?: HTMLElement | null;
}

const ActionCardModalData = ({
  activityData,
  activity,
  dataDefinition,
  permitNotMappedValues = false,
  showProductLines = true,
  className,
  rowClassName,
  labelClassName,
  ocn: pOcn,
  ocnType,
  miLoc: pMiLoc,
  customerId,
  onSendEmail,
  onContactClick,
  currency,
  scrollParent,
}: ActionCardModalDataProps): JSX.Element => {
  const ocn = toString(activityData?.orderNumber || pOcn);
  const miLoc = toString(activityData?.miLoc || pMiLoc);
  const id = toString(activityData?.custNo || customerId);

  const { t, i18n } = useTranslation(namespaces.activities);

  const { order, isLoading: isOcnLinesLoading } = useGetOrder({
    miLoc,
    orderCtlNo: ocn,
    enabled: showProductLines,
  });

  const ocnLines = order?.items;

  const canViewOrders = useAccessControls(
    AccessControlType.ViewOrdersAccessControls
  );

  const itemContent = (index: number, item: CartOrderItem) => {
    const {
      mfgPartNumber,
      itemNumber,
      unitSellPrice,
      imageURL,
      manufacturerName,
      priceSourceDesc,
      itemUOMDesc,
    } = getProductFromOcnLine(item);
    const isZcoded = isZcodedItem(itemNumber);

    return (
      <ListItem
        key={index}
        title={{
          text: mfgPartNumber,
          variant: !isZcoded && activity?.userLocationMatch ? 'link' : 'clear',
          href:
            !isZcoded && activity?.userLocationMatch
              ? goToProductDetail({
                  miLoc,
                  customerNo: id,
                  itemNo: itemNumber,
                })
              : undefined,
        }}
        overlay={manufacturerName}
        secondaryTextArray={[
          {
            text: t('activities:unitPrice', {
              price: formatNumber({
                number: unitSellPrice,
                currencyType: 'USD',
                scale: 2,
              }),
              priceSourceDesc,
              itemUOMDesc,
            }),
          },
        ]}
        testid="line-item"
        withArrow={false}
        leftImage={`${toString(process.env.REACT_APP_IMAGES_URL)}${imageURL}`}
      />
    );
  };

  return (
    <div data-testid="activity-action-card">
      <Loader
        testid="loader"
        className={classes.loader}
        text={t('common:loading')}
        isOpen={isOcnLinesLoading}
      />
      {!isOcnLinesLoading && (
        <div
          className={classNames(className, classes.activityDataWrapper)}
          data-testid="activity-list"
        >
          {map(activityData, (lineValue: unknown | LineValue, key: string) => {
            if (!permitNotMappedValues && !dataDefinition?.[key]) {
              return null;
            }

            const value = (get(lineValue, 'value') || lineValue) as unknown;
            const customComponent = get(
              lineValue,
              'customComponent'
            ) as React.ReactNode;

            const label = t(toString(dataDefinition?.[key]?.label));
            const type = dataDefinition?.[key]?.type as DataTypeEnum;
            const { language } = i18n;
            const formattedValue =
              toString(value) === noActivityData
                ? noActivityData
                : formatKeyValue({
                    value,
                    type,
                    language,
                    currency,
                  });
            let href: string | undefined;
            let isExternalLink = false;
            let disabledButton = false;
            let onClick: (() => void) | undefined;
            const isOCN = type === DataTypeEnum.ocn;
            let valueDataClass = '';

            if (type === DataTypeEnum.phone) {
              href = `tel:${toString(value)}`;
              isExternalLink = true;
            }
            if (type === DataTypeEnum.link) {
              href = toString(get(lineValue, 'href'));
            }
            if (type === DataTypeEnum.contact) {
              if (value !== noActivityData) {
                onClick = onContactClick;
              }
            }
            if (type === DataTypeEnum.email) {
              onClick = onSendEmail;
            }
            if (type === DataTypeEnum.percent) {
              valueDataClass =
                toNumber(value) >= 0
                  ? classes.positiveText
                  : classes.negativeText;
            }
            if (isOCN) {
              href = goToReviewCart({
                miLoc,
                shipToCustNo: id,
                orderCtlNo: ocn,
                ocnType: ocnType === OcnTypeEnum.Order ? 'order' : 'quote',
                ocnMode: 'review',
              });
              if (!canViewOrders) {
                disabledButton = true;
              }
            }

            const Wrapper = isOCN ? LongPressCopyGesture : React.Fragment;
            const wrapperProps = isOCN
              ? { copyableValue: toString(formattedValue) }
              : undefined;

            return (
              <IonRow
                className={classNames(rowClassName, classes.detail)}
                key={key}
              >
                {!isNil(type) ? (
                  <Text
                    className={classNames(labelClassName, classes.label)}
                    variant="content-heavy"
                    text={label}
                  />
                ) : (
                  <div className={classes.searchButton}>
                    <Button
                      className={classes.search}
                      variant="link"
                      disabled
                      text={`"${label}"`}
                      testid={`${label}-search-button`}
                    />
                  </div>
                )}
                {!isNil(customComponent) && customComponent}
                {isNil(customComponent) &&
                  (or(
                    includes(
                      [
                        DataTypeEnum.link,
                        DataTypeEnum.phone,
                        DataTypeEnum.address,
                        DataTypeEnum.email,
                        DataTypeEnum.contact,
                      ],
                      type
                    ),
                    type === DataTypeEnum.ocn && activity?.userLocationMatch
                  ) && value !== noActivityData ? (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Wrapper {...wrapperProps}>
                      {disabledButton ? (
                        <Text
                          className={classNames(
                            classes.value,
                            classes.linkValue
                          )}
                          variant="content-heavy"
                          text={formattedValue}
                          testid={`${label}-button`}
                        />
                      ) : (
                        <Button
                          className={classNames(
                            classes.value,
                            classes.linkValue
                          )}
                          variant="link"
                          text={formattedValue}
                          href={href}
                          isExternalLink={isExternalLink}
                          onClick={onClick}
                          testid={`${label}-button`}
                        />
                      )}
                    </Wrapper>
                  ) : (
                    <Text
                      className={classNames(classes.value, valueDataClass)}
                      text={formattedValue}
                      testid={`${kebabCase(label)}-value`}
                    />
                  ))}
              </IonRow>
            );
          })}
        </div>
      )}
      {showProductLines &&
        !isOcnLinesLoading &&
        !isEmpty(ocn) &&
        size(ocnLines) > 0 && (
          <Accordion
            sections={[
              {
                title: {
                  text: t('common:products'),
                  customContent: (
                    <Text
                      text={toString(order?.linesOrdered)}
                      variant="content-small"
                      className={classes.count}
                    />
                  ),
                },
                value: 'products',
                content: (
                  <List
                    testid="ocn-lines-list"
                    scrollParent={scrollParent}
                    data={values(ocnLines)}
                    className={classes.list}
                    itemContent={itemContent}
                  />
                ),
              },
            ]}
            testid="product-accordion"
            className={classes.ocnLinesAccordion}
          />
        )}
    </div>
  );
};

export default ActionCardModalData;
