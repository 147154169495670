import { size } from 'lodash';
import { getURLParams } from 'common/api/utils/apiHelpers';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import { getIndustryUserCountAPI } from '.';

export const getIndustryUserCountQueryKey = 'get-industry-user-count';

interface GetIndustryUserCountAPI {
  sharedUserCount: number;
}

interface UseGetIndustryUserCountProps {
  industries?: string[];
}

const useGetIndustryUserCount = ({
  industries,
}: UseGetIndustryUserCountProps) => {
  const { axios } = useAxios();
  const params = { industryId: industries };

  const response = useQuerySetup<number>({
    queryKey: getIndustryUserCountQueryKey,
    queryParams: params,
    isInfiniteQuery: false,
    enabled: size(industries) > 0,
    onAPIRequest: async ({ signal }) => {
      const { data } = await axios.get<GetIndustryUserCountAPI>(
        getIndustryUserCountAPI(getURLParams(params)),
        { signal }
      );
      return data.sharedUserCount;
    },
  });

  return { userCount: response.result, ...response };
};

export default useGetIndustryUserCount;
