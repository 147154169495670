import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AppIcons from 'constants/appIcons';
import { includes, isEmpty } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { ProTipEnum } from 'ActivitiesApp/models/ProTip';
import { choose } from 'common/utils/logicHelpers';
import {
  differenceInMinutes,
  formatDistance,
  isFuture,
  isToday,
} from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import { useToasts } from 'providers/ToastProvider';
import useUpdateActivity from 'api/activities/useUpdateActivity';
import type {
  ActionCardActivity,
  ActivityTypeEnum,
  UpdateActivityFlags,
} from 'models/ActivityModels';
import { IconColor } from 'models/Legend';
import type { SnoozedData } from 'models/SnoozedData';
import { formatSnoozeDate, getDateLocale, parseDate } from 'utils/date';
import ProTipSvg from 'assets/ProTip.svg';
import ComponentActionCard from 'components/Activities/ActionCard/ActionCard';
import DefaultActionCard from 'components/Activities/ActionCard/DefaultActionCard';
import type { SwipeOption } from 'components/Slider/Slider';
import type { CodeListOptionProps } from './ActivityCardConfig';
import { useGetActionCardConfig } from './ActivityCardConfig';

interface ActionCardProps {
  historyId: number;
  activity: ActionCardActivity;
  cardType: ActivityTypeEnum;
  setIsOpenModal?: (b: boolean) => void;
  onClick?: (data: { activity: ActionCardActivity }) => void;
  onLongPress?: (data: number) => void;
  testid: string;
  codeList?: CodeListOptionProps[];
  onMultiSelectAction?: (data: number) => void;
  icon?: IconName;
  color?: IconColor | string;
  avatarContainerClass?: string;
  loggedInUserId?: string;
  disableSwipe?: boolean;
  isDisabled?: boolean;
}

const ActivityActionCard = ({
  className,
  historyId,
  activity,
  cardType,
  setIsOpenModal,
  onClick,
  onLongPress,
  onMultiSelectAction,
  testid,
  codeList,
  icon,
  color,
  avatarContainerClass,
  loggedInUserId,
  disableSwipe,
  isDisabled,
}: ActionCardProps & IonicReactProps): JSX.Element => {
  const customerName = activity?.customerName;
  const { t, i18n } = useTranslation(namespaces.activities);
  const isActivityOwner = activity.userId === loggedInUserId;
  const { onUpdateActivity } = useUpdateActivity({
    userId: activity.userId,
    historyId,
  });

  const { addToast } = useToasts();

  const onCloseModal = () => {
    setIsOpenModal?.call(null, false);
  };

  const handleDisabledOnClick = () => {
    addToast({
      testid: 'activity-action-card-disabled',
      text: choose(
        !isActivityOwner,
        t('disabledDeleteNotOwnerTask'),
        t('disabledDeleteCrmTask')
      ),
    });
  };

  const { activityConfig, cardText, isUnsupportedType } =
    useGetActionCardConfig({
      cardType,
      activityData: activity,
      onCloseModal,
      codeList,
      loggedInUserId,
    });

  const showAfterDate = parseDate(activity.showAfterISO);
  const createdTimeDate = parseDate(activity.creationTimestampISO);

  // #region activity actions
  const isSnoozedBefore: boolean =
    differenceInMinutes(showAfterDate, createdTimeDate) !== 0;

  const snoozedData: SnoozedData = {
    isSnoozed:
      activity.isSnoozed === 'Y' &&
      (isFuture(showAfterDate) || isToday(showAfterDate)),
    isSnoozedBefore: isSnoozedBefore && activity.isSnoozed === 'N',
    when:
      activity.isSnoozed === 'Y'
        ? t('snoozeUntilDate', {
            formatDate: formatSnoozeDate(showAfterDate, i18n.language),
          })
        : t('snoozedDate', {
            formatDate: formatDistance(showAfterDate, new Date(), {
              addSuffix: true,
              locale: getDateLocale(),
            }),
          }),
  };

  const isFollowedUp = activity.followUp === 'Y';
  const isDone = activity.done === 'Y';

  const onUpdateActivityFlags = useCallback(
    ({ undoData, activityUpdate }: UpdateActivityFlags) => {
      onUpdateActivity(activityUpdate);
      addToast({
        text: activityUpdate.done ? t('removeActivity') : t('savedToast'),
        button: {
          role: 'undo',
          text: t('common:undo'),
          handler: () => onUpdateActivity(undoData),
        },
        testid: 'update-activity-toast',
      });
    },
    [addToast, onUpdateActivity, t]
  );

  const onDone = () => {
    onUpdateActivityFlags?.({
      undoData: {
        done: activity?.done === 'Y',
        followUp: activity?.followUp === 'Y',
        showAfter: activity?.showAfterISO,
      },
      activityUpdate: {
        done: true,
        followUp: !isFollowedUp,
        showAfter: activity?.creationTimestampISO,
      },
    });
  };

  const onDoneOption: SwipeOption = {
    icon: AppIcons.doneIcon(isDone),
    text: t(`${namespaces.common}:remove`),
    className: 'deleteSlider',
    onSwipeComplete: onDone,
  };

  const svgIcon = cardType === ProTipEnum.proTip ? ProTipSvg : undefined;
  if (isUnsupportedType) {
    return (
      <DefaultActionCard
        className={className}
        text={cardText}
        description={activityConfig.card.body.description}
        aging={activity.creationTimestampISO}
        snoozedData={snoozedData}
        icon={icon}
        iconColor={color}
        backgroundColor={IconColor.grey}
        avatarContainerClass={avatarContainerClass}
        onMultiSelectAction={() => {
          onMultiSelectAction?.(activity.historyId);
        }}
        onLongPress={() => {
          onLongPress?.(activity.historyId);
        }}
        testid={testid}
        onSlideLeft={isActivityOwner ? onDoneOption : undefined}
        nonLoggedInOwnerEmpId={!isActivityOwner ? activity.employeeId : ''}
        ownerName={activity.userFullName}
      />
    );
  }

  return (
    <ComponentActionCard
      className={className}
      withAvatar={!isEmpty(customerName)}
      text={cardText}
      body={activityConfig.card.body}
      highlight={activityConfig.card.highlight}
      aging={activity.creationTimestampISO}
      isRead={activity?.eventRead !== 'N'}
      icon={icon}
      svgIcon={
        !includes(avatarContainerClass, 'multiSelectEnabled')
          ? svgIcon
          : undefined
      }
      iconColor={color}
      backgroundColor={IconColor.grey}
      onClick={() => onClick?.({ activity })}
      snoozedData={snoozedData}
      customContent={activityConfig.card.customContent}
      testid={testid}
      statusIcon={
        isFollowedUp ? AppIcons.followUpIcon(isFollowedUp) : undefined
      }
      statusColor="primary"
      onMultiSelectAction={() => onMultiSelectAction?.(activity.historyId)}
      onLongPress={() => {
        onLongPress?.(activity.historyId);
      }}
      avatarContainerClass={avatarContainerClass}
      onSlideLeft={choose(!disableSwipe, onDoneOption)}
      nonLoggedInOwnerEmpId={!isActivityOwner ? activity.employeeId : ''}
      ownerName={activity.userFullName}
      disabled={isDisabled}
      onDisabledClick={handleDisabledOnClick}
    />
  );
};

const areEqual = (
  {
    historyId: prevHistoryId,
    avatarContainerClass: prevAvatarClassName,
    icon: prevIcon,
    activity: prevActivity,
  }: ActionCardProps & IonicReactProps,
  {
    historyId: nextHistoryId,
    avatarContainerClass: nextAvatarClassName,
    icon: nextIcon,
    activity: nextActivity,
  }: ActionCardProps & IonicReactProps
) => {
  return (
    prevHistoryId === nextHistoryId &&
    prevAvatarClassName === nextAvatarClassName &&
    prevIcon === nextIcon &&
    prevActivity.followUp === nextActivity.followUp &&
    prevActivity.done === nextActivity.done &&
    prevActivity.isSnoozed === nextActivity.isSnoozed &&
    prevActivity.eventRead === nextActivity.eventRead &&
    prevActivity.showAfter === nextActivity.showAfter &&
    prevActivity.showAfterISO === nextActivity.showAfterISO &&
    prevActivity.isOptimisticallyUpdating ===
      nextActivity.isOptimisticallyUpdating &&
    prevActivity.commentCount === nextActivity.commentCount
  );
};

export default React.memo(ActivityActionCard, areEqual);
