import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { isArray, map, reduce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonItem } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import {
  getOptionalRenderProp,
  useStikcyElement,
} from 'common/components/utils/renderHelpers';
import type {
  BaseComponentProps,
  OptionalRenderProp,
} from 'common/components/utils/renderHelpers';
import Text from 'components/Text/Text';
import classes from './Accordion.module.scss';

export interface AccordionSection extends BaseComponentProps {
  value: string;
  title: OptionalRenderProp;
  stickyOffset?: number;
  content?: React.ReactNode;
  hidden?: boolean;
}

interface AccortionSectionProps {
  section: AccordionSection;
  expanded: boolean;
  onToggleExpand?: () => void;
  scrollParent?: HTMLElement | null;
  multiple?: boolean;
}

const AccortionSection = ({
  section,
  expanded,
  onToggleExpand,
  scrollParent,
  multiple,
}: AccortionSectionProps): JSX.Element => {
  const { node, nodeRef } = useStikcyElement<HTMLIonItemElement>(
    section.stickyOffset
  );

  if (section.hidden) {
    return <></>;
  }

  const { text: sectionTitle, props: sectionTitleProps } =
    getOptionalRenderProp(section.title);

  return (
    <div
      className={classNames(classes.section, section.className, {
        [classes.expanded]: expanded,
      })}
      data-testid={`accordion-${section.value}`}
    >
      <IonItem
        ref={nodeRef}
        detail={false}
        lines="none"
        button
        data-testid="accordion-header"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...sectionTitleProps?.wrapperProps}
        onClick={() => {
          onToggleExpand?.();
          if (!expanded) {
            setTimeout(() => {
              scrollParent?.scroll({
                top:
                  ((node?.parentNode as HTMLElement)?.offsetTop || 0) -
                  (scrollParent?.offsetTop || 0),
                behavior: 'smooth',
              });
            }, 500);
          } else if (multiple) {
            setTimeout(() => {
              scrollParent?.scroll({
                top:
                  ((node?.parentNode?.nextSibling as HTMLElement)?.offsetTop ||
                    0) - (scrollParent?.offsetTop || 0),
              });
            }, 500);
          }
        }}
        className={classNames(
          classes.header,
          sectionTitleProps?.wrapperProps?.className
        )}
      >
        <div className={classes.iconWrapper} data-testid="header-arrow">
          <FontAwesomeIcon
            className={classes.icon}
            icon={['fas', 'chevron-down']}
          />
        </div>
        {sectionTitle && (
          <Text
            text={sectionTitle}
            variant="mipro-h3-headline"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...sectionTitleProps}
          />
        )}
        {sectionTitleProps?.customContent}
      </IonItem>
      <div className={classes.content} data-testid="accordion-content">
        {section.content}
      </div>
    </div>
  );
};

interface AccordionProps {
  sections: AccordionSection[];
  value?: string | string[];
  onChange?: (v: string, b: boolean) => void;
  scrollParent?: HTMLElement | null;
  multiple?: boolean;
  testid: string;
}

const Accordion = ({
  className,
  sections,
  value,
  onChange,
  scrollParent,
  multiple = false,
  testid,
}: AccordionProps & IonicReactProps): JSX.Element => {
  const [expanded, setExpanded] = useState<Dictionary<boolean>>({});
  const values = useMemo(() => {
    if (!value) {
      return [];
    }
    return isArray(value) ? value : [value];
  }, [value]);

  useEffect(() => {
    setExpanded(reduce(values, (prev, v) => ({ ...prev, [v]: true }), {}));
  }, [values]);

  return (
    <div
      className={classNames(classes.accordion, className)}
      data-testid={testid}
    >
      {map(sections, (section, sectionIndex) => (
        <AccortionSection
          key={`${section.value}-${sectionIndex}`}
          section={section}
          expanded={expanded[section.value]}
          onToggleExpand={() => {
            const v = section.value;
            const b = !expanded[section.value];
            setExpanded((prev) => ({ ...(multiple ? prev : {}), [v]: b }));
            onChange?.(v, b);
          }}
          scrollParent={scrollParent}
          multiple={multiple}
        />
      ))}
    </div>
  );
};

export default Accordion;
