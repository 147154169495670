import { useSelector } from 'react-redux';
import { find, get, map, toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { getActivityQueryKey } from 'api/activities/useGetActivity';
import { getQueryCacheKeys, onErrorUpdate, onMutateUpdate } from 'api/helpers';
import type { ActionCardActivity } from 'models/ActivityModels';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { deleteVote, thumbsDownVote, thumbsUpVote } from '.';

export const useUpdateSubscriptionQuerykey = 'update-subscription';

interface UseUpdateVoteResponse {
  status: MutationStatus;
  onUpdateVote: (body: UpdateVote) => void;
}
interface UseUpdateVoteProps {
  historyId: number;
}
interface UpdateVote {
  like?: boolean;
}

const useUpdateVote = ({
  historyId,
}: UseUpdateVoteProps): UseUpdateVoteResponse => {
  const { axios } = useAxios();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = toString(get(userInfo, 'userid'));
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const doUpdateVote = async (body: UpdateVote) => {
    if (body.like === undefined) {
      return axios.delete<string>(deleteVote(historyId, userId));
    }
    if (body.like) {
      return axios.put<string>(thumbsUpVote(historyId, userId));
    }
    return axios.put<string>(thumbsDownVote(historyId, userId));
  };

  const { status, mutate } = useMutation(doUpdateVote, {
    onMutate: async (vars) => {
      const cachedActivity = queryClient.getQueryData<ActionCardActivity>(
        getQueryCacheKeys(queryClient, getActivityQueryKey, {
          historyId: toNumber(historyId),
        })?.[0]
      );
      // set the new thumb choice value
      let newThumbChoice = 'N';
      if (vars.like === undefined) {
        newThumbChoice = '';
      } else if (vars.like) {
        newThumbChoice = 'Y';
      }
      let newThumbCount = cachedActivity?.thumbCount ?? [];
      // set default values if not there
      if (!find(newThumbCount, (i) => i.vote === 'N')) {
        newThumbCount.push({ count: 0, vote: 'N' });
      }
      if (!find(newThumbCount, (i) => i.vote === 'Y')) {
        newThumbCount.push({ count: 0, vote: 'Y' });
      }
      // if want to undo your like/dislike
      if (newThumbChoice === '') {
        newThumbCount = map(newThumbCount, (i) => {
          if (i.vote === cachedActivity?.thumbChoice && i.count > 0) {
            return {
              count: i.count - 1,
              vote: i.vote,
            };
          }
          return i;
        });
      } else {
        // like or dislike something
        newThumbCount = map(newThumbCount, (i) => {
          // remove count from previous like/dislike if it was done
          if (i.vote === cachedActivity?.thumbChoice) {
            return {
              count: i.count - 1,
              vote: i.vote,
            };
          }
          // add count to which one you chose now
          if (i.vote === newThumbChoice) {
            return {
              count: i.count + 1,
              vote: i.vote,
            };
          }
          return i;
        });
      }
      const activityContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: getActivityQueryKey,
        updatedItems: [
          {
            thumbCount: newThumbCount,
            thumbChoice: newThumbChoice,
          },
        ],
        findPredicates: [{ historyId: toNumber(historyId) }],
        isSingleQuery: true,
      });
      return { activityContext };
    },
    onError: (_err, vars, context) => {
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: context?.activityContext,
        isSingleQuery: true,
      });
      addToast({
        type: ToastType.error,
        text: i18next.t('ActivitiesApp-Comments:voteAPIError'),
        testid: 'update-vote-error-toast',
      });
    },
  });

  return {
    status,
    onUpdateVote: (body: UpdateVote) => mutate(body),
  };
};

export default useUpdateVote;
