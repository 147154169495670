import { useSelector } from 'react-redux';
import { toString } from 'lodash';
import useAPIUrl from 'api';
import type { QueryFlags } from 'common/api/utils/useGetQueryFlags';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import type { RootState } from 'store/reducers';
import { HOURS_IN_MILLISECONDS } from 'utils/constants';

export const unreadNoticesQueryKey = 'unread-notices-count';

export interface UnreadNoticesCount {
  unreadCount: number;
}
interface UseGetUnreadNoticesCountResponse {
  data?: UnreadNoticesCount;
}

const useGetUnreadNoticesCount = ({
  enabled = true,
}: {
  enabled?: boolean;
}): UseGetUnreadNoticesCountResponse & QueryFlags => {
  const { axios } = useAxios();
  const { unreadNoticesCountAPI } = useAPIUrl();
  const { noticesLastSeenTime } = useSelector((state: RootState) => state.user);

  const response = useQuerySetup<UnreadNoticesCount>({
    queryKey: unreadNoticesQueryKey,
    queryParams: { noticesLastSeenTime },
    options: {
      refetchIntervalInBackground: true,
      // 1 hour in miliseconds
      refetchInterval: HOURS_IN_MILLISECONDS,
    },
    onAPIRequest: async ({ signal }) => {
      const { data } = await axios.get<UnreadNoticesCount>(
        unreadNoticesCountAPI(
          encodeURIComponent(toString(noticesLastSeenTime))
        ),
        { signal }
      );
      return data;
    },
    enabled,
    isInfiniteQuery: false,
  });

  const { data: responseData, ...queryFlags } = response;

  return {
    ...queryFlags,
    data: responseData as UnreadNoticesCount,
  };
};

export default useGetUnreadNoticesCount;
