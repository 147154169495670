import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { filter, isEmpty, map, size, toNumber, toString } from 'lodash';
import {
  IonContent,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonPage,
  IonLoading,
} from '@ionic/react';
import Header from 'common/components/Header/Header';
import { and, ifRender, or } from 'common/utils/logicHelpers';
import useDownloadCountPlanOptions from 'InventoryApp/api/useDownloadCountPlanOptions';
import useDownloadGroupItems from 'InventoryApp/api/useDownloadGroupItems';
import useFindCountGroups from 'InventoryApp/api/useFindCountGroups';
import useRemoveCountGroup from 'InventoryApp/api/useRemoveCountGroup';
import InventoryCustomTitle from 'InventoryApp/components/InventoryCustomTitle/InventoryCustomTitle';
import ListItem from 'InventoryApp/components/ListItem/ListItem';
import useInventoryHeaderActions from 'InventoryApp/hooks/useInventoryHeaderActions';
import { CountGroupListTypeEnum } from 'InventoryApp/models/InventoryPlanGroup';
import type { CountGroup } from 'InventoryApp/models/InventoryPlanGroup';
import {
  getInventoryPlanIcon,
  inventoryCountType,
  isStartVMI,
  startVMICountType,
} from 'InventoryApp/util/inventoryUtil';
import useInventoryPermissions from 'InventoryApp/util/useInventoryPermissions';
import {
  countGroupListURL,
  countPlanURL,
  homeInventoryURL,
  inventoryURL,
} from 'navigation';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import { useGetSelectedMiLoc } from 'api/helpers';
import { ToastType } from 'models/Toast';
import { DateFormatEnum, formatDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import ChangeLocation from 'components/ChangeLocation/ChangeLocation';
import Loader from 'components/Loader/Loader';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import Refresher from 'components/Refresher/Refresher';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './CountGroupList.module.scss';

interface CountGroupListProps {
  countListType: CountGroupListTypeEnum;
}

const CountGroupList = ({
  countListType,
}: CountGroupListProps): JSX.Element => {
  const history = useHistory();
  const showResumeSavedCount = countListType === 'pending';
  const { hasInventoryPermission, hasVmiPermission } =
    useInventoryPermissions();
  const { t } = useTranslation();
  const { fromVirtualTeam, team, isBranchUser } = useGetSelectedMiLoc();
  const { isOnline } = useNetworkStatus();
  const { addToast } = useToasts();
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<CountGroup>();
  const [deleteGroup, setDeleteGroup] = useState<CountGroup>();

  const isShowChangeLocation = !isBranchUser && !fromVirtualTeam && !team;

  const countTypesToSearch = useMemo(() => {
    const countTypes = [];
    if (hasVmiPermission) {
      countTypes.push(...startVMICountType);
    }
    if (hasInventoryPermission) {
      countTypes.push(...inventoryCountType);
    }
    return countTypes;
  }, [hasInventoryPermission, hasVmiPermission]);

  const { groups, showLoader, refetch, lastUpdatedAt, loc } =
    useFindCountGroups({
      countStatus: 'O',
      countType: countTypesToSearch,
      enabled: !isShowChangeLocation,
    });

  const filteredGroups: CountGroup[] = useMemo(
    () =>
      filter(groups, (item) => {
        return (
          showResumeSavedCount ||
          (toNumber(item.downloadedItems) === 0 && !isStartVMI(item))
        );
      }),
    [groups, showResumeSavedCount]
  );

  const { onRemoveCountGroup, status: deleteStatus } = useRemoveCountGroup();
  const { onDownloadGroupItems, status: downloadItemsStatus } =
    useDownloadGroupItems();
  const { onDownloadCountPlanOptions, status: downloadOptionsStatus } =
    useDownloadCountPlanOptions();

  const isDeleting = deleteStatus === 'loading';

  const getWarningMessage = (group: CountGroup) => {
    if (group.isDeleted) {
      return t('inventory:countGroupNotExist');
    }
    if (showResumeSavedCount && toNumber(group.downloadedItems) <= 0) {
      return t('inventory:itemNotOnDevice');
    }
    return '';
  };

  const onSelectCountGroup = (group: CountGroup) => {
    setSelectedGroup(group);
    if (isOnline && !group.isDeleted) {
      onDownloadCountPlanOptions({ countPlanId: group.countPlanId });
      onDownloadGroupItems({
        miLoc: group.miLoc,
        countPlanId: group.countPlanId,
        groupId: group.groupId,
        groupUniqueId: group.uniqueId,
        storeroomNo: group.storeroomNo,
      });
    } else {
      history.push(
        concatRoutes(
          inventoryURL(),
          countPlanURL(
            group.countPlanId,
            group.groupId,
            group.miLoc,
            group.uniqueId,
            group.customerNo || ''
          )
        )
      );
    }
  };

  const getSecondaryTextArray = (group: CountGroup) => {
    const returnArray = [];
    if (showResumeSavedCount) {
      returnArray.push({
        text: t('inventory:countingProgress', {
          lines: group.lines,
          linesUpdated: group.linesUpdated,
        }),
      });
    }
    returnArray.push({
      text: t('inventory:pendingCountDesc', {
        date: formatDate(
          new Date(group.dateDownloaded),
          DateFormatEnum.shortMonth
        ),
      }),
    });
    return returnArray;
  };

  useEffect(() => {
    if (
      isOnline &&
      downloadItemsStatus === 'success' &&
      downloadOptionsStatus === 'success' &&
      selectedGroup
    ) {
      history.push(
        concatRoutes(
          inventoryURL(),
          countPlanURL(
            selectedGroup.countPlanId,
            selectedGroup.groupId,
            selectedGroup.miLoc,
            selectedGroup.uniqueId,
            selectedGroup.customerNo || ''
          )
        )
      );
    }
  }, [
    isOnline,
    downloadOptionsStatus,
    downloadItemsStatus,
    history,
    selectedGroup,
  ]);

  const { headerActions } = useInventoryHeaderActions({});

  return (
    <IonPage test-id="pending-count-plan-list-page">
      <Header
        hideCartButton
        hideNoticesBellButton
        hideMenuButton
        testid="pending-count-plan-list"
        headerActions={headerActions}
        customTitle={
          <InventoryCustomTitle title={t('inventory:countGroups')} />
        }
      />
      <IonContent className={classes.content}>
        <Refresher
          slot="fixed"
          onRefresh={refetch}
          hidden
          testid="count-groups-refresher"
          disabled={showLoader || isShowChangeLocation}
          lastUpdatedAt={lastUpdatedAt}
        />
        {!isShowChangeLocation && (
          <>
            <div className={classes.contentTitle}>
              <Text
                text={
                  showResumeSavedCount
                    ? t('inventory:pendingListTitle', { count: size(groups) })
                    : t('inventory:createNewInventoryCount')
                }
                variant="mipro-h3-headline"
              />
            </div>
            {isOnline && isEmpty(filteredGroups) && !showLoader && (
              <WarningMessage
                icon={['far', 'info-circle']}
                title={
                  showResumeSavedCount
                    ? t('inventory:countGroupsWrnTitle')
                    : t('inventory:inventoryGroupWrnTitle')
                }
                body={
                  showResumeSavedCount
                    ? t('inventory:countGroupsWrnMsg')
                    : t('inventory:inventoryGroupWrnMsg', {
                        loc,
                      })
                }
                className={classes.warngMessage}
              >
                <Button
                  className={classes.warningBtn}
                  variant="action"
                  text={
                    showResumeSavedCount
                      ? t('inventory:homeInventory')
                      : t('inventory:viewInProgressCounts')
                  }
                  testid="view-in-progress"
                  onClick={() =>
                    showResumeSavedCount
                      ? history.replace(
                          concatRoutes(inventoryURL(), homeInventoryURL())
                        )
                      : history.replace(
                          concatRoutes(
                            inventoryURL(),
                            countGroupListURL(CountGroupListTypeEnum.pending)
                          )
                        )
                  }
                />
              </WarningMessage>
            )}
            {!isOnline && !showResumeSavedCount && (
              <WarningMessage
                icon={['far', 'info-circle']}
                title={t('inventory:offlineMsg')}
                className={classes.warngMessage}
              />
            )}
            {or(isOnline, showResumeSavedCount) && (
              <IonList>
                {map(filteredGroups, (item) => (
                  <IonItemSliding
                    key={item.uniqueId}
                    data-testid={`count-group-${item.uniqueId}`}
                  >
                    {ifRender(
                      showResumeSavedCount,
                      <IonItemOptions>
                        <IonItemOption
                          className={classNames(
                            classes.deleteOption,
                            classes.options
                          )}
                          onClick={() => {
                            if (isOnline) {
                              setDeleteGroup(item);
                              setConfirmationModal(true);
                              return;
                            }
                            addToast({
                              type: ToastType.alert,
                              variant: 'mipro-toast',
                              text: t('inventory:offlineDeleteMsg'),
                              testid: 'count-group-delete-warn-toast',
                            });
                          }}
                          data-testid="remove-count-group"
                        >
                          <Button
                            icon={findIcon('trash-can', 'far')}
                            text={t('common:remove')}
                            testid="slide-left"
                          />
                        </IonItemOption>
                      </IonItemOptions>
                    )}
                    <ListItem
                      testid={toString(item.uniqueId)}
                      onClick={() => onSelectCountGroup(item)}
                      title={item.name}
                      overlay={item.countTypeDescription}
                      withoutHrefArrow
                      disabled={or(
                        and(!isOnline, toNumber(item.downloadedItems) <= 0),
                        item.isOptimisticallyUpdating
                      )}
                      subTitle={item.planName}
                      secondaryTextArray={getSecondaryTextArray(item)}
                      badge={{
                        text: item.isDeleted ? t('inventory:viewOnly') : '',
                        icon: 'eye',
                        type: 'warning',
                      }}
                      alert={getWarningMessage(item)}
                      planIcon={getInventoryPlanIcon(item.countType)}
                    />
                  </IonItemSliding>
                ))}
              </IonList>
            )}
          </>
        )}
        {isShowChangeLocation && (
          <ChangeLocation
            fromInventory
            onlineMsg={t('inventory:changeLocationMsg')}
            offlineMsg={t('inventory:changeLocationOfflineMsg')}
            testid="inventory"
          />
        )}
        <IonLoading
          isOpen={
            downloadItemsStatus === 'loading' ||
            downloadOptionsStatus === 'loading'
          }
          message={t('inventory:downloadingData')}
        />
        <IonLoading
          isOpen={isDeleting}
          message={t('inventory:deleteCountGroupOfflineMsg')}
        />
        <Loader
          isOpen={showLoader && isEmpty(filteredGroups)}
          text={t('inventory:loadingCountGroups')}
          className={classes.loader}
        />
        <DiscardModal
          title={t('inventory:deleteGroupCountTitle')}
          className={classes.discardModal}
          isOpen={showConfirmationModal}
          setIsOpen={setConfirmationModal}
          testid="delete-count-group"
          initialBreakpoint={0.4}
          backdropDismiss={false}
          withRightCloseButton
          discardMsg={t('inventory:deleteGroupCountMessage')}
          goBackButtonTitle={t('inventory:goBack')}
          discardButtonTitle={t('inventory:deleteGroup')}
          onDiscardClick={() => {
            onRemoveCountGroup({
              name: toString(deleteGroup?.customerName),
              miLoc: toString(deleteGroup?.miLoc),
              groupUniqueId: toString(deleteGroup?.uniqueId),
              countPlanId: toString(deleteGroup?.countPlanId),
              isDeleted: deleteGroup?.isDeleted,
              deleteGroupOnServer: isStartVMI(deleteGroup),
              groupId: toString(deleteGroup?.groupId),
            });
          }}
          secondayButtonVariant="mipro-danger-default"
        />
      </IonContent>
    </IonPage>
  );
};

export default CountGroupList;
