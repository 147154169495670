import { useSelector } from 'react-redux';
import type { Dictionary } from 'lodash';
import { get, isNil, map, reduce, toString } from 'lodash';
import useAPIUrl from 'api';
import type { QueryFlags } from 'common/api/utils/useGetQueryFlags';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { or } from 'common/utils/logicHelpers';
import { useAxios } from 'providers/AxiosProvider';
import useFindDataCodes from 'api/data/useFindDataCodes';
import { useMiLocOrTeamId } from 'api/helpers';
import type {
  ActionCardActivity,
  ActivityFieldData,
} from 'models/ActivityModels';
import { ActivityType } from 'models/ActivityModels';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';
import type { DateParam } from 'utils/date';
import { DateFormatEnum, formatDate } from 'utils/date';
import { getIsCorpAccount } from 'utils/search';
import type { CodeListOptionProps } from 'pages/Activities/ActivityActionCard/ActivityCardConfig';

export const findActivitiesV2QueryKey = 'user-activities-v2';

export const reduceFieldsData = (
  fields: ActivityFieldData[] = []
): Dictionary<unknown> =>
  reduce(fields, (prev, field) => ({ ...prev, [field.name]: field.value }), {});

interface UseFindActivitiesProps {
  miLoc?: string;
  id?: string;
  natlAccNo?: string;
  filter?: Record<string, unknown>;
  fetchOtherUsers?: boolean;
  visitContact?: string;
  startDate?: DateParam;
  endDate?: DateParam;
  sortField?: string;
  sortDir?: string;
  enabled?: boolean;
  groupSortField?: string;
  limit?: number;
  enabledDataCodesQuery?: boolean;
  includeOthers?: boolean;
}

interface UseFindActivitiesResponse extends QueryFlags {
  hasError?: boolean;
  totalItems?: number;
  items: ActionCardActivity[];
  codeList?: CodeListOptionProps[];
}

interface FindActivitiesAPIResponse {
  rows: ActionCardActivity[];
  totalRows: number;
}

const getQueryParams = ({
  miLoc,
  id,
  natlAccNo,
  filter,
  fetchOtherUsers = false,
  visitContact,
  endDate,
  startDate,
  sortField = 'showAfter',
  sortDir = 'DESCENDING',
  groupSortField,
  limit = pageSize(),
  includeOthers = false,
  userId,
}: UseFindActivitiesProps & { userId: string }): Record<string, string> => {
  const params: Record<string, string> = {
    sortField,
    sortDir,
    limit: toString(limit),
    ...filter,
  };

  if (miLoc) {
    params.custMiLoc = miLoc;
  }
  if (id) {
    if (natlAccNo && getIsCorpAccount(id, natlAccNo)) {
      params.nationalAcctNo = natlAccNo;
    } else {
      params.custNo = id;
    }
  }
  if (includeOthers) {
    params.distinctHistoryId = toString(true);
  } else {
    params.userId = userId;
  }
  if (fetchOtherUsers) {
    delete params.userId;
  }
  if (isNil(params.isDone)) {
    delete params.isDone;
  }
  if (visitContact) {
    params.visitContact = visitContact;
  }
  if (endDate) {
    params.endDate = formatDate(endDate, DateFormatEnum.activitiesDateRangeAPI);
  }
  if (startDate) {
    params.startDate = formatDate(
      startDate,
      DateFormatEnum.activitiesDateRangeAPI
    );
  }
  if (groupSortField) {
    params.groupSortField = groupSortField;
  }

  params.useExtendedInfo = toString(false);
  params.extendedInfoEventTagName = toString(
    `${ActivityType.customerVisit},${ActivityType.customerVisitedByNonRep},${ActivityType.crmTask},${ActivityType.crmOpportunity}`
  );
  return params;
};

const useFindActivitiesV2 = ({
  miLoc,
  id,
  natlAccNo,
  filter,
  fetchOtherUsers = false,
  visitContact,
  endDate,
  startDate,
  sortField = 'showAfter',
  sortDir = 'DESCENDING',
  groupSortField,
  enabled = true,
  limit = pageSize(),
  enabledDataCodesQuery,
  includeOthers = false,
}: UseFindActivitiesProps): UseFindActivitiesResponse => {
  const { axios } = useAxios();
  const { findActivitiesAPIv2 } = useAPIUrl();
  const { getURLParams } = useMiLocOrTeamId({});
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const params = getQueryParams({
    miLoc,
    id,
    natlAccNo,
    filter,
    fetchOtherUsers,
    visitContact,
    endDate,
    startDate,
    sortField,
    sortDir,
    groupSortField,
    limit,
    includeOthers,
    userId,
  });

  const response = useQuerySetup<ActionCardActivity, FindActivitiesAPIResponse>(
    {
      queryKey: findActivitiesV2QueryKey,
      queryParams: params,
      onAPIRequest: async ({ pageParam = 1, signal }) => {
        const { data } = await axios.get<FindActivitiesAPIResponse>(
          findActivitiesAPIv2(getURLParams({ ...params, page: pageParam })),
          { signal }
        );
        return { rows: data.rows, totalRows: data.totalRows };
      },
      dataPath: 'rows',
      totalDataPath: 'totalRows',
      limit,
      enabled,
      isInfiniteQuery: true,
    }
  );

  const { data: codeList, isLoading: isCodesLoading } = useFindDataCodes({
    codeType: 'CUCONTYP',
    enabled: enabledDataCodesQuery,
  });

  const codeListOptions = map(codeList, ({ id: codeId, codeName }) => ({
    id: codeId,
    text: codeName,
  }));

  const {
    items,
    totalItems,
    isLoading,
    isEmptyResponse,
    status,
    ...queryFlags
  } = response;

  return {
    items: items as ActionCardActivity[],
    totalItems,
    isLoading: or(isLoading, isCodesLoading),
    hasError: status === 'error',
    ...queryFlags,
    isEmptyResponse,
    codeList: codeListOptions,
  };
};

export default useFindActivitiesV2;
