import React from 'react';
import { toString } from 'lodash';
import useFindActiveEmployees from 'ActivitiesApp/api/useFindActiveEmployees';
import ContactModal from 'common/components/ContactModal/ContactModal';
import EmployeeTitle from 'SearchApp/components/EmployeeTitle/EmployeeTitle';
import { goToEmployeeSnapshot } from 'SearchApp/navigation/navigationHelpers';
import { type LoggedInStatusEnum } from 'SearchApp/utils/helpers';
import useGetEmployee from 'api/employee/useGetEmployee';
import Loader from 'components/Loader/Loader';
import classes from './Employee.module.scss';

const EmployeeModal = (
  props: Pick<
    React.ComponentProps<typeof ContactModal>,
    'isOpen' | 'setIsOpen' | 'testid' | 'id' | 'initialBreakpoint'
  >
): JSX.Element => {
  const { id: employeeId, isOpen } = { ...props };
  const { activeEmployeeList } = useFindActiveEmployees({});
  const employeeFromCache = activeEmployeeList?.[toString(employeeId)];
  const { data: employee, isLoading } = useGetEmployee({
    id: toString(employeeId),
    enabledProp: isOpen,
  });

  let customNode = (
    <EmployeeTitle
      miLoc={employee?.miLoc}
      title={employee?.title}
      lastLoggedIn={employeeFromCache?.lastLoggedIn}
      status={employeeFromCache?.status as LoggedInStatusEnum}
      testid="employee-title"
    />
  );

  if (isLoading) {
    customNode = (
      <Loader isOpen={isLoading} className={classes.loader} testid="loader" />
    );
  }

  return (
    <ContactModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      customNode={customNode}
      phoneNumber={employee?.workPhone}
      mobilePhone={employee?.mobilePhone}
      email={employee?.email}
      titleHref={goToEmployeeSnapshot({ employeeId })}
      name={employee?.name_empDisplayName}
      countryCode={employee?.phoneCountryCode}
    />
  );
};

export default EmployeeModal;
