import { useMemo } from 'react';
import { toString } from 'lodash';
import { endOfDay, isAfter, isToday, startOfDay, subWeeks } from 'date-fns';
import type { NotificationItem } from 'HomeApp/models/Notifications';

interface NoticesGroupCountsRepsonse {
  groupCounts: number[];
}

const useNotificationGroupCounts = (
  notices: NotificationItem[]
): NoticesGroupCountsRepsonse => {
  const groupCounts = useMemo((): number[] => {
    let future = 0;
    let today = 0;
    let lastTwoWeeks = 0;
    let older = 0;

    notices.forEach((notice) => {
      const creationTime = new Date(toString(notice?.creationTimestamp));

      // future group
      if (isAfter(creationTime, endOfDay(new Date()))) {
        future += 1;
        return;
      }

      // today group
      if (isToday(creationTime)) {
        today += 1;
        return;
      }

      // last two weeks group
      if (isAfter(creationTime, startOfDay(subWeeks(new Date(), 2)))) {
        lastTwoWeeks += 1;
        return;
      }

      // older group (fallback)
      older += 1;
    });
    return [future, today, lastTwoWeeks, older];
  }, [notices]);
  return {
    groupCounts,
  };
};

export default useNotificationGroupCounts;
