import { isNil } from 'lodash';

export enum FeatureFlagType {
  customizeActivityImportance,
  gaLogging,
  workOrders,
  removeDateFilter,
  includeDailyTotals,
  newReports,
  changePin,
  quoting,
  demoMode,
  shareProTip,
  disablePick12,
  disableAccessControl,
}

const featureFlags = (featureFlag: FeatureFlagType) => {
  switch (featureFlag) {
    case FeatureFlagType.customizeActivityImportance:
      return process.env.REACT_APP_CUSTOMIZE_ACTIVITY_IMPORTANCE === 'true';
    case FeatureFlagType.gaLogging:
      return process.env.REACT_APP_GA_LOGGING === 'true';
    case FeatureFlagType.workOrders:
      return process.env.REACT_APP_WORK_ORDERS === 'true';
    case FeatureFlagType.removeDateFilter:
      return process.env.REACT_APP_SHOW_ALL_ACTIVITIES === 'true';
    case FeatureFlagType.includeDailyTotals:
      return process.env.REACT_APP_FEATURE_INCLUDE_DAILY_TOTALS === 'true';
    case FeatureFlagType.changePin:
      return process.env.REACT_APP_FEATURE_CHANGE_PIN === 'true';
    case FeatureFlagType.demoMode:
      return process.env.REACT_APP_FEATURE_DEMO_MODE === 'true';
    case FeatureFlagType.shareProTip:
      return process.env.REACT_APP_SHARE_PRO_TIP === 'true';
    case FeatureFlagType.disablePick12:
      return process.env.REACT_APP_DISABLE_FEATURE_PICK12 === 'true';
    case FeatureFlagType.disableAccessControl:
      return process.env.REACT_APP_DISABLE_ACCESS_CONTROL === 'true';
    default:
      return false;
  }
};

const useFeatureFlags = (featureFlag?: FeatureFlagType): boolean => {
  // if there is an featureFlag flag, it verifies an active value for it
  // if not it defaults to false (the user does not has access by default)
  if (!isNil(featureFlag)) {
    return featureFlags(featureFlag);
  }
  return false;
};

export default useFeatureFlags;
