interface NotificatinData {
  baseConfigVersion: string;
  comment: string;
  commentId: string;
  commentType: string;
  custMiLoc: string;
  custNo: string;
  eventConfigVersion: string;
  eventTagName: string;
  historyId: string;
  notificationType: string;
  notificationTypeComment: string;
  notificationTypeId: string;
  userId: string;
}

interface Notification {
  body: string;
  className: string;
  customerName: string;
  customerNo: string;
  data: NotificatinData;
  eventConfigVersion: number;
  eventDisplayName: string;
  eventHistoryId: number;
  eventTagName: string;
  fcmToken: string;
  mentions: string[];
  miLoc: string;
  notificationComment: string;
  notificationCommentCreatedName: string;
  notificationCommentId: string;
  notificationCommentType: string;
  notificationCount: number;
  subTitle: string;
  title: string;
  type: string;
  typeComment: string;
  typeId: string;
  userId: string;
}

export interface NotificationItem {
  active: boolean;
  creationTimestamp: string;
  creationUserId: string;
  id: number;
  lastUpdateTimestamp: string;
  lastUpdateUserid: string;
  notification: Notification;
  read: boolean;
  userId: string;
}

export enum NotificationTypeEnum {
  CommentNotification = 'CommentNotification',
  CommentTask = 'CommentTask',
}
