import { isEmpty, join, map } from 'lodash';
import { and, choose, or } from 'common/utils/logicHelpers';
import { differenceInCalendarDays, isToday } from 'date-fns';
import i18next from 'i18n/i18n';
import type { Recipient } from 'models/Contact';
import { parseDate } from 'utils/date';

interface EmployeeStatusModel {
  status: LoggedInStatusEnum;
  sinceLastLoggedIn: number;
}

export enum LoggedInStatusEnum {
  active = 'active',
  away = 'away',
  inactive = 'inactive',
}

export const LOGGEDIN_TIME_INACTIVE = Infinity;

export const employeeStatus = (lastLoggedIn: string): EmployeeStatusModel => {
  const formattedLastLoggedIn = parseDate(lastLoggedIn);
  const today = new Date();
  let status = LoggedInStatusEnum.active;
  const differenceInDays = differenceInCalendarDays(
    today,
    formattedLastLoggedIn
  );
  let sinceLastLoggedIn = 0;
  const isTodayLoggedIn = isToday(formattedLastLoggedIn);

  if (and(differenceInDays <= 30, !isTodayLoggedIn)) {
    status = LoggedInStatusEnum.away;
    sinceLastLoggedIn = differenceInDays;
  }
  if (or(differenceInDays > 30, isEmpty(lastLoggedIn))) {
    status = LoggedInStatusEnum.inactive;
    sinceLastLoggedIn = LOGGEDIN_TIME_INACTIVE;
  }

  return { status, sinceLastLoggedIn };
};

export const formatRecepientName = (recepients: Recipient[]) =>
  join(
    map(recepients, (item) => item.name),
    '; '
  );

export const calculateEmployeeStatusAging = (sinceLastLoggedIn?: number) => {
  return choose(
    sinceLastLoggedIn === LOGGEDIN_TIME_INACTIVE,
    i18next.t('ActivitiesApp-Comments:inactive'),
    i18next.t('ActivitiesApp-Comments:days', { count: sinceLastLoggedIn })
  );
};
