import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { size, toNumber } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindFollowers from 'ActivitiesApp/api/useFindFollowers';
import ActiveEmployeeItem from 'ActivitiesApp/components/ActiveEmployeeItem/ActiveEmployeeItem';
import List from 'common/components/List/List';
import { useNodeRef } from 'common/components/utils/renderHelpers';
import { choose } from 'common/utils/logicHelpers';
import { useDebounce } from 'use-debounce';
import type { Employee } from 'models/Employee';
import Modal from 'components/Modal/Modal';
import Searchbar from 'components/Searchbar/Searchbar';
import classes from './FollowerList.module.scss';

interface FollowerListProp {
  historyId: string;
}

const FollowerList = ({
  historyId,
  isOpen,
  setIsOpen,
  testid = 'follower-list-modal',
}: FollowerListProp &
  React.ComponentProps<typeof Modal> &
  IonicReactProps): JSX.Element => {
  const { t } = useTranslation('ActivitiesApp-Comments');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const { node, nodeRef } = useNodeRef();

  const {
    items,
    totalItems,
    isLoading,
    noMoreData,
    fetchNextPage,
    error,
    isEmptyResponse,
  } = useFindFollowers({
    query: searchQueryValue,
    historyId,
    enabled: isOpen,
  });

  const renderItem = (index: number, item: Employee) => {
    return <ActiveEmployeeItem employee={item} key={`employee-${index}`} />;
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      testid={testid}
      title={t('followers', {
        totalItems,
        count: choose(toNumber(totalItems) > 0, 1, 0),
      })}
      forceFullHeight
      withTitleLine={false}
      header={
        <Searchbar
          className={classes.searchbar}
          value={searchQuery}
          setValue={setSearchQuery}
          testid="search-input"
          placeholder={t('followerListSearchPlaceholder')}
        />
      }
      className={classes.modal}
    >
      <div className={classes.followersList} ref={nodeRef}>
        <List
          data={items}
          testid="follower-list"
          scrollParent={node}
          isLoading={isLoading}
          isEndOfList={noMoreData}
          endReached={fetchNextPage}
          itemContent={renderItem}
          isError={!!error}
          isEmptyList={{
            isEmptyList: isEmptyResponse,
            title: t('emptyResultsTitle', {
              count: size(searchQueryValue),
              query: searchQueryValue,
            }),
          }}
        />
      </div>
    </Modal>
  );
};

export default FollowerList;
