import React, { useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import type { AxiosError } from 'axios';
import { filter, isEmpty, last, map } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import Footer from 'common/components/Footer/Footer';
import { FormikInput } from 'common/components/Forms/Input/Input';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { and, ifRender, or } from 'common/utils/logicHelpers';
import { Formik, useFormikContext } from 'formik';
import { formatRecepientName } from 'SearchApp/utils/helpers';
import useGoBack from 'hooks/useGoBack';
import type { MiProFile } from 'models/Attachment';
import type { Customer } from 'models/Customer';
import { getErrorMessage } from 'utils/helpers';
import Attachments from 'components/Attachments/Attachments';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import Spinner from 'components/Spinner/Spinner';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import EmployeeList from './EmployeeList';
import classes from './SendEmail.module.scss';
import { SendEmailSchema, type SendEmailForm } from './SendEmailSchema';

interface SendEmailFormikProps {
  onSubmit: (values: SendEmailForm) => void | Promise<void>;
  values: SendEmailForm;
}

export const SendEmailFormik = ({
  children,
  onSubmit,
  values,
}: React.PropsWithChildren<SendEmailFormikProps>): JSX.Element => {
  return (
    <Formik<SendEmailForm>
      initialValues={{
        subject: values.subject,
        message: values.message,
        recepients: values.recepients,
        files: map(
          values.files,
          (file) =>
            ({
              ...file,
              fileName: file.USER_FILE,
              name: file.USER_FILE,
              type: last(file.USER_FILE?.split('.')),
            } as MiProFile)
        ),
      }}
      enableReinitialize
      validationSchema={SendEmailSchema}
      onSubmit={onSubmit}
    >
      {() => children}
    </Formik>
  );
};

interface SendEmailProps {
  isLoading?: boolean;
  customerLoc?: string;
  allowRecipients?: boolean;
}

export const SendEmail = ({
  isLoading,
  customerLoc,
  allowRecipients = true,
}: SendEmailProps): JSX.Element => {
  const { t } = useTranslation();
  const { setFieldTouched, setFieldValue, values, validateField } =
    useFormikContext<SendEmailForm>();

  const [recepientListIsOpen, setRecepientListIsOpen] = useState(false);
  return (
    <div data-testid="send-email-form" className={classes.formWrapper}>
      <Spinner
        className={classes.formLoader}
        text={t('common:forms:updatingLoader')}
        showSpinner={isLoading}
        testid="send-email-loader"
      />
      <FormikInput
        label={t('email:recipients')}
        testid="recepients-input"
        name="recepients"
        required
        textarea
        rows={2}
        onClick={() => setRecepientListIsOpen(true)}
        readonly
        disabled={or(isLoading, !allowRecipients)}
        customLabel={
          <>
            <Button
              variant="link"
              icon={['far', 'plus-circle']}
              testid="add-recipient-button"
              className={classes.addRecipientButton}
              onClick={() => setRecepientListIsOpen(true)}
            />
            <EmployeeList
              customerLoc={customerLoc}
              isOpen={recepientListIsOpen}
              setIsOpen={setRecepientListIsOpen}
              testid="employee-list-modal"
              onDone={(v) => {
                setFieldValue('recepients', formatRecepientName(v), true);
                setFieldValue('recepientsList', v);
                setFieldTouched('recepients');
                setTimeout(() => validateField('recepients'));
              }}
              values={values.recepientsList}
              onClose={() => setFieldTouched('recepients')}
            />
          </>
        }
        rightButton={{
          icon: ['fas', 'caret-down'],
          onClick: () => setRecepientListIsOpen(true),
        }}
      />
      <FormikInput
        className={classes.input}
        label={t('email:subject')}
        testid="subject-input"
        name="subject"
        required
        rows={2}
        disabled={isLoading}
        textarea
      />
      <FormikInput
        className={classes.input}
        label={t('email:message')}
        testid="message-input"
        name="message"
        rows={5}
        disabled={isLoading}
        textarea
        required
      />
      <div className={classes.attachments}>
        <Text text={t('email:attachments')} variant="mipro-h3-headline" />
        <Attachments
          name="customer"
          className={classes.attachments}
          files={values.files}
          onAdd={(files) => {
            setFieldValue('files', [...(values.files || []), ...files]);
          }}
          onRemove={(file) => {
            const filesFilteredToUpload = filter(
              [...(values.files as MiProFile[])],
              (i) => i.fileName !== file.fileName
            );
            setFieldValue('files', filesFilteredToUpload);
          }}
          disabled={isLoading}
          prefetchAll
          testid="send-email-attachments"
        />
      </div>
    </div>
  );
};

interface SendEmailPageProps {
  /* eslint-disable react/require-default-props */
  customerData?: Customer;
  isLoading?: boolean;
  error?: AxiosError | null;
  isSubmitting?: boolean;
  title: string;
  allowRecipients?: boolean;
  /* eslint-enable react/require-default-props */
}

export interface SendEmailPageRef {
  resetForm?: () => void;
}

export const SendEmailPage = React.forwardRef<
  SendEmailPageRef,
  SendEmailPageProps
>((props, outerRef): JSX.Element => {
  const { customerData, error, isSubmitting, title, allowRecipients } = props;
  const { t } = useTranslation();
  const { goBack } = useGoBack();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const resetView = params.get('reset');
  const [isOpenDiscardModal, setIsOpenDiscardModal] = useState(false);
  const { dirty, handleSubmit, resetForm } = useFormikContext<SendEmailForm>();

  const customBackButtonClick = () => {
    if (dirty) {
      setIsOpenDiscardModal(true);
    } else {
      goBack();
    }
  };

  const isLoading = props.isLoading || !!resetView;
  const formLoading = isLoading || isSubmitting;

  useImperativeHandle(
    outerRef,
    () => ({
      resetForm: () => resetForm({}),
    }),
    [resetForm]
  );

  return (
    <IonPage>
      <Header
        testid="send-email-page-header"
        customTitle={<CustomerName customerData={customerData} />}
        backButton={{ onClick: () => customBackButtonClick() }}
      />
      <IonContent className={classes.content}>
        <Header
          pageTitle={{
            text: title,
            testid: 'send-email-title',
          }}
          testid="send-email-header"
          customTitle={<CustomerName customerData={customerData} />}
          collapse="condense"
          className={classes.header}
        />
        {ifRender(
          isLoading,
          <Loader
            className={classes.loader}
            text={t('common:loading')}
            isOpen={isLoading}
            testid="loader"
          />
        )}
        {ifRender(
          !isEmpty(error),
          <WarningMessage
            className={classes.message}
            title={t('common:errorTitle')}
            body={getErrorMessage(error)}
            testid="customer-error"
          />
        )}
        {ifRender(
          and(!isLoading, isEmpty(error)),
          <SendEmail
            allowRecipients={allowRecipients}
            isLoading={formLoading}
            customerLoc={customerData?.miLoc}
          />
        )}
        <DiscardModal
          isOpen={isOpenDiscardModal}
          setIsOpen={setIsOpenDiscardModal}
          title={t('notes:cancelNotesTitle')}
          discardMsg={t('common:discardMsg')}
          discardButtonTitle={t('notes:cancelNotesYes')}
          goBackButtonTitle={t('notes:cancelNotesNo')}
          onDiscardClick={() => goBack()}
          initialBreakpoint={0.4}
          withRightCloseButton
          testid="send-email-discard-modal"
        />
      </IonContent>
      <Footer
        buttons={[
          {
            testid: 'send-email-btn',
            variant: 'mipro-action',
            text: t('common:sendEmail'),
            onClick: () => handleSubmit(),
          },
        ]}
      />
    </IonPage>
  );
});
