import { map, uniq, difference, compact, toString } from 'lodash';
import { or } from 'common/utils/logicHelpers';
import type { Delta } from 'quill';

type BlotName = 'styled-mention';

type DeltaOpInsertBlot = Record<
  BlotName,
  { value: string; id: string } | DOMStringMap
>;

export const blotName = 'styled-mention';

/**
 * @description Convert a user mention information into a serialized representation.
 * @param id Employee's ID
 * @param value Employee's Name
 * @returns String
 */
export const serializeMention = (id: string, value: string): string => {
  return `[${id}:MOTION_EMP](${value})`;
};

/**
 * @description Parse a comment string into HTML elements to be inserted into the Quill editor.
 * @param comment String that contins the comment text.
 * @returns string
 */
export const getCommentAsHtml = (comment: string): string => {
  const escapedInput = comment.replaceAll(
    /\[([A-Za-z0-9]+):MOTION_EMP\]\(([^)]+)\)/g,
    (match, id: string, name: string) => {
      return `<span class="mention" data-id="${id}" data-value="${name}" data-denotation-char="@"><span style="color: var(--mipro-color-dark-blue);">@${name}</span></span>`;
    }
  );

  const lines = escapedInput.split(/\n/);

  const htmlOutput = lines.map((line) => {
    if (line.trim() === '') {
      return '<p><br></p>';
    }
    return `<p>${line}</p>`;
  });

  return htmlOutput.join('');
};

/**
 * @description Obtain a list of unique user ids from a comment string.
 * @param comment The comment that needs to be parsed.
 * @returns String[]
 */
export const parseMentionList = (comment: string): string[] => {
  const regex = /\[([A-Za-z0-9]+):MOTION_EMP\]/g;
  const matches = [];
  let match;

  do {
    match = regex.exec(comment);
    if (match) {
      matches.push(match[1]);
    }
  } while (match);

  return uniq(matches);
};

/**
 * @description Get a list of updated employee ids only. Only return the ids that are not in the existing list.
 * @param existingIds List of unique user ids
 * @param newIds List of unique user ids
 * @returns String[]
 */
export const getUpdatedMentionList = (
  existingIds: string[],
  newIds: string[]
): string[] => {
  return uniq(difference(compact(newIds), compact(existingIds)));
};

/**
 * @description Concatenate all the content from a Delta object.
 * @param contents Delta object from Quill.
 * @param blot Custom blot name.
 * @returns String
 */
export const getContentAsString = (
  contents?: Pick<Delta, 'ops'>,
  blot = 'styled-mention'
): string => {
  if (or(!contents, !contents?.ops)) return '';

  return map(contents?.ops, ({ insert }) => {
    if (insert && typeof insert === 'object') {
      const { id, value } = or(
        (insert as DeltaOpInsertBlot)[blot as BlotName],
        (insert as DeltaOpInsertBlot)['styled-mention']
      );
      return serializeMention(toString(id), toString(value));
    }
    return insert;
  })
    .join('')
    .replace(/\n$/, '');
};

export const NO_ITEM_FOUND = 'NO_ITEM_FOUND';
