import type { BaseQueryProps } from 'common/api/utils/apiHelpers';
import { getURLParams } from 'common/api/utils/apiHelpers';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import type { Employee } from 'models/Employee';
import { pageSize } from 'utils/constants';
import { findEmployeesAPI } from '.';

export const findEmployeesQueryKey = 'find-employees';

const useFindEmployees = ({
  query,
  sortField = 'searchScore',
  sortDir = 'ASC',
  limit = pageSize(),
}: BaseQueryProps) => {
  const { axios } = useAxios();
  const params = { query, sortField, sortDir, limit };

  const response = useQuerySetup<Employee, Employee[]>({
    queryKey: findEmployeesQueryKey,
    queryParams: params,
    isInfiniteQuery: true,
    enabled: !!query,
    onAPIRequest: async ({ pageParam = 1, signal }) => {
      const { data } = await axios.get<Employee[]>(
        findEmployeesAPI(getURLParams({ ...params, page: pageParam })),
        { signal }
      );
      return data;
    },
  });

  return { employees: response.items, ...response };
};

export default useFindEmployees;
