import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  findIndex,
  isEmpty,
  isNil,
  map,
  slice,
  size,
  toString,
  find,
  filter,
  includes,
  upperCase,
} from 'lodash';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import List from 'common/components/List/List';
import { useNodeRef } from 'common/components/utils/renderHelpers';
import { and, choose, or } from 'common/utils/logicHelpers';
import { useDebounce } from 'use-debounce';
import useFindEmployees from 'api/employee/useFindEmployees';
import useGetLocationItem from 'api/location/useGetLocationItem';
import type { Recipient } from 'models/Contact';
import type { Employee } from 'models/Employee';
import { getErrorMessage } from 'utils/helpers';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Modal from 'components/Modal/Modal';
import Searchbar from 'components/Searchbar/Searchbar';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './EmployeeList.module.scss';
import EmployeeListItem from './EmployeeListItem/EmployeeListItem';

interface RecepientPillProps {
  removeRecipient: (name: string, miLoc: string) => void;
  recipients: Recipient[];
  onDone?: (v: Recipient[]) => void;
  isSingleSelection?: boolean;
}

export const RecepientPill = ({
  recipients,
  removeRecipient,
  onDone,
  isSingleSelection,
}: RecepientPillProps): JSX.Element => {
  return (
    <div className={classes.selectedEmployees}>
      <div className={classes.recipientsWrapperInverted}>
        {map(
          recipients,
          ({ sequenceNo, miLoc: itemMiLoc = '', name, email }, index) => (
            <IonRow
              key={`pill-${index}-${itemMiLoc}`}
              className={classes.recipientButtonInverted}
            >
              <Text
                className={classes.employeeName}
                text={toString(or(name, email))}
              />
              <Button
                icon={['fas', 'times']}
                iconClassName={classes.recipientButtonInvertedIcon}
                onClick={() => {
                  removeRecipient(toString(name), itemMiLoc);
                  if (isSingleSelection) {
                    onDone?.([]);
                  }
                }}
                testid={`remove-recipient-${toString(sequenceNo)}-button`}
              />
            </IonRow>
          )
        )}
        <div style={{ minWidth: `1px` }} />
      </div>
    </div>
  );
};

interface EmployeeListProps {
  showSelectedEmployees?: boolean;
  values?: Recipient[];
  customerLoc?: string;
  onDone?: (v: Recipient[]) => void;
  isSingleSelection?: boolean;
  title?: string;
}

const EmployeeList = ({
  showSelectedEmployees = true,
  customerLoc,
  values,
  onDone,
  isOpen,
  setIsOpen,
  onClose,
  testid,
  isSingleSelection,
  title,
}: EmployeeListProps &
  React.ComponentProps<typeof Modal> &
  IonicReactProps): JSX.Element => {
  const [expandResults, setExpandResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [initialFocus, setInitialFocus] = useState(0);

  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const { t } = useTranslation();

  const { node: employeeListRef, nodeRef } = useNodeRef();
  const { node: modalNode, nodeRef: modalRef } = useNodeRef();
  const { data: location } = useGetLocationItem({
    miLoc: toString(customerLoc),
  });

  const {
    items: employees,
    error: employeesError,
    hasError: employeesHasError,
    showLoader: employeesShowLoader,
    isEmptyResponse: employeeEmptyResponse,
  } = useFindEmployees({
    query: customerLoc || '',
    enabled: and(isOpen, !isSingleSelection),
    limit: 100,
  });

  const {
    items,
    error,
    fetchNextPage,
    hasError,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
  } = useFindEmployees({
    query: searchQueryValue,
    enabled: isOpen && !isEmpty(searchQueryValue),
  });

  const addRecipient = (employee: Employee) => {
    const { id, miLoc, name, email } = employee;
    setRecipients((prev) => [...prev, { id, miLoc, name, email }]);
  };

  const removeRecipient = (name: string, itemMiLoc?: string) => {
    setRecipients((prev) => {
      const seqIndex = findIndex(prev, {
        miLoc: itemMiLoc,
        name,
      });
      return [...prev.slice(0, seqIndex), ...prev.slice(seqIndex + 1)];
    });
  };

  const employeesSliced = expandResults
    ? (map(employees) as unknown as Employee[])
    : (map(slice(employees, 0, 3)) as unknown as Employee[]);

  const filteredEmployees = filter(
    employees as unknown as Employee[],
    ({ name }) => includes(upperCase(name), upperCase(searchQueryValue))
  );

  useEffect(() => {
    if (isOpen) {
      setRecipients(!isNil(values) ? values : []);
      setSearchQuery('');
    }
  }, [values, isOpen]);

  useEffect(() => {
    if (isOpen && searchQueryValue.length === 0 && items.length === 0) {
      setTimeout(() => {
        setInitialFocus(Date.now());
      }, 150);
    }
  }, [isOpen, items.length, searchQueryValue.length]);

  return (
    <Modal
      withTitleLine={false}
      isOpen={isOpen}
      onClose={() => {
        onClose?.();
        setExpandResults(false);
      }}
      forceFullHeight
      setIsOpen={setIsOpen}
      title={or(title, t('email:recipients'))}
      modalClassName={classes.modalWrapper}
      headerClassName={classes.modalHeader}
      className={classes.modal}
      header={
        <div className={classes.searchWrapper}>
          <Searchbar
            className={classes.searchbar}
            value={searchQuery}
            setValue={setSearchQuery}
            testid="search-input"
            placeholder={t('email:searchEmployees')}
            triggerInputSelect={initialFocus}
          />
          {showSelectedEmployees && size(recipients) > 0 && (
            <RecepientPill
              onDone={onDone}
              isSingleSelection={isSingleSelection}
              removeRecipient={removeRecipient}
              recipients={recipients}
            />
          )}
        </div>
      }
      footer={
        !isSingleSelection ? (
          <IonRow className={classes.buttonsWrapper}>
            <Button
              variant="secondary"
              text={t('common:cancel')}
              onClick={() => {
                onClose?.();
                setIsOpen?.(false);
                setExpandResults(false);
              }}
              testid="cancel-button"
            />
            <Button
              variant="action"
              text={t('common:add')}
              onClick={() => {
                onDone?.(recipients);
                setIsOpen?.(false);
              }}
              testid="send-button"
            />
          </IonRow>
        ) : undefined
      }
      testid={testid}
    >
      <div
        className={classNames(classes.content, classes.recipientsList)}
        ref={modalRef}
      >
        {!isSingleSelection && (
          <div className={classes.employeesList} ref={nodeRef}>
            <List
              title={{
                text: t('email:contactsInBranch', {
                  branch: customerLoc,
                  shortName: location?.locationShortName,
                }),
                className: classes.title,
              }}
              data={choose(
                searchQueryValue,
                filteredEmployees,
                employeesSliced
              )}
              itemContent={(_, item) => (
                <EmployeeListItem
                  item={item}
                  selected={
                    find(recipients, {
                      id: item.id,
                      miLoc: item.miLoc,
                    }) as unknown as boolean
                  }
                  onAdd={() => addRecipient(item)}
                  onRemove={() =>
                    item.name && removeRecipient(item.name, item.miLoc)
                  }
                />
              )}
              scrollParent={modalNode}
              isLoading={employeesShowLoader}
              isEmptyList={{
                isEmptyList:
                  employeeEmptyResponse || isEmpty(filteredEmployees),
                title: t('snapshot:noEmployee'),
                className: classNames({
                  [classes.hide]:
                    employeeEmptyResponse || isEmpty(filteredEmployees),
                }),
              }}
              isError={{
                isError: employeesHasError,
                title: t('snapshot:employeeError'),
                body: getErrorMessage(employeesError),
              }}
              testid="employee-list-search"
              data-testid="employee-list-search-results"
            />
            {!employeesHasError &&
              size(employees) > 3 &&
              isEmpty(searchQueryValue) &&
              !employeesShowLoader && (
                <ActionRow
                  className={classes.moreBtn}
                  testid="view-more"
                  onClick={() => {
                    setExpandResults(!expandResults);
                    employeeListRef?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <IonRow>
                    <Text
                      text={
                        expandResults
                          ? t('snapshot:viewLess')
                          : t('snapshot:viewMore')
                      }
                    />
                  </IonRow>
                </ActionRow>
              )}
          </div>
        )}
        <div className={classes.employeesList}>
          {and(!searchQueryValue, !isSingleSelection) && (
            <div className={classes.section}>
              <Text
                text={t('email:allContacts')}
                className={classes.title}
                variant="title-screen-section"
              />
            </div>
          )}
          {isEmpty(searchQueryValue) ? (
            <WarningMessage
              className={classes.warningMessage}
              icon={['far', 'info-circle']}
              title={t('snapshot:startSearching')}
            />
          ) : (
            <>
              <List
                title={choose(!isSingleSelection, {
                  text: t('email:allContacts'),
                  className: classes.title,
                  testid: 'employee-list-title',
                })}
                testid="employee-list-search-results"
                data={items as unknown as Employee[]}
                itemContent={(_, item) => (
                  <EmployeeListItem
                    item={item}
                    selected={
                      find(recipients, {
                        id: item.id,
                        miLoc: item.miLoc,
                      }) as unknown as boolean
                    }
                    onAdd={() => {
                      if (isSingleSelection) {
                        setIsOpen?.(false);
                        onDone?.([item]);
                      } else {
                        addRecipient(item);
                      }
                    }}
                    onRemove={() =>
                      item.name && removeRecipient(item.name, item.miLoc)
                    }
                    isSingleSelection={isSingleSelection}
                  />
                )}
                isLoading={and(showLoader, !isEmpty(searchQueryValue))}
                scrollParent={modalNode}
                isEmptyList={{
                  isEmptyList: isEmptyResponse,
                  title: t('snapshot:noEmployee'),
                }}
                isError={{
                  isError: hasError,
                  title: t('snapshot:employeeError'),
                  body: getErrorMessage(error),
                }}
              />
              <InfiniteScroll
                disabled={!enableInfiniteScroll}
                onLoadMore={fetchNextPage}
                testid="infinite-scroll"
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeList;
