import { toNumber } from 'lodash';
import type { IndustrySegment } from 'HomeApp/ProTips/api/useFindIndustrySegments';
import type { Manufacturer } from 'HomeApp/ProTips/api/useFindManufacturers';
import i18next from 'i18next';
import * as yup from 'yup';
import type { MiProFile } from 'models/Attachment';
import type { Employee } from 'models/Employee';

export interface ShareProTipForm {
  employee?: Employee[];
  industrySegments?: IndustrySegment[];
  revenue?: string;
  manufacturer?: Manufacturer[];
  headline?: string;
  description?: string;
  files?: MiProFile[];
}

export const ShareProTipSchema = yup.object<ShareProTipForm>().shape({
  employee: yup
    .array()
    .required(i18next.t('common:forms:requiredError'))
    .min(1, i18next.t('common:forms:requiredError')),
  industrySegments: yup
    .array()
    .required(i18next.t('common:forms:requiredError'))
    .min(1, i18next.t('common:forms:requiredError')),
  revenue: yup
    .string()
    .required(i18next.t('common:forms:requiredError'))
    .test(
      'moreThan0',
      i18next.t('common:forms:moreThanZeroError'),
      (v) => toNumber(v) > 0
    ),
  manufacturer: yup.array(),
  headline: yup.string().required(i18next.t('common:forms:requiredError')),
  description: yup.string().required(i18next.t('common:forms:requiredError')),
  files: yup.array(),
});
