import React from 'react';
import { Trans } from 'react-i18next';
import { isEmpty, toLower, toString, trim } from 'lodash';
import CommentInput from 'ActivitiesApp/components/Comments/CommentInput';
import { goToActivity } from 'ActivitiesApp/navigation/navigationHelpers';
import { choose, or } from 'common/utils/logicHelpers';
import classes from 'HomeApp/components/NotificationActionCard/NotificationActionCard.module.scss';
import {
  NotificationTypeEnum,
  type NotificationItem,
} from 'HomeApp/models/Notifications';
import { isCommentTypeMentioned } from 'HomeApp/utils/helpers';
import i18next from 'i18next';
import { CUSTOMERLESS } from 'utils/constants';

// eslint-disable-next-line import/prefer-default-export
export const getCommentCardConfigs = (
  notificationItem: NotificationItem,
  notificationType: NotificationTypeEnum
) => {
  const {
    customerName,
    notificationCommentCreatedName,
    notificationCommentType,
    data,
    title: createdName,
    eventDisplayName,
    eventHistoryId,
    notificationCommentId,
  } = notificationItem.notification;

  const isMentioned = isCommentTypeMentioned(notificationCommentType);

  const isCommentTask = notificationType === NotificationTypeEnum.CommentTask;

  let title = (
    <Trans
      i18nKey={i18next.t(
        'HomeApp-Notifications:commentNotificationCommentedTitle',
        {
          customerName: toLower(customerName),
          createdName: choose(
            !isCommentTask,
            toLower(notificationCommentCreatedName),
            createdName
          ),
          eventDisplayName: choose(
            !isCommentTask,
            eventDisplayName,
            data?.eventTagName
          ),
        }
      )}
      data-testid="commented-title"
    />
  );

  if (isMentioned) {
    title = (
      <Trans
        i18nKey={i18next.t(
          'HomeApp-Notifications:commentNotificationMentionedTitle',
          {
            customerName: toLower(customerName),
            createdName: toLower(notificationCommentCreatedName),
            eventDisplayName,
          }
        )}
        data-testid="mentioned-title"
      />
    );
  }

  return {
    title: (
      <div className={classes.cardTitle} data-testid="title">
        {title}
      </div>
    ),
    description: (
      <div className={classes.comment}>
        {!isEmpty(data?.comment) && (
          <CommentInput
            className={classes.description}
            readOnly
            testid="description"
            value={`“${data?.comment}”`}
          />
        )}
      </div>
    ),
    icon: choose(isMentioned, 'at', 'comment') as string,
    iconColor: 'color-commentNotification',
    href: goToActivity({
      miLoc: or(trim(data?.custMiLoc), 'EXEC'),
      customerId: or(trim(data?.custNo), CUSTOMERLESS),
      userId: data?.userId,
      historyId: toString(eventHistoryId),
      commentId: notificationCommentId,
      activityType: data?.eventTagName,
    }),
  };
};
