import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import {
  escape,
  includes,
  isEmpty,
  map,
  orderBy,
  toLower,
  toNumber,
  toString,
  trim,
} from 'lodash';
import useFindActiveEmployees from 'ActivitiesApp/api/useFindActiveEmployees';
import { type BaseComponentProps } from 'common/components/utils/renderHelpers';
import { and, choose, or } from 'common/utils/logicHelpers';
import i18next from 'i18n/i18n';
import Quill from 'quill';
import { Mention, MentionBlot } from 'quill-mention';
import { LOGGEDIN_TIME_INACTIVE } from 'SearchApp/utils/helpers';
import type { Employee } from 'models/Employee';
import classes from './CommentInput.module.scss';
import { getCommentAsHtml, getContentAsString, NO_ITEM_FOUND } from './utils';

interface InsertMentionData {
  id: string;
  value: string;
}

/* eslint-disable react/require-default-props */
interface CommentInputProps extends BaseComponentProps {
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  readOnly?: boolean;
  onMentionClick?: (value: InsertMentionData) => void;
  onChange?: (value: string) => void;
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
}

export interface CommentInputRef {
  openMenu: VoidFunction;
  closeMentionList: (clearInput?: boolean) => void;
}

export const renderItem = (data: Employee) => {
  const item = document.createElement('div');
  if (data.id === NO_ITEM_FOUND) {
    item.classList.add(classes.tagEmployee);
    item.classList.add(classes.emptyTagEmployee);

    const firstRow = document.createElement('div');
    firstRow.classList.add(classes.emptyFirstRow);
    const nameText = document.createElement('span');
    nameText.classList.add(classes.name);
    nameText.innerText = i18next.t('ActivitiesApp-Comments:noTagUserTitle');
    firstRow.append(nameText);
    item.append(firstRow);

    const secondRow = document.createElement('div');
    secondRow.classList.add(classes.emptySecondRow);

    const titleText = document.createElement('span');
    titleText.classList.add(classes.title);
    titleText.innerText = i18next.t('ActivitiesApp-Comments:noTagUserMsg');
    secondRow.append(titleText);

    item.append(secondRow);
  } else {
    const count = data.sinceLastLoggedIn;
    const lastLoggedInTime = choose(
      count === LOGGEDIN_TIME_INACTIVE,
      i18next.t('ActivitiesApp-Comments:inactive'),
      i18next.t('ActivitiesApp-Comments:days', { count })
    );

    item.classList.add(classes.tagEmployee);
    const firstRow = document.createElement('div');
    firstRow.classList.add(classes.firstRow);
    const nameRow = document.createElement('div');
    nameRow.classList.add(classes.nameRow);
    const statusIcon = document.createElement('span');
    statusIcon.classList.add(classes.status, classes[toString(data.status)]);
    nameRow.append(statusIcon);
    const nameText = document.createElement('span');
    nameText.classList.add(classes.name);
    nameText.innerText = data.name_empDisplayName;
    nameRow.append(nameText);
    firstRow.append(nameRow);
    const lastLoginText = document.createElement('div');
    lastLoginText.classList.add(classes.lastLogin);
    lastLoginText.innerText = toString(lastLoggedInTime);
    firstRow.append(lastLoginText);
    item.append(firstRow);

    const secondRow = document.createElement('div');
    secondRow.classList.add(classes.secondRow);

    if (toString(data.title)) {
      const titleText = document.createElement('span');
      titleText.classList.add(classes.title);
      titleText.innerText = toString(data.title);
      secondRow.append(titleText);
      const separator = document.createElement('span');
      separator.classList.add(classes.separator);
      separator.innerText = '•';
      secondRow.append(separator);
    }
    if (toString(data.miLoc)) {
      const miLocText = document.createElement('span');
      miLocText.classList.add(classes.miLoc);
      miLocText.innerText = toString(data.miLoc);
      secondRow.append(miLocText);
    }
    item.append(secondRow);
  }

  return item;
};

export const filteredEmployeeList = ({
  searchTerm,
  initialList,
  activeEmployeeList,
}: {
  searchTerm: string;
  initialList: Employee[];
  activeEmployeeList?: Dictionary<Employee>;
}) => {
  if (searchTerm.length === 0) {
    return initialList;
  }
  const list: Employee[] = map(or(activeEmployeeList, {}), (item) => item);

  const queryArray = searchTerm?.trim()?.split(' ');
  const lowerCaseQueryArray = map(queryArray, (item) => trim(toLower(item)));
  let filteredList = map(list, (item) => {
    const { searchTokens } = item;
    const lowerCaseSearchTokens = map(searchTokens, (it) => toLower(it));
    let customScore = 0;
    const lastSearchIndexes: number[] = [];
    lowerCaseQueryArray.every((queryItem) => {
      let queryCustomScore = 0;
      let isQueryFound = false;
      lowerCaseSearchTokens.every((searchToken, searchIndex) => {
        if (!includes(lastSearchIndexes, searchIndex)) {
          if (searchToken === queryItem) {
            queryCustomScore = 1;
            isQueryFound = true;
            lastSearchIndexes.push(searchIndex);
            return false;
          }
          if (includes(searchToken, queryItem)) {
            isQueryFound = true;
            queryCustomScore = 0.5;
            lastSearchIndexes.push(searchIndex);
            return false;
          }
        }
        return true;
      });
      if (!isQueryFound) {
        customScore = 0;
        return false;
      }
      customScore += queryCustomScore;
      return true;
    });

    return {
      ...item,
      customScore,
    };
  });
  filteredList = filteredList.filter((item) => item.customScore);
  filteredList = orderBy(
    filteredList,
    ['customScore', 'searchScore'],
    ['desc', 'desc']
  ).slice(0, 25);
  return filteredList;
};

Quill.register({
  'blots/mention': MentionBlot,
  'modules/mention': Mention,
});

const CommentInput = React.forwardRef(
  (props: CommentInputProps, outerRef: React.ForwardedRef<CommentInputRef>) => {
    const {
      className,
      placeholder,
      disabled,
      value,
      readOnly,
      testid,
      onMentionClick,
      onChange,
      onFocus,
      onBlur,
    } = props;
    const quillRef = useRef<HTMLDivElement>(null);

    const { activeEmployeeList } = useFindActiveEmployees({});
    const initialList = useMemo(() => {
      return orderBy(
        map(activeEmployeeList, (i) => i),
        ['searchScore'],
        ['desc']
      ).slice(0, 25);
    }, [activeEmployeeList]);

    const [editor, setEditor] = useState<Quill>();

    useEffect(() => {
      if (quillRef.current) {
        const quill = new Quill(quillRef.current, {
          formats: ['mention'],
          placeholder,
          modules: {
            mention: {
              readOnly,
              allowedChars: /^[a-zA-Z0-9\s]*$/,
              mentionDenotationChars: ['@'],
              isolateCharacter: true,
              fixMentionsToQuill: false,
              showDenotationChar: true,
              spaceAfterInsert: true,
              positioningStrategy: 'fixed',
              defaultMenuOrientation: 'top',
              mentionContainerClass: classes.qlMentionListContainer,
              mentionListClass: classes.qlMentionList,
              renderItem,
              source: (
                searchTerm: string,
                renderList: (employee: Employee[], searchTerm: string) => void
              ) => {
                if (searchTerm.length === 0) {
                  renderList(initialList, searchTerm);
                }
                const filteredList = filteredEmployeeList({
                  searchTerm,
                  initialList,
                  activeEmployeeList,
                });

                if (!isEmpty(filteredList)) {
                  renderList(filteredList, searchTerm);
                } else {
                  renderList([{ id: NO_ITEM_FOUND } as Employee], searchTerm);
                }
              },
            },
          },
        });
        setEditor(quill);
      }
    }, [placeholder, activeEmployeeList, initialList, readOnly]);

    useEffect(() => {
      editor?.root.addEventListener('focus', () => {
        if (and(!disabled, !readOnly)) {
          onFocus?.();
        }
      });
      editor?.root.addEventListener('click', () => {
        if (!disabled) {
          (editor?.getModule('mention') as Mention)?.hideMentionList();
          quillRef?.current?.focus();
        }
      });
      editor?.root.addEventListener('blur', () => {
        if (and(!disabled, !readOnly)) {
          onBlur?.();
        }
        (editor?.getModule('mention') as Mention)?.hideMentionList();
      });
      editor?.enable(!readOnly && !disabled);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, readOnly, editor]);

    useImperativeHandle(
      outerRef,
      () => ({
        openMenu: () => {
          editor?.focus();
          const selection = editor?.getSelection(true);
          editor?.insertText(toNumber(selection?.index), '@');
          setTimeout(() => {
            editor?.blur();
            editor?.focus();
          }, 300);
        },
        closeMentionList: (clearInput?: boolean) => {
          (editor?.getModule('mention') as Mention)?.hideMentionList();
          if (clearInput) {
            editor?.setText('');
            editor?.blur();
          }
        },
      }),
      [editor]
    );

    useEffect(() => {
      const onChangeListener = editor?.on('text-change', () => {
        onChange?.(getContentAsString(editor.getContents(), 'mention'));
      });

      return () => {
        onChangeListener?.removeListener?.('text-change');
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor]);

    useEffect(() => {
      if (getContentAsString(editor?.getContents(), 'mention') !== value) {
        const delta = editor?.clipboard.convert({
          html: getCommentAsHtml(escape(toString(value))),
        });
        if (delta) {
          editor?.setContents(delta, 'silent');
        }
      }
    }, [editor, value]);

    useEffect(() => {
      const handleOnMention = (
        e: Event &
          Partial<{
            value: InsertMentionData;
          }>
      ) => {
        onMentionClick?.({
          id: toString(e.value?.id),
          value: toString(e.value?.value),
        });
      };

      window.addEventListener('mention-clicked', handleOnMention);

      return () => {
        window.removeEventListener('mention-clicked', handleOnMention);
      };
    }, [onMentionClick]);

    return (
      <div
        className={classNames(classes.wrapper, className, {
          [classes.disabled]: disabled,
          [classes.readOnly]: readOnly,
        })}
      >
        <div className={classes.input} ref={quillRef} data-testid={testid} />
      </div>
    );
  }
);

export default CommentInput;
