import { toString } from 'lodash';
import { choose } from 'common/utils/logicHelpers';
import NotificationList from 'HomeApp/pages/Notifications/NotificationList';
import ShareProTip from 'HomeApp/ProTips/pages/ShareProTip/ShareProTip';

// #region ui-routes
export const shareProURL = (reset = false) =>
  `/share-pro-tip${toString(choose(reset, '?reset=true'))}`;
export const notificationListURL = () => '/notifications';
// #endregion ui-routes

export const ShareProTipRouter = [
  {
    path: shareProURL(),
    routeName: 'Share Pro Tip',
    component: ShareProTip,
  },
];

export const NotificationListRouter = [
  {
    path: notificationListURL(),
    routeName: 'Notices List',
    component: NotificationList,
  },
];
