import { toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { choose } from 'common/utils/logicHelpers';
import i18next from 'i18next';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { onErrorUpdate, onMutateUpdate, onSuccessMutation } from 'api/helpers';
import { ToastType } from 'models/Toast';
import { findActivityNotesV2QueryKey } from './notes/useFindActivityNotesV2';
import { findFollowersQuerykey } from './useFindFollowers';

export const useUpdateSubscriptionQuerykey = 'update-subscription';

interface OnUpdateSubscriptionBody {
  subscriptionCount?: number;
  subscribed?: boolean;
  historyId?: number;
}

interface UseUpdateSubscriptionResponse {
  status: MutationStatus;
  onUpdateSubscription: (body?: OnUpdateSubscriptionBody) => void;
}

interface UseUpdateSubscriptionProps {
  historyId: number;
  userId?: string;
}

const useUpdateSubscription = ({
  historyId,
}: UseUpdateSubscriptionProps): UseUpdateSubscriptionResponse => {
  const { axios } = useAxios();
  const { updateSubscriptionAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const apiUrl = updateSubscriptionAPI();
  const { addToast } = useToasts();

  const doUpdateSubscription = async (body?: OnUpdateSubscriptionBody) => {
    let data: OnUpdateSubscriptionBody;
    if (body?.subscribed) {
      ({ data } = await axios.delete<OnUpdateSubscriptionBody>(
        `${apiUrl}/${toString(historyId)}`
      ));
    } else {
      ({ data } = await axios.post<OnUpdateSubscriptionBody>(
        `${apiUrl}/${toString(historyId)}`
      ));
    }

    return data;
  };

  const { status, mutate } = useMutation(doUpdateSubscription, {
    onMutate: async (vars) => {
      const subscribed = !vars?.subscribed;
      const subscriptionCount = choose(
        !subscribed,
        toNumber(vars?.subscriptionCount) - 1,
        toNumber(vars?.subscriptionCount) + 1
      );
      return onMutateUpdate<OnUpdateSubscriptionBody>({
        queryClient,
        queryKey: findActivityNotesV2QueryKey,
        queryKeyParams: { historyId },
        updatedItems: [{ subscriptionCount, subscribed }],
        dataPath: 'pages.0',
        isSingleQuery: true,
      });
    },
    onSuccess: () => {
      void onSuccessMutation(queryClient, findActivityNotesV2QueryKey, {
        historyId,
      });
      void onSuccessMutation(queryClient, findFollowersQuerykey, {
        historyId: toString(historyId),
      });
    },
    onError: (error, vars, context) => {
      addToast({
        type: ToastType.error,
        text: i18next.t('ActivitiesApp-Comments:subscriptionAPIError'),
        testid: 'subscription-toast',
      });
      onErrorUpdate<OnUpdateSubscriptionBody>({
        queryClient,
        context,
        dataPath: 'pages.0',
        isSingleQuery: true,
      });
    },
  });

  return {
    status,
    onUpdateSubscription: (body?: OnUpdateSubscriptionBody) => mutate(body),
  };
};

export default useUpdateSubscription;
