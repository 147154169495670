import { isNil, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { choose, or } from 'common/utils/logicHelpers';
import { differenceInMinutes } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  onSuccessMutation,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
} from 'api/helpers';
import { unreadQueryKey } from 'api/user/useGetUnreadNotificationsCount';
import usePushNotifications from 'hooks/usePushNotifications';
import type {
  ActionCardActivity,
  UpdateActivityBody,
} from 'models/ActivityModels';
import { ToastType } from 'models/Toast';
import { parseDate } from 'utils/date';
import { findActivitiesQueryKey } from './useFindActivities';
import { findActivitiesV2QueryKey } from './useFindActivitiesV2';
import { getActivityQueryKey } from './useGetActivity';

interface UseUpdateActivityProps {
  userId: string;
  historyId: number;
}

interface UseUpdateActivityResponse {
  status: MutationStatus;
  onUpdateActivity: (body: UpdateActivityBody) => void;
}

export const getUpdatedFlags = ({
  showAfter,
  done,
  followUp,
}: UpdateActivityBody): Record<string, string> => {
  const requestBody: Record<string, string> = {};
  if (showAfter) {
    requestBody.showAfter = showAfter;
  }
  if (!isNil(done)) {
    requestBody.done = toString(choose(done, 'Y', 'N'));
  }
  if (!isNil(followUp)) {
    requestBody.followUp = toString(choose(followUp, 'Y', 'N'));
  }
  return requestBody;
};

const useUpdateActivity = ({
  historyId,
}: UseUpdateActivityProps): UseUpdateActivityResponse => {
  const { axios } = useAxios();
  const { updateActivityAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { removeDeliveredNotifications } = usePushNotifications();

  const doUpdateActivity = (body: UpdateActivityBody) => {
    if (body.historyId === 0) {
      return new Promise<void>((resolve) => {
        resolve();
      });
    }
    return doPromiseAPI(async (cancelToken) => {
      await axios.put(
        updateActivityAPI(toString(or(historyId, body.historyId))),
        getUpdatedFlags(body),
        { cancelToken }
      );
    });
  };

  const { status, mutate } = useMutation(doUpdateActivity, {
    onMutate: async (vars) => {
      const updatedActivity = getUpdatedFlags(vars);
      if (updatedActivity.showAfter) {
        updatedActivity.isSnoozed = toString(
          choose(
            differenceInMinutes(
              parseDate(updatedActivity.showAfter),
              new Date()
            ) > 0,
            'Y',
            'N'
          )
        );
        updatedActivity.showAfterISO = updatedActivity.showAfter;
      }
      const activitiesListContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        updatedItems: [{ ...updatedActivity, historyId }],
        findPredicates: [{ historyId }],
        // TUDU fix optimisticUI updates with new react version
        // sortPredicate: true,
        // filterPredicate: activitiesFilterPredicate,
        isInfiniteQuery: true,
      });
      const activitiesV2ListContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesV2QueryKey,
        dataPath: 'rows',
        updatedItems: [{ ...updatedActivity, historyId }],
        findPredicates: [{ historyId }],
        isInfiniteQuery: true,
      });
      const activityContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: getActivityQueryKey,
        updatedItems: [{ ...updatedActivity, historyId }],
        findPredicates: [{ historyId }],
        isSingleQuery: true,
      });
      return {
        activityContext,
        activitiesListContext,
        activitiesV2ListContext,
      };
    },

    onSuccess: () => {
      setTimeout(() => {
        // invalidate unread when an activity is updated
        void onSuccessMutation(queryClient, unreadQueryKey);
        void onSuccessMutation(queryClient, findActivitiesQueryKey);
        void onSuccessMutation(queryClient, findActivitiesV2QueryKey);
        void onSuccessMutation(queryClient, getActivityQueryKey, {
          historyId,
        });

        // remove delivered notifications
        void removeDeliveredNotifications(toString(historyId), 'historyId');
      }, 1000);
    },
    onError: (error, vars, context) => {
      addToast({
        type: ToastType.error,
        text: 'Update activity operation failed. Please try again later.',
        testid: 'update-activity-error-toast',
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: context?.activitiesListContext,
        // sortPredicate: true,
        isInfiniteQuery: true,
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: context?.activitiesV2ListContext,
        isInfiniteQuery: true,
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: context?.activityContext,
        isSingleQuery: true,
      });
    },
  });

  return {
    status,
    onUpdateActivity: (body: UpdateActivityBody) => mutate(body),
  };
};

export default useUpdateActivity;
