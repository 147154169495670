import React, { useEffect, useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { find, includes, last, map, toNumber, toString } from 'lodash';
import useUpdateTask from 'ActivitiesApp/api/useUpdateTask';
import CrmOpportunityForm from 'ActivitiesApp/components/CrmOpportunityForm/CrmOpportunityForm';
import type { TimelineItem } from 'ActivitiesApp/components/Timeline/Timeline';
import Timeline from 'ActivitiesApp/components/Timeline/Timeline';
import type { ActivityPageBaseProps } from 'ActivitiesApp/models/ActivityDetail';
import {
  CrmActivityTypeEnum,
  type CrmForm,
  type GeneralTaskInfo,
  type SecondaryStatus,
} from 'ActivitiesApp/models/CrmActivity';
import { SalesOpportunityEnum } from 'ActivitiesApp/models/SalesOpportunity';
import type { ActivityComponentRef } from 'ActivitiesApp/pages/ActivityDetail';
import {
  BLANK_DUE_DATE,
  getCrmRatingLabels,
  getCrmTimelineStatusLabel,
  getCrmTimeLineStatusTitle,
  getCrmTimelineStatusVariant,
  shouldShowDueDate,
} from 'ActivitiesApp/utils/helpers';
import type { ContactModalProps } from 'common/components/ContactModal/ContactModal';
import ContactModal from 'common/components/ContactModal/ContactModal';
import {
  and,
  choose,
  ifFunction,
  ifRender,
  or,
} from 'common/utils/logicHelpers';
import { formatNumber } from 'common/utils/numberHelper';
import { differenceInDays } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import { useToasts, type ToastProps } from 'providers/ToastProvider';
import useFindDataCodes from 'api/data/useFindDataCodes';
import { RatingActivityEnum } from 'models/ActivityModels';
import { ToastType } from 'models/Toast';
import { DateFormatEnum, formatDate, formatLastUpdatedDate } from 'utils/date';
import RatingButtons from 'components/Activities/RatingButtons/RatingButtons';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Text from 'components/Text/Text';
import classes from './CrmTaskForm.module.scss';
import crmTaskFormSchema from './CrmTaskFormSchema';

const CrmTaskForm = React.forwardRef<
  ActivityComponentRef,
  ActivityPageBaseProps
>((props, outerRef): JSX.Element => {
  const {
    activity,
    loggedInUserId,
    triggerRerender,
    footerRef,
    didChange,
    isLoading,
    updateActivityData,
    shouldRenderSaveButton,
    hasPendingComment,
    setIsDiscardCommentOpen,
    onContentChange,
    onCompleteActivityFlag,
    onUpdateActivity,
    onDone,
  } = props;

  const {
    assignedMiAccount,
    currency,
    estimatedValue,
    statusHistory,
    requesterEmail,
    requesterPhone,
    dueDate,
    estimatedCloseDate,
    subject,
    description,
    lastUpdateTimestamp,
    actualValue,
  } = activity?.extendedInfo as unknown as GeneralTaskInfo;
  const lastStatus = last(statusHistory);
  const isSameUser = assignedMiAccount === loggedInUserId;
  const defaultTextVariant = 'mipro-body-copy';

  const { addToast } = useToasts();
  const { onUpdateTask, onOptimisticUpdateTask, rollbackUpdateTask } =
    useUpdateTask({
      taskId: activity?.extendedInfo?.taskId as number,
      userId: loggedInUserId,
    });
  const { t } = useTranslation(namespaces.activities);
  const isCrmOpportunity =
    activity?.eventTagName === CrmActivityTypeEnum.crmOpportunity;
  const isSalesOpportunity =
    activity?.eventTagName === SalesOpportunityEnum.salesOpportunity;

  const shouldRenderCrmOpportunityForm =
    isCrmOpportunity &&
    includes(['OPEN', 'ACCEPT'], lastStatus?.secondaryStatus);

  const { data: wonReasons, isLoading: isLoadingWonCodes } = useFindDataCodes({
    codeType: 'TASKWON',
    enabled: shouldRenderCrmOpportunityForm,
  });
  const { data: lostReasons, isLoading: isLoadingLostCodes } = useFindDataCodes(
    {
      codeType: 'TASKLOST',
      enabled: shouldRenderCrmOpportunityForm,
    }
  );

  const isLoadingCodes = and(
    shouldRenderCrmOpportunityForm,
    or(isLoadingWonCodes, isLoadingLostCodes)
  );
  const formik = useFormik<CrmForm>({
    validationSchema: crmTaskFormSchema,
    validateOnMount: true,
    validateOnChange: true,
    initialValues: {
      action: undefined,
      actualValue: choose(actualValue > 0, toString(actualValue)),
      reason: undefined,
    },
    onSubmit: async (formValues) => {
      const status = or(formValues.action, '');
      let toastConfig: Partial<ToastProps>;

      switch (status) {
        case 'ACCEPT':
          toastConfig = {
            header: choose(
              isCrmOpportunity,
              t('opportunityAccepted'),
              t('taskAccepted')
            ),
            type: ToastType.success,
            icon: ['fas', 'check-circle'],
          };
          break;
        case 'COMPL':
          toastConfig = {
            header: t('taskCompleted'),
            type: ToastType.success,
            icon: ['fas', 'check-circle'],
          };
          break;
        case 'CANC':
          toastConfig = {
            header: t('taskCancelled'),
          };
          break;
        case 'REJECT':
          toastConfig = {
            header: choose(
              isCrmOpportunity,
              t('opportunityRejected'),
              t('taskRejected')
            ),
          };
          break;
        case 'WON':
          toastConfig = {
            header: t('opportunityWon'),
            text: `${t('reason')} ${toString(formValues.reasonDesc)}\n${t(
              'value'
            )} ${formatNumber({
              number: or(Number(formValues.actualValue), 0),
              scale: 2,
              currencyType: or(currency, 'USD'),
            })}`,
            type: ToastType.success,
            icon: ['fas', 'check-circle'],
          };
          break;
        case 'LOST':
          toastConfig = {
            header: t('opportunityLost'),
            text: `${t('reason')} ${toString(formValues.reasonDesc)}`,
          };
          break;
        default:
          toastConfig = {
            header: t('savedToast'),
          };
      }

      const updateTaskContext = await onOptimisticUpdateTask({
        historyId: toNumber(activity?.historyId),
        secondaryStatus: formValues.action,
        actualValue: or(formValues.actualValue, '0'),
        reasonCd: formValues.reason,
      });

      addToast({
        testid: 'crm-task-form-submit',
        onClose: () => {
          if (updateActivityData) {
            onUpdateActivity?.(updateActivityData);
          }
          ifFunction(!!formValues.action, () => {
            onUpdateTask({
              historyId: toNumber(activity?.historyId),
              secondaryStatus: formValues.action,
              actualValue: or(formValues.actualValue, '0'),
              reasonCd: formValues.reason,
            });
          });
        },
        type: ToastType.info,
        icon: ['fas', 'check'],
        variant: 'mipro-toast',
        className: classes.toast,
        button: {
          text: t('common:undo'),
          handler: () => {
            rollbackUpdateTask(updateTaskContext);
          },
        },
        ...toastConfig,
      });
      onDone?.();
    },
  });
  const { handleSubmit, isSubmitting, setValues, values } = formik;
  const isWon = choose(values.action === 'WON', true, false);
  const formattedReasons = map(
    choose(isWon, wonReasons, lostReasons),
    ({ id, codeName }) => ({
      label: codeName,
      value: id,
    })
  );

  useImperativeHandle(
    outerRef,
    () => ({
      onSubmit: handleSubmit,
    }),
    [handleSubmit]
  );

  const [ratingValue, setRatingValue] = useState<
    RatingActivityEnum | undefined
  >();
  const [contactModalData, setContactModalData] = useState<
    Partial<ContactModalProps>
  >({
    name: '',
    phoneNumber: '',
    email: '',
  });
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const formattedEstimatedValue = formatNumber({
    number: or(estimatedValue, 0),
    scale: 2,
    currencyType: or(currency, 'USD'),
  });

  const showRatingButtons =
    !isSalesOpportunity &&
    includes(['OPEN', 'ACCEPT'], lastStatus?.secondaryStatus);
  const isTaskCancelled = lastStatus?.secondaryStatus === 'CANC';
  const hasContactInfo = or(requesterEmail, requesterPhone);

  const isDueDateBlank = dueDate === BLANK_DUE_DATE;
  const formattedDueDate = choose(
    !isDueDateBlank,
    formatLastUpdatedDate(dueDate, DateFormatEnum.dayShortMonth)
  );
  const daysOverdued = differenceInDays(new Date(), new Date(dueDate));

  const showDueDate =
    !isSalesOpportunity &&
    !isTaskCancelled &&
    shouldShowDueDate(lastStatus?.secondaryStatus);

  const isTaskOverdue = daysOverdued > 0;
  const daysOverduedText = choose(
    daysOverdued > 1,
    t('daysLateAlt_other', { count: daysOverdued }),
    t('daysLateAlt_one', { count: 1 })
  ) as string;

  const isEstCloseDateBlank = estimatedCloseDate === BLANK_DUE_DATE;
  const formattedEstCloseDate = choose(
    !isEstCloseDateBlank,
    formatLastUpdatedDate(estimatedCloseDate, DateFormatEnum.dayShortMonth)
  );

  const timelineData: TimelineItem[] = map(statusHistory, (status, i) => {
    const isMe = status.creationUserId === loggedInUserId;
    const lines: TimelineItem['lines'] = [
      {
        label: or(
          getCrmTimelineStatusLabel(status.secondaryStatus, t),
          `${t('common:by')}:`
        ),
        customValue: (
          <Button
            variant={choose(isMe || !hasContactInfo, 'clear', 'link')}
            text={choose(isMe, t('common:you'), status.creationUser)}
            testid={`timeline-item-list-${i}`}
            onClick={() => {
              setContactModalData({
                name: status.creationUser,
                phoneNumber: status.creationPhone,
                email: status.creationEmail,
              });
              setIsContactModalOpen(true);
            }}
          />
        ),
      },
      {
        label: `${t('common:date')}:`,
        customValue: (
          <Text
            testid="created-timestamp"
            variant={defaultTextVariant}
            text={formatLastUpdatedDate(
              status.creationTimestamp,
              DateFormatEnum.dayShortMonth
            )}
            className={classNames({
              [classes.overdueDate]: and(
                isTaskOverdue,
                !isDueDateBlank,
                showDueDate
              ),
            })}
          />
        ),
      },
    ];

    if (includes(['WON', 'LOST'], status?.secondaryStatus)) {
      lines.push(
        ...[
          {
            label: t('reason'),
            value: or(find(wonReasons, { id: status.reason })?.codeName, ''),
          },
          {
            label: `${t('actualValue')}:`,
            value: formatNumber({
              number: or(actualValue, 0),
              scale: 2,
              currencyType: or(currency, 'USD'),
            }),
          },
        ]
      );
    }

    return {
      variant: or(
        getCrmTimelineStatusVariant(status.secondaryStatus),
        'pending'
      ),
      title: or(
        getCrmTimeLineStatusTitle(status.secondaryStatus, t),
        status.secondaryStatusDescription
      ),
      lines,
    };
  });

  if (lastStatus?.secondaryStatus === 'OPEN') {
    timelineData.push({
      variant: 'pending',
      title: t('activities:awaitingAcceptance'),
    });
  }
  if (lastStatus?.secondaryStatus === 'ACCEPT') {
    timelineData.push({
      variant: 'pending',
      title: t('activities:awaitingOutcome'),
    });
  }

  const onRatingChange = async (r: RatingActivityEnum) => {
    const currentStatus = lastStatus?.secondaryStatus;
    const isPositive = r === RatingActivityEnum.positive;
    let actionValue: SecondaryStatus | undefined;

    if (currentStatus === 'OPEN') {
      actionValue = choose(isPositive, 'ACCEPT', 'REJECT');
    }

    if (currentStatus === 'ACCEPT') {
      actionValue = choose(isPositive, 'COMPL', 'CANC');

      if (isCrmOpportunity) {
        actionValue = choose(isPositive, 'WON', 'LOST');
      }
    }

    setRatingValue(
      choose(
        isPositive,
        RatingActivityEnum.positive,
        RatingActivityEnum.negative
      )
    );

    onContentChange?.();
    onCompleteActivityFlag?.(
      includes(['COMPL', 'CANC', 'REJECT', 'WON', 'LOST'], actionValue)
    );

    if (values.action !== actionValue) {
      await setValues({
        ...values,
        reason: '',
        reasonDesc: '',
        action: actionValue,
      });
    }
  };

  const ratingLabels = getCrmRatingLabels(
    t,
    or(lastStatus?.secondaryStatus, ''),
    isCrmOpportunity
  );

  const [, setRerender] = useState(triggerRerender);

  useEffect(() => {
    setRerender(triggerRerender);
  }, [triggerRerender]);

  return (
    <FormikProvider value={formik}>
      <Loader testid="loader" text={t('common:loading')} isOpen={!!isLoading} />
      <div className={classes.detailCard}>
        <Text
          variant="mipro-body-copy-bold"
          text={subject}
          className={classNames(classes.textBlock, classes.lineBreak)}
        />
        <Text
          variant={defaultTextVariant}
          text={description}
          className={classNames(classes.textBlock, classes.lineBreak)}
        />
        {ifRender(
          or(isCrmOpportunity, isSalesOpportunity),
          <Text
            variant={defaultTextVariant}
            testid="crm-activity-estimated-value"
            text={t('estimatedValue', {
              amount: formattedEstimatedValue,
            })}
            className={classes.textBlock}
            textQuery={formattedEstimatedValue}
          />
        )}
        {ifRender(
          showDueDate,
          <div data-testid="crm-activity-due-date">
            <Text variant={defaultTextVariant} text={`${t('due')} `} />
            <Text
              variant={defaultTextVariant}
              text={choose(isDueDateBlank, t('notSet'), formattedDueDate)}
              textQuery={choose(!isDueDateBlank, formattedDueDate, '')}
              className={classNames({
                [classes.overdueDate]: and(isTaskOverdue, !isDueDateBlank),
                [classes.textItalic]: isDueDateBlank,
              })}
            />
            {ifRender(
              and(isTaskOverdue, !isDueDateBlank),
              <Text
                variant={defaultTextVariant}
                text={` ${daysOverduedText}`}
                className={classes.overdueDate}
                testid="crm-task-overdue-date"
              />
            )}
          </div>
        )}
        {ifRender(
          isSalesOpportunity,
          <div
            data-testid="sales-opportunity-due-date"
            className={classNames({
              [classes.estCloseDate]: isSalesOpportunity,
            })}
          >
            <Text variant={defaultTextVariant} text={`${t('estCloseDate')} `} />
            <Text
              variant={defaultTextVariant}
              text={choose(
                isEstCloseDateBlank,
                t('notSet'),
                formattedEstCloseDate
              )}
              textQuery={choose(
                !isEstCloseDateBlank,
                formattedEstCloseDate,
                ''
              )}
            />
          </div>
        )}
        {!isSalesOpportunity && (
          <Timeline
            className={classes.timeline}
            data={timelineData}
            testid="crm-timeline"
          />
        )}
        <Loader
          testid="loader-crm-opportunity-form"
          text={t('common:loading')}
          isOpen={and(!isLoading, isLoadingCodes)}
        />
        {ifRender(
          and(shouldRenderCrmOpportunityForm, !isLoadingCodes),
          <CrmOpportunityForm
            reasons={formattedReasons}
            isSameUser={isSameUser}
            isSubmitting={isSubmitting}
          />
        )}
        {ifRender(
          and(showRatingButtons, isSameUser, !isLoadingCodes),
          <RatingButtons
            className={classes.rating}
            rating={ratingValue}
            positiveText={or(ratingLabels?.[0], '')}
            negativeText={or(ratingLabels?.[1], '')}
            setRating={onRatingChange}
          />
        )}
        {lastUpdateTimestamp && (
          <Text
            variant="content-smaller"
            text={`${t('common:updated')} ${formatDate(
              toString(or(lastUpdateTimestamp)),
              'MMM d, y h:mm a'
            )}`}
          />
        )}
        {!isSalesOpportunity &&
          isSameUser &&
          footerRef?.current &&
          shouldRenderSaveButton &&
          createPortal(
            <Button
              className={classes.button}
              variant="action"
              text={t('common:save')}
              onClick={() => {
                if (hasPendingComment) {
                  setIsDiscardCommentOpen?.(true);
                  return;
                }
                handleSubmit();
              }}
              disabled={or(!didChange, isSubmitting)}
              testid="save-button"
            />,
            footerRef.current
          )}
        <ContactModal
          isOpen={isContactModalOpen}
          setIsOpen={setIsContactModalOpen}
          testid="activity-contact-modal"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...contactModalData}
        />
      </div>
    </FormikProvider>
  );
});

export default CrmTaskForm;
