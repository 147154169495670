import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import HelpButton from 'common/components/HelpButton/HelpButton';
import {
  getOptionalRenderProp,
  type BaseComponentProps,
  type OptionalRenderProp,
} from 'common/components/utils/renderHelpers';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import Text from 'components/Text/Text';
import classes from './InventoryCustomTitle.module.scss';

interface InventoryCustomTitleProps extends BaseComponentProps {
  title?: OptionalRenderProp;
  subTitle?: OptionalRenderProp;
}

const InventoryCustomTitle = ({
  ...props
}: InventoryCustomTitleProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { isOnline } = useNetworkStatus();
  const { text: title, props: titleProps } = getOptionalRenderProp(props.title);
  const { text: subTitle, props: subTitleProps } = getOptionalRenderProp(
    props.subTitle
  );

  return (
    <div className={classes.customTitleWrapper}>
      {title && (
        <div className={classes.flex}>
          <Text
            variant="mipro-h2-headline"
            text={title}
            testid="title"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...titleProps}
          />

          <FontAwesomeIcon
            icon={['fas', isOnline ? 'wifi' : 'wifi-slash']}
            className={classNames({
              [classes.onlineIcon]: isOnline,
              [classes.offlineIcon]: !isOnline,
            })}
          />
          {!isOnline && (
            <HelpButton
              button={{
                icon: ['far', 'info-circle'],
                className: classes.offlineBtn,
                testid: 'offline-info-button',
              }}
              modal={{
                title: t('inventory:offlineMode'),
                testid: 'offline-info-button',
                children: (
                  <>
                    <IonRow>
                      <Text
                        text={t('inventory:offlineHelpTitle')}
                        variant="mipro-body-copy-bold"
                      />
                    </IonRow>
                    <IonRow className={classes.blockPd}>
                      <Text
                        text={t('inventory:offlineHelpMsgBlockOne')}
                        variant="mipro-body-copy"
                      />
                    </IonRow>
                    <IonRow className={classes.blockPd}>
                      <Text
                        text={t('inventory:offlineHelpMsgBlockTwo')}
                        variant="mipro-body-copy"
                      />
                    </IonRow>
                  </>
                ),
                initialBreakpoint: 0.55,
              }}
            />
          )}
        </div>
      )}

      {subTitle && (
        <Text
          variant="mipro-h6-headline"
          text={subTitle}
          testid="subtitle"
          className={classes.subtitle}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...subTitleProps}
        />
      )}
    </div>
  );
};

export default InventoryCustomTitle;
