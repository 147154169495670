import { useRef } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, isEmpty, toNumber } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useTabReset } from 'providers/TabResetProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { findActivitiesV2QueryKey } from 'api/activities/useFindActivitiesV2';
import { doPromiseAPI, onSuccessMutation, useKeyUserId } from 'api/helpers';
import usePushNotifications from 'hooks/usePushNotifications';
import type { QueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';

export const unreadQueryKey = 'unread-notifications-count';

export interface UnreadCount {
  unreadCount: number;
}
interface UseGetUnreadNotificationsCountResponse {
  data?: UnreadCount;
}

const useGetUnreadNotificationsCount =
  (): UseGetUnreadNotificationsCountResponse & QueryFlags => {
    const { axios } = useAxios();
    const { unreadNotificationsCountAPI } = useAPIUrl();
    const queryClient = useQueryClient();
    const { createQueryKey } = useKeyUserId();
    const { userInfo } = useSelector((state: RootState) => state.user);
    const userId = get(userInfo, 'userid', '');
    const { hasPushNotificationSupport, setBadgeCount } =
      usePushNotifications();
    const { triggerActivityAnimation } = useTabReset();
    const currentUnreadNotifications = useRef<number>(0);

    const doGetUnreadNotificationsCount = (): Promise<UnreadCount> => {
      return doPromiseAPI(async (cancelToken) => {
        const { data } = await axios.get<UnreadCount>(
          unreadNotificationsCountAPI(),
          { cancelToken }
        );
        return data;
      });
    };

    const { data, error, status, isFetching } = useQuery<
      UnreadCount,
      AxiosError
    >(createQueryKey(unreadQueryKey), doGetUnreadNotificationsCount, {
      enabled: !isEmpty(userId),
      notifyOnChangeProps: ['data', 'error', 'status'],
      refetchInterval: () => {
        // Change refetch interval based on whether we will receive push notifications
        if (hasPushNotificationSupport()) {
          return 60 * 60 * 1000;
        }
        return 30 * 1000;
      },
      onSuccess: (vars) => {
        if (
          vars.unreadCount > 0 &&
          vars.unreadCount > currentUnreadNotifications.current
        ) {
          triggerActivityAnimation();
          // invalidate activities when cache is updated and there are new notifications
          void onSuccessMutation(queryClient, findActivitiesQueryKey);
          void onSuccessMutation(queryClient, findActivitiesV2QueryKey);
        }
        currentUnreadNotifications.current = vars.unreadCount;
        void setBadgeCount(toNumber(vars.unreadCount));
      },
    });

    return {
      error,
      data,
      isLoading: status === 'loading' || isFetching,
    };
  };

export default useGetUnreadNotificationsCount;
