import React from 'react';
import classnames from 'classnames';
import type { IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCardHeader } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { and } from 'common/utils/logicHelpers';
import useDisablePick12 from 'ReportsApp/hooks/useDisablePick12';
import { findIcon } from 'utils/icons';
import Pick12Icon from 'components/Pick12Icon/Pick12Icon';
import Text from 'components/Text/Text';
import classes from './CardHeader.module.scss';

interface CardHeaderProps {
  prefix?: string;
  suffix?: string;
  title: string;
  withHref?: boolean;
  prefixClassName?: string;
  titleClassName?: string;
  suffixClassName?: string;
  iconClassName?: string;
  iconName?: IconName;
  customerPick12?: boolean;
  testid: string;
}

const CardHeader = ({
  prefix,
  title,
  suffix,
  withHref = true,
  className,
  prefixClassName,
  titleClassName,
  suffixClassName,
  iconClassName,
  iconName = 'chevron-right',
  customerPick12,
  testid,
}: CardHeaderProps & IonicReactProps): JSX.Element => {
  const isPick12Disabled = useDisablePick12();

  return (
    <IonCardHeader
      className={classnames(classes.cardHeader, className)}
      data-testid={`card-header-${testid}`}
    >
      {prefix && (
        <Text
          className={classnames(classes.prefix, prefixClassName)}
          text={prefix}
          variant="content-small"
          testid={`card-header-prefix-${testid}`}
        />
      )}
      {and(customerPick12, !isPick12Disabled) && (
        <Pick12Icon
          testid={`${testid}-card-header`}
          className={classes.pick12Icon}
        />
      )}
      <Text
        className={classnames(classes.title, titleClassName)}
        text={title}
        variant="title-action-card"
        testid={`card-header-title-${testid}`}
      />
      {suffix && (
        <Text
          className={classnames(classes.suffix, suffixClassName)}
          text={suffix}
          variant="content-small"
          testid={`card-header-suffix-${testid}`}
        />
      )}
      {withHref && (
        <FontAwesomeIcon
          className={classnames(classes.icon, iconClassName)}
          icon={findIcon(iconName)}
          data-testid={`card-header-arrow-${testid}`}
        />
      )}
    </IonCardHeader>
  );
};

export default CardHeader;
