import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  filter,
  findIndex,
  forEach,
  head,
  isEmpty,
  last,
  map,
  set,
  size,
  toNumber,
  toString,
} from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonContent,
  IonPage,
  IonicSlides,
  IonRow,
  IonMenuToggle,
  IonMenuButton,
} from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import CustomerName from 'common/components/Header/CustomerName';
import ProductSearchResult from 'common/components/ProductSearchResult/ProductSearchResult';
import { useIonContentRef } from 'common/components/utils/renderHelpers';
import { and, choose, or } from 'common/utils/logicHelpers';
import {
  ProductSearchBar,
  ProductSearchHeaderActions,
} from 'ProductSearchApp/pages/ProductSearch';
import {
  customerLessProductSearchByOptions,
  isCustomerLess,
  productSearchByOptions,
} from 'ProductSearchApp/util/productSearchUtil';
import { useTabReset } from 'providers/TabResetProvider';
import { getCostSavingsReportQueryKey } from 'ReportsApp/api/useGetCostSavingsReport';
import { getDigitalSalesReportQueryKey } from 'ReportsApp/api/useGetDigitalSalesReport';
import { getSalesDashboardQueryKey } from 'ReportsApp/api/useGetSalesDashboard';
import { getSalesReportQueryKey } from 'ReportsApp/api/useGetSalesReport';
import useDisablePick12 from 'ReportsApp/hooks/useDisablePick12';
import useGetCamUserDetails from 'ReportsApp/hooks/useGetCamUserDetails';
import type { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDebounce } from 'use-debounce';
import { findAnnouncementsQueryKey } from 'api/announcements/useFindAnnouncements';
import useGetCustomer from 'api/customer/useGetCustomer';
import useAccessControls, {
  AccessControlType,
  useHasAccessControls,
} from 'hooks/useAccessControls';
import useGetAppLogo from 'hooks/useGetAppLogo';
import type { RootState } from 'store/reducers';
import { selectIsTeamLocation } from 'store/user/selectors';
import { CUSTOMERLESS, HOME } from 'utils/constants';
import { findIcon } from 'utils/icons';
import Header from 'components/Header/Header';
import headerClasses from 'components/Header/Header.module.scss';
import HeaderActions from 'components/HeaderActions/HeaderActions';
import Refresher from 'components/Refresher/Refresher';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import Spinner from 'components/Spinner/Spinner';
import Text from 'components/Text/Text';
import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';
import CostSavingsHealthCard from './CostSavingsHealthCard/CostSavingsHealthCard';
import Pick12Card from './DashboardCards/Pick12Card';
import SalesPerformanceCard from './DashboardCards/SalesPerformanceCard';
import WebPerformanceCard from './DashboardCards/WebPerformanceCard';
import HomeAnnouncements from './HomeAnnouncements';
import classes from './NewHome.module.scss';

const homeNameSpace = 'Home';
const productSearchNameSpace = 'productSearch';

const NewHome = (): JSX.Element => {
  const {
    miLoc: stateMiLoc = '',
    fallbackMiLoc,
    currentCartCustomer,
  } = useSelector((state: RootState) => state.user);
  const cartMiLoc = toString(currentCartCustomer?.miLoc);
  const customerId = toString(currentCartCustomer?.shipToCustNo);
  const isCustomerLessSearch = isCustomerLess(customerId);

  const { data: customerData } = useGetCustomer({
    miLoc: cartMiLoc,
    id: customerId,
    searchType: 'customer',
    enabled: !isCustomerLessSearch,
  });
  const isTeam = useSelector(selectIsTeamLocation);

  const miLoc = choose(isTeam, fallbackMiLoc, stateMiLoc) as string;

  const { t } = useTranslation();

  const { triggerResetTabMap } = useTabReset();
  const triggerResetHomeTab = triggerResetTabMap?.[HOME];

  const { redLogo } = useGetAppLogo();
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const tabsRowRef = useRef<HTMLDivElement>(null);
  const { nodeRef, node: contentNode } = useIonContentRef();

  const segmentTabsRef = useRef<HTMLIonSegmentElement>(null);
  const [selectedTab, setSelectedTab] = useState('sales');

  const [actionsIsOpen, setActionsIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 1000);
  const [triggerInputSelect, setTriggerInputSelect] = useState(0);
  const [productSearchView, setProductSearchView] = useState(false);
  const [updatingCustomer, setUpdatingCustomer] = useState(false);

  const [searchMode, setSearchMode] = useState(head(productSearchByOptions));

  const costSavingsAC = useAccessControls(
    AccessControlType.CostSavingsAccessControls
  );

  const { hasAccessControl } = useHasAccessControls();
  const queryClient = useQueryClient();

  const { accessControl: reportAccessControl } = useGetCamUserDetails();
  const isPick12Disabled = useDisablePick12();

  const tabs = filter(
    [
      {
        key: 'sales',
        text: t(`${homeNameSpace}:sales`),
        accessControl: reportAccessControl,
        component: SalesPerformanceCard,
      },
      {
        key: 'pick12',
        text: t(`${homeNameSpace}:pick12`),
        component: Pick12Card,
        accessControl: AccessControlType.viewReports,
        hidden: isPick12Disabled,
      },
      {
        key: 'web',
        text: t(`${homeNameSpace}:webTitle`),
        component: WebPerformanceCard,
        accessControl: reportAccessControl,
      },
    ],
    ({ accessControl, hidden }) => and(hasAccessControl(accessControl), !hidden)
  );

  const onSwipe = (sw: SwiperClass) => {
    if (tabsRowRef.current) {
      const lowerRange = 0.35;
      const firstSnap = toNumber(head(sw.snapGrid));
      // calibrate to 0
      const snapGrid = map(sw.snapGrid, (snap) => Math.abs(snap - firstSnap));
      const lastSnap = toNumber(last(snapGrid));
      let translate = -1 * sw.translate - firstSnap;
      let snapIndex = 0;
      if (translate < 0) {
        translate = 0;
      }
      if (translate > lastSnap) {
        translate = lastSnap;
      }
      forEach(snapGrid, (snap, index) => {
        if (translate > snap) {
          snapIndex = index;
        }
      });
      const progress =
        100 *
        ((translate - snapGrid[snapIndex]) /
          (snapGrid[snapIndex + 1] - snapGrid[snapIndex]));
      tabsRowRef.current.style.transform = `translateX(${
        100 * snapIndex + progress
      }%)`;
      if (segmentTabsRef.current) {
        const range = 1 - lowerRange;
        const prevTab =
          segmentTabsRef.current.childNodes[snapIndex]?.firstChild?.firstChild;
        const nextTab =
          segmentTabsRef.current.childNodes[snapIndex + 1]?.firstChild
            ?.firstChild;
        set(
          prevTab as ChildNode,
          'style.opacity',
          lowerRange + ((100 - progress) * range) / 100
        );
        set(
          nextTab as ChildNode,
          'style.opacity',
          lowerRange + (progress * range) / 100
        );
      }
    }
  };

  const headerActions = ProductSearchHeaderActions({});

  useEffect(() => {
    if (triggerResetHomeTab && productSearchView) {
      setSearchQuery('');
      setProductSearchView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerResetHomeTab]);

  const handleSearchQuery = (query: string) => {
    setProductSearchView(true);
    setSearchQuery(query);
  };

  return (
    <IonPage className={classes.homePage} data-testid="home-page">
      <div className={classes.background} />
      <Header
        theme="dark"
        className={classNames(classes.homeHeader, {
          [classes.productSearchHeader]: productSearchView,
        })}
        eyebrowClassName={classNames({
          [classes.eyebrow]: !isEmpty(currentCartCustomer),
        })}
        titleButton={choose(
          and(
            !isCustomerLessSearch,
            !isEmpty(currentCartCustomer),
            productSearchView
          ),
          <CustomerName
            className={classes.customerData}
            customerData={customerData}
          />
        )}
        title={choose(
          and(
            productSearchView,
            or(isCustomerLessSearch, isEmpty(currentCartCustomer))
          ),
          t(`${productSearchNameSpace}:title`)
        )}
        withBackButton={productSearchView ? true : undefined}
        customBackButtonClick={() => {
          setSearchQuery('');
          setProductSearchView(false);
        }}
        showLocationSelector
        showLocationText={!productSearchView}
        hideHomeMenu={!!productSearchView}
        toolbar={
          <>
            {!productSearchView && (
              <div className={headerClasses.logo}>
                <img data-testid="mipro-logo" src={redLogo} alt="" />
              </div>
            )}

            {!productSearchView && (
              <IonMenuToggle
                slot="end"
                className={headerClasses.openMenuToggle}
                menu="home-menu"
                data-testid="home-header-avatar-toggle"
              >
                <IonMenuButton
                  className={headerClasses.menuButton}
                  autoHide={false}
                />
              </IonMenuToggle>
            )}

            {productSearchView && (
              <HeaderActions
                className={classes.headerActions}
                isOpen={actionsIsOpen}
                setIsOpen={setActionsIsOpen}
                testid="header-actions"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...headerActions}
              />
            )}
          </>
        }
        testid="home"
      >
        <ProductSearchBar
          disabled={updatingCustomer}
          searchMode={searchMode}
          searchQuery={searchQuery}
          triggerInputSelect={triggerInputSelect}
          setSearchQuery={handleSearchQuery}
          setTriggerInputSelect={setTriggerInputSelect}
          setSearchMode={setSearchMode}
          productSearchByOptionsProps={customerLessProductSearchByOptions}
          isCustomerLess
        />
      </Header>
      <Spinner
        className={classes.spinner}
        testid="product-search-result-spinner"
        text={t(`${homeNameSpace}:updatingCustomer`)}
        showSpinner={updatingCustomer}
      />
      <IonContent
        ref={nodeRef}
        className={classNames({
          [classes.content]: !productSearchView,
          [classes.productSearch]: productSearchView,
        })}
      >
        {productSearchView && (
          <ProductSearchResult
            miLoc={
              choose(
                or(isEmpty(currentCartCustomer), isCustomerLessSearch),
                miLoc,
                cartMiLoc
              ) as string
            }
            updatingCustomer={updatingCustomer}
            id={or(customerId, CUSTOMERLESS)}
            productSearchView={productSearchView}
            contentNode={contentNode}
            searchMode={searchMode}
            searchQuery={searchQuery}
            searchQueryValue={searchQueryValue}
            setSearchQuery={setSearchQuery}
            setSearchMode={setSearchMode}
            setUpdatingCustomer={setUpdatingCustomer}
            productSearchByOptions={customerLessProductSearchByOptions}
          />
        )}

        {!productSearchView && (
          <>
            <div>
              <IonRow className={classes.titleRow}>
                <Text
                  className={classes.title}
                  variant="title-screen-section"
                  text={t(`${homeNameSpace}:reportSummaries`)}
                />
                <Refresher
                  slot="fixed"
                  variant="new-home"
                  className={classes.refreshHomeBanner}
                  onRefresh={async () => {
                    await Promise.all([
                      queryClient.invalidateQueries([
                        findAnnouncementsQueryKey,
                      ]),
                      queryClient.invalidateQueries(['features']),
                      queryClient.invalidateQueries([
                        getCostSavingsReportQueryKey,
                      ]),
                      queryClient.invalidateQueries([
                        getSalesDashboardQueryKey,
                      ]),
                      queryClient.invalidateQueries([getSalesReportQueryKey]),
                      queryClient.invalidateQueries([
                        getDigitalSalesReportQueryKey,
                      ]),
                    ]);
                  }}
                  testid="home-refresher"
                />
              </IonRow>
              {!isEmpty(tabs) ? (
                <>
                  <SegmentTabs
                    ref={segmentTabsRef}
                    className={classes.tabs}
                    selectedClassName={classes.selectedTab}
                    value={selectedTab}
                    options={tabs}
                    setValue={(key) => {
                      setSelectedTab(key);
                      swiperRef?.slideTo(findIndex(tabs, { key }));
                    }}
                    testid="tabs"
                  />
                  <div className={classes.tabsRow}>
                    <div
                      ref={tabsRowRef}
                      className={classes.selectedRow}
                      style={{ width: `calc(100% / ${size(tabs) || 1})` }}
                    />
                  </div>
                  <Swiper
                    className={classes.slides}
                    onSwiper={setSwiperRef}
                    slidesPerView="auto"
                    centeredSlides
                    onActiveIndexChange={(sw) =>
                      setSelectedTab(tabs[sw.activeIndex].key)
                    }
                    modules={[IonicSlides]}
                    onTransitionStart={onSwipe}
                    onTransitionEnd={(sw) => {
                      onSwipe(sw);
                      if (segmentTabsRef.current) {
                        forEach(segmentTabsRef.current.childNodes, (node) => {
                          set(
                            node?.firstChild?.firstChild as ChildNode,
                            'style.opacity',
                            ''
                          );
                        });
                      }
                    }}
                    onSliderMove={onSwipe}
                  >
                    {map(tabs, ({ key, component: CustomComponent }) => (
                      <SwiperSlide key={key}>
                        <CustomComponent />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </>
              ) : (
                <div className={classes.forbiddenSummariesWarning}>
                  <IonRow className={classes.iconRow}>
                    <FontAwesomeIcon
                      className={classes.icon}
                      icon={findIcon('exclamation-triangle', 'fas')}
                    />
                  </IonRow>
                  <IonRow
                    className={classNames(
                      classes.warningRow,
                      classes.warningHeaderRow
                    )}
                  >
                    <Text
                      className={classNames(
                        classes.warningHeaderText,
                        classes.warningText
                      )}
                      variant="title-info-card"
                      text={t(`${homeNameSpace}:forbiddenSummariesHeader`)}
                    />
                  </IonRow>
                  <IonRow
                    className={classNames(
                      classes.warningRow,
                      classes.warningMsgRow
                    )}
                  >
                    <Text
                      className={classNames(
                        classes.warningMsgText,
                        classes.warningText
                      )}
                      variant="content-default"
                      text={t(`${homeNameSpace}:forbiddenSummariesDesc`)}
                    />
                  </IonRow>
                </div>
              )}
            </div>
            <div className={classes.bottomSectionWrapper}>
              {costSavingsAC && <CostSavingsHealthCard />}
              <HomeAnnouncements />
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default NewHome;
