import type { Dictionary } from 'lodash';
import {
  omit,
  filter,
  isEmpty,
  isNil,
  remove,
  size,
  toString,
  toLower,
  escapeRegExp,
} from 'lodash';
import { Preferences } from '@capacitor/preferences';
import type { BleService } from '@capacitor-community/bluetooth-le';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import type {
  Middleware,
  MiddlewareAPI,
  PayloadAction,
  Draft,
} from '@reduxjs/toolkit';
import { isAccountRep } from 'common/utils/userInfo';
import type { AppThunk } from 'store';
import type { SearchCustomerItem, SearchItem } from 'models/Search';
import type { UserInfo, WhoAmIResponse } from 'models/Signin';
import type { Territory, TerritoryLocation } from 'models/Territory';
import UserInfoStorage from 'storage/UserInfoStorage';
import type { RootState } from 'store/reducers';
import {
  getFallbackDeepestLocation,
  getFirstAccessibleLocation,
  getLocationTree,
} from 'utils/territories';

const STORAGE_KEY = 'UserState';

// this number may be increased to force a reset of a user's initial location
const DEFAULT_LOC_VERSION = 2;
export interface DeepLink {
  url: string;
  data?: {
    [key: string]: string;
  };
}

export enum AppModeEnum {
  mipro = 'mipro',
  miproservices = 'miproservices',
}

export interface CurrentCartCustomer {
  customerName?: string;
  miLoc?: string;
  shipToCustNo?: string;
  billToCustNo?: string;
  orderCtlNo?: string;
}

interface BaseCustomerSearch {
  miLoc?: string;
  customerNo?: string;
  searchQuery?: string;
}

export interface MiproBleDevice {
  deviceId: string;
  name?: string;
  services?: BleService[];
}

export interface ProductSearchCustomer {
  customerId?: string;
  miLoc?: string;
  group1?: string;
  group2?: string;
  searchQuery?: string;
  searchMode?: string;
  advancedSearchFields?: unknown;
}

type CustomerOcnSearch = BaseCustomerSearch;
type CorpCustomerSearch = BaseCustomerSearch;

export interface UserState {
  loginMiLoc?: string;
  miLoc?: string;
  corpLoc?: string;
  currencyType?: string;
  currencyTypeOptions?: string[];
  hasOverriddenDefaultLoc?: number;
  fallbackMiLoc?: string;
  recentlySearched?: string[];
  recentlyViewed?: SearchItem[];
  userInfo?: UserInfo;
  locationTree?: Dictionary<TerritoryLocation>;
  rollupTree?: Territory[];
  accessControl?: Dictionary<unknown>;
  featureToggles?: Dictionary<unknown>;
  fcmToken?: string;
  deeplink?: DeepLink;
  bulletinDismissDate?: string;
  allowAddAllFromStoreroom?: boolean;
  appMode?: AppModeEnum;
  vaultReady?: boolean;
  storeroom?: string;
  storeroomName?: string;
  currentCartCustomer?: CurrentCartCustomer;
  productSearchCustomer?: ProductSearchCustomer;
  customerOcnSearch?: CustomerOcnSearch;
  corpCustomerSearch?: CorpCustomerSearch;
  showCostDetails?: boolean;
  showNewPick12Report?: boolean;
  showNewSalesReport?: boolean;
  showDigitalSalesReport?: boolean;
  isCamUser?: boolean;
  isAvpView?: boolean;
  isAvpUser?: boolean;
  useBleScanner?: boolean;
  pairedBleDevices?: Dictionary<MiproBleDevice>;
  connectedBleDevices?: Dictionary<MiproBleDevice>;
  notificationRegistered?: boolean;
  noticesLastSeenTime?: string;
}

export const initialState: UserState = {
  loginMiLoc: undefined,
  userInfo: {},
  recentlySearched: [],
  recentlyViewed: [],
  locationTree: {},
  accessControl: {},
  featureToggles: {},
  currentCartCustomer: {},
  productSearchCustomer: {},
  customerOcnSearch: {},
  corpCustomerSearch: undefined,
  showCostDetails: false,
  showNewPick12Report: true,
  showNewSalesReport: true,
  showDigitalSalesReport: true,
  isCamUser: undefined,
  isAvpUser: undefined,
  useBleScanner: false,
  notificationRegistered: false,
};

export const setUserDataReducer = (
  state: Draft<UserState>,
  action: PayloadAction<WhoAmIResponse>
): UserState => {
  const jobCode = action.payload.userInfo?.jobCode;
  const teamAccessibleValid = isAccountRep(jobCode);

  const locationTree = getLocationTree(action.payload.rollupTree);

  // some endpoints do not support teams, so we use first accessible non-team location as a fallback
  const fallbackMiLoc = getFallbackDeepestLocation(locationTree);
  const stateMiLoc = locationTree[toString(state.miLoc)];
  const isStateMiLocValid =
    !isEmpty(stateMiLoc?.miLoc) && stateMiLoc?.hasAccess;
  const firstAccessible = teamAccessibleValid
    ? getFirstAccessibleLocation(locationTree)
    : fallbackMiLoc;

  // use miLoc from state when it is valid and the user has previously changed from the default location
  const initialMiLoc =
    !teamAccessibleValid &&
    isStateMiLocValid &&
    state.hasOverriddenDefaultLoc === DEFAULT_LOC_VERSION
      ? state.miLoc
      : firstAccessible?.miLoc;

  void UserInfoStorage.set(action.payload);

  return {
    ...state,
    miLoc: initialMiLoc,
    corpLoc: action.payload.corpLoc,
    currencyType: action.payload.currencyType,
    currencyTypeOptions: action.payload.currencyTypeOptions,
    fallbackMiLoc: fallbackMiLoc?.miLoc,
    loginMiLoc: action.payload.miLoc,
    userInfo: action.payload.userInfo,
    accessControl: action.payload.accessControl,
    featureToggles: action.payload.featureFlags,
    locationTree,
    rollupTree: action.payload.rollupTree,
    isCamUser: action.payload.isCamUser,
    isAvpUser: action.payload.isAvpUser,
  };
};

const { actions, reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<WhoAmIResponse>) =>
      setUserDataReducer(state, action),
    setUserDataFromStorage: (state, action: PayloadAction<UserState>) => ({
      ...state,
      ...action.payload,
    }),
    setUserMiLoc: (state, action: PayloadAction<Pick<UserState, 'miLoc'>>) => ({
      ...state,
      miLoc: action.payload.miLoc,
      // DOC: reset storeroom when changing locations
      storeroom: undefined,
      hasOverriddenDefaultLoc: DEFAULT_LOC_VERSION,
    }),
    setUserStoreroom: (
      state,
      action: PayloadAction<Pick<UserState, 'storeroom' | 'storeroomName'>>
    ) => ({
      ...state,
      storeroom: action.payload.storeroom,
      storeroomName: action.payload.storeroomName,
    }),
    addRecentlySearched: (state, action: PayloadAction<{ text: string }>) => {
      const { text } = action.payload;
      const { recentlySearched = [] } = state;
      remove(recentlySearched, (v: string) => toLower(v) === toLower(text));
      if (!isEmpty(text)) {
        recentlySearched.unshift(text);
      }
      if (size(recentlySearched) > 10) {
        recentlySearched.pop();
      }
    },
    addRecentlyViewed: (state, action: PayloadAction<SearchItem>) => {
      const { type, id, text, customerPick12, isCorpAccountorInfo } =
        action.payload;
      const { miLoc } = action.payload as SearchCustomerItem;
      const { recentlyViewed = [] } = state;
      remove(
        recentlyViewed,
        type === 'customer' ? { type, miLoc, id } : { type, id }
      );
      if (!isEmpty(text)) {
        recentlyViewed.unshift({
          type,
          miLoc,
          id,
          text,
          customerPick12,
          isCorpAccountorInfo,
        });
      }
      if (size(recentlyViewed) > 10) {
        recentlyViewed.pop();
      }
    },
    setFCMToken: (state, action: PayloadAction<string>) => ({
      ...state,
      fcmToken: action.payload,
    }),
    addDeepLink: (state, action: PayloadAction<DeepLink>) => ({
      ...state,
      deeplink: action.payload,
    }),
    setBulletinDismissDate: (
      state,
      action: PayloadAction<string | undefined>
    ) => ({
      ...state,
      bulletinDismissDate: action.payload,
    }),
    setAllowAddAllFromStoreroom: (state, action: PayloadAction<boolean>) => ({
      ...state,
      allowAddAllFromStoreroom: action.payload,
    }),
    setShowCostDetails: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showCostDetails: action.payload,
    }),
    setShowNewPick12Report: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showNewPick12Report: action.payload,
    }),
    setShowNewSalesReport: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showNewSalesReport: action.payload,
    }),
    setShowDigitalSalesReport: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showDigitalSalesReport: action.payload,
    }),
    setUseBleScanner: (state, action: PayloadAction<boolean>) => ({
      ...state,
      useBleScanner: action.payload,
    }),
    setNoticesLastSeenTime: (
      state,
      action: PayloadAction<string | undefined>
    ) => ({
      ...state,
      noticesLastSeenTime: action.payload,
    }),
    connectBleDevice: (state, action: PayloadAction<MiproBleDevice>) => ({
      ...state,
      pairedBleDevices: {
        ...state.pairedBleDevices,
        [action.payload.deviceId]: {
          deviceId: action.payload.deviceId,
          name: action.payload?.name,
        },
      },
      connectedBleDevices: {
        ...state.connectedBleDevices,
        [action.payload.deviceId]: action.payload,
      },
    }),
    disconnectBleDevice: (state, action: PayloadAction<string>) => ({
      ...state,
      connectedBleDevices: {
        ...state.connectedBleDevices,
        [action.payload]: {} as MiproBleDevice,
      },
    }),
    clearRecentlyViewed: (state) => ({ ...state, recentlyViewed: [] }),
    clearDeepLink: (state) => ({ ...state, deeplink: undefined }),
    clearUserData: (state) => ({
      ...state,
      miLoc: undefined,
      currencyType: undefined,
      currencyTypeOptions: undefined,
      corpLoc: undefined,
      loginMiLoc: undefined,
      hasOverriddenDefaultLoc: undefined,
      recentlySearched: [],
      recentlyViewed: [],
      accessControl: initialState.accessControl,
      featureToggles: initialState.featureToggles,
      allowAddAllFromStoreroom: undefined,
      fcmToken: undefined,
      deeplink: undefined,
      storeroom: undefined,
      currentCartCustomer: undefined,
      productSearchCustomer: undefined,
      customerOcnSearch: undefined,
      corpCustomerSearch: undefined,
      isCamUser: undefined,
      isAvpView: undefined,
      isAvpUser: undefined,
      notificationRegistered: false,
      noticesLastSeenTime: undefined,
    }),
    logout: (state) => ({
      miLoc: state.miLoc,
      corpLoc: undefined,
      loginMiLoc: undefined,
      currencyType: undefined,
      currencyTypeOptions: undefined,
      hasOverriddenDefaultLoc: state.hasOverriddenDefaultLoc,
      recentlySearched: state.recentlySearched,
      recentlyViewed: state.recentlyViewed,
      userInfo: initialState.userInfo,
      accessControl: initialState.accessControl,
      featureToggles: initialState.featureToggles,
      locationTree: initialState.locationTree,
      allowAddAllFromStoreroom: undefined,
      fcmToken: undefined,
      deeplink: state.deeplink,
      appMode: undefined,
      bulletinDismissDate: state.bulletinDismissDate,
      storeroom: state.storeroom,
      storeroomName: state.storeroomName,
      currentCartCustomer: state.currentCartCustomer,
      productSearchCustomer: state.productSearchCustomer,
      customerOcnSearch: state.customerOcnSearch,
      corpCustomerSearch: state.corpCustomerSearch,
      showCostDetails: false,
      showNewPick12Report: true,
      showNewSalesReport: true,
      showDigitalSalesReport: true,
      isCamUser: undefined,
      isAvpView: state.isAvpView,
      isAvpUser: undefined,
      useBleScanner: state.useBleScanner,
      pairedBleDevices: state.pairedBleDevices,
      connectedBleDevices: state.connectedBleDevices,
      notificationRegistered: state.notificationRegistered,
      noticesLastSeenTime: state.noticesLastSeenTime,
    }),
    setAppMode: (state, action: PayloadAction<Pick<UserState, 'appMode'>>) => ({
      ...state,
      appMode: action.payload.appMode,
    }),
    setAvpView: (
      state,
      action: PayloadAction<Pick<UserState, 'isAvpView'>>
    ) => ({
      ...state,
      isAvpView: action.payload.isAvpView,
    }),
    setCurrentCartCustomer: (
      state,
      action: PayloadAction<Pick<UserState, 'currentCartCustomer'>>
    ) => ({
      ...state,
      currentCartCustomer: action.payload.currentCartCustomer,
    }),
    clearCurrentCartCustomer: (state) => ({
      ...state,
      currentCartCustomer: undefined,
    }),
    setProductSearchCustomer: (
      state,
      action: PayloadAction<Pick<UserState, 'productSearchCustomer'>>
    ) => ({
      ...state,
      productSearchCustomer: action.payload.productSearchCustomer,
    }),
    setCustomerOcnSearch: (
      state,
      action: PayloadAction<Pick<UserState, 'customerOcnSearch'>>
    ) => ({
      ...state,
      customerOcnSearch: action.payload.customerOcnSearch,
    }),
    setCorpCustomerSearch: (
      state,
      action: PayloadAction<Pick<UserState, 'corpCustomerSearch'>>
    ) => ({
      ...state,
      corpCustomerSearch: action.payload.corpCustomerSearch,
    }),
    setNotificationRegistered: (state, action: PayloadAction<boolean>) => ({
      ...state,
      notificationRegistered: action.payload,
    }),
  },
});
export const {
  logout,
  setUserData,
  setUserDataFromStorage,
  setUserMiLoc,
  setUserStoreroom,
  addRecentlySearched,
  addRecentlyViewed,
  setFCMToken,
  addDeepLink,
  setBulletinDismissDate,
  setAllowAddAllFromStoreroom,
  setShowCostDetails,
  setShowNewPick12Report,
  setShowNewSalesReport,
  clearRecentlyViewed,
  clearUserData,
  clearDeepLink,
  setAppMode,
  setAvpView,
  setCurrentCartCustomer,
  clearCurrentCartCustomer,
  setProductSearchCustomer,
  setCustomerOcnSearch,
  setCorpCustomerSearch,
  setShowDigitalSalesReport,
  setUseBleScanner,
  connectBleDevice,
  disconnectBleDevice,
  setNotificationRegistered,
  setNoticesLastSeenTime,
} = actions;

export default reducer;

type RecentlySearchedSelectorType = (
  state: RootState,
  query: string
) => string[];

export const recentlySearchedSelector = (): RecentlySearchedSelectorType =>
  createSelector(
    (state: RootState) => state.user,
    (_: RootState, query: string) => query,
    ({ recentlySearched }, query) =>
      filter(recentlySearched, (v: string) =>
        new RegExp(escapeRegExp(query), 'i').exec(v)
      ) as string[]
  );

export const loadRawUserDataFromStorage = async (): Promise<
  UserState | undefined
> => {
  const result = await Preferences.get({ key: STORAGE_KEY });
  let userData;
  // TODO: App.test is failing here, should not do falsy validation
  if (!isNil(result?.value)) {
    userData = JSON.parse(result.value) as UserState;
  }
  return userData;
};

export const loadUserDataFromStorage = (): AppThunk => async (dispatch) => {
  const userData = await loadRawUserDataFromStorage();
  if (userData) {
    dispatch(setUserDataFromStorage(userData));
  }
};

export const saveUserDataToStorage: Middleware =
  ({ getState }: MiddlewareAPI) =>
  (next) =>
  async (action: PayloadAction) => {
    const result = next(action);
    const { user: userData } = getState() as RootState;
    switch (action.type) {
      case setUserDataFromStorage.type:
        break;
      case logout.type:
        await Preferences.set({
          key: STORAGE_KEY,
          value: JSON.stringify(omit(userData, 'userInfo')),
        });
        break;
      default:
        await Preferences.set({
          key: STORAGE_KEY,
          value: JSON.stringify(omit(userData, 'userInfo')),
        });
        break;
    }
    return result;
  };
