import React from 'react';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { find, get } from 'lodash';
import ProTipForm from 'ActivitiesApp/components/ProTipForm/ProTipForm';
import { ProTipEnum } from 'ActivitiesApp/models/ProTip';
import { t } from 'i18next';
import classes from 'pages/Activities/ActivityActionCard/ActivityActionCard.module.scss';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import Pick12SVGIcon from 'assets/Pick12.svg';
import ActivityVotes from 'components/Activities/ActivityVotes/ActivityVotes';

const namespace = 'ActivitiesApp-Config:ProTip';

export const getProTipData = ({
  eventTagName,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  if (eventTagName === ProTipEnum.proTip) {
    return {
      cardType: ProTipEnum.proTip,
      title: t(`${namespace}:proTip`),
      svgIcon: Pick12SVGIcon,
    };
  }

  return undefined;
};

export const getProTipConfig = ({
  defaultFirstLine,
  activityVersion,
  activityData,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const data = activityData;
  const subject = find(data?.fields, { name: 'SUBJECT' })?.value as string;
  const sicNames = find(data?.fields, { name: 'sicNames' })?.value as string;
  const requestedName = find(data?.fields, { name: 'REQUESTED NAME' })
    ?.value as string;
  const sicGroupDesc = get(data?.extendedInfo, 'sicGroupsDesc', '');
  const firstLine = defaultFirstLine?.[0];
  const { className, description, highlight, ...rest } = firstLine || {};

  if (activityVersion === `${ProTipEnum.proTip}-1`) {
    return {
      card: {
        defaultTitle: t(`${namespace}:proTip`),
        body: {
          description: '',
          lines: [
            {
              description: subject,
              highlight: subject,
              className: classNames(
                classes.customerName,
                classes.proTipSubject
              ),
              ...rest,
            },
            {
              description: sicNames,
            },
            {
              description: t(`${namespace}:requestedName`, {
                requestedName,
              }),
            },
          ],
        },
      },
      modal: {
        title: t(`${namespace}:proTip`),
        description: sicGroupDesc as string,
        hideCustomerLink: true,
        customContent: (props: Dictionary<unknown>) => {
          return (
            <>
              <ProTipForm
                activity={activityData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
              <ActivityVotes
                activity={activityData}
                title={t(`${namespace}:voteLabel`)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            </>
          );
        },
      },
    };
  }
  return undefined;
};
