import { useTranslation } from 'react-i18next';
import { filter, find, isEmpty, isNil, map, toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { RateVisitBody } from 'ActivitiesApp/models/CustomerVisit';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { findActivitiesV2QueryKey } from 'api/activities/useFindActivitiesV2';
import {
  onSuccessMutation,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  getPlaceholderData,
} from 'api/helpers';
import type { UpdateMutationContext } from 'api/helpers';
import { findNotesQueryKey } from 'api/notebooks/useFindNotes';
import type { ActionCardActivity } from 'models/ActivityModels';
import type { Note } from 'models/Notebook';
import { ToastType } from 'models/Toast';

interface UseRateVisitProps {
  historyId: number;
  userId: string;
  miLoc?: string;
  id?: string;
  noteId?: string;
}

interface UseRateVisitResponse {
  status: MutationStatus;
  onRateVisit: (body: RateVisitBody) => void;
}

const useRateVisit = ({
  historyId,
  userId,
  miLoc,
  id,
  noteId,
}: UseRateVisitProps): UseRateVisitResponse => {
  const { axios } = useAxios();
  const { rateVisitAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { t } = useTranslation(namespaces.activities);
  const { addToast } = useToasts();

  const doRateVisit = (body: RateVisitBody) => {
    return doPromiseAPI(async (cancelToken) => {
      const contacts = map(
        body.contacts,
        ({ sequenceNo, miLoc: itemMiLoc, customerNo, delete: cDelete }) => ({
          sequenceNo,
          miLoc: itemMiLoc,
          customerNo,
          delete: cDelete,
        })
      );
      await axios.post(
        rateVisitAPI(toString(historyId)),
        { ...body, title: '', contacts },
        { cancelToken }
      );
    });
  };

  // TODO: a new API to update notes should be added
  const isNoteUpdate = !isEmpty(miLoc) && !isEmpty(id) && !isEmpty(noteId);

  interface RateVisitMutationContext {
    visitContext: UpdateMutationContext<ActionCardActivity>[];
    visitContextV2: UpdateMutationContext<ActionCardActivity>[];
    noteContext: UpdateMutationContext<Note>[];
  }

  const { status, mutate } = useMutation(doRateVisit, {
    onMutate: async (vars) => {
      const cachedActivity = getPlaceholderData<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        findPredicate: { historyId, userId },
      });

      const updatedChildren = map(
        filter(vars.children, { delete: false }),
        (ev) => {
          let childActivity = getPlaceholderData<ActionCardActivity>({
            queryClient,
            queryKey: findActivitiesQueryKey,
            findPredicate: { historyId: ev.historyId, userId },
          });

          if (isNil(childActivity)) {
            childActivity = find(cachedActivity?.children, {
              historyId: ev.historyId,
              userId,
            });
          }

          return { ...ev, ...childActivity, done: ev.done };
        }
      );
      const updatedContacts = map(
        filter(vars.contacts, ({ delete: cDelete }) => !cDelete),
        ({ sequenceNo, name }) => ({
          contactSeqNo: sequenceNo,
          contactName: name,
        })
      );
      const updatedNote = {
        notebookText: vars.notebook?.text,
        notebookTitle: vars.notebook?.title,
      };
      const visitContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        updatedItems: [
          {
            ...cachedActivity,
            children: updatedChildren,
            extendedInfo: {
              rating: vars.rating,
              contacts: updatedContacts,
              ...updatedNote,
            },
            historyId,
          } as ActionCardActivity,
        ],
        findPredicates: [{ historyId }],
        isInfiniteQuery: true,
      });

      const visitContextV2 = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesV2QueryKey,
        updatedItems: [
          {
            ...cachedActivity,
            children: updatedChildren,
            extendedInfo: {
              rating: vars.rating,
              contacts: updatedContacts,
              ...updatedNote,
            },
            historyId,
          } as ActionCardActivity,
        ],
        dataPath: 'rows',
        findPredicates: [{ historyId }],
        isInfiniteQuery: true,
      });
      let noteContext;
      if (isNoteUpdate) {
        noteContext = await onMutateUpdate<Note>({
          queryClient,
          queryKey: findNotesQueryKey,
          queryKeyParams: { miLoc, id },
          updatedItems: [
            {
              miLoc: toString(miLoc),
              customerNo: toString(id),
              title: toString(vars.notebook?.title),
              text: vars.notebook?.text,
              updatedTimestamp: new Date().toISOString(),
            },
          ],
          findPredicates: [
            {
              miLoc,
              customerNo: id,
              itemLineNo: toNumber(noteId),
            },
          ],
          isInfiniteQuery: true,
        });
      }
      return { visitContext, visitContextV2, noteContext };
    },
    onSuccess: (data, vars) => {
      void onSuccessMutation(queryClient, findActivitiesQueryKey);
      void onSuccessMutation(queryClient, findActivitiesV2QueryKey);
      if (isNoteUpdate) {
        void onSuccessMutation(queryClient, findNotesQueryKey, {
          miLoc,
          id,
        });
        addToast({
          text: t('notes:editToast', {
            title: vars.notebook?.title || t('notes:notebook'),
          }),
          // TODO undo operation
          testid: 'edit-notebook-toast',
        });
      }
    },
    onError: (error, vars, context) => {
      const { visitContext, visitContextV2, noteContext } =
        context as RateVisitMutationContext;
      addToast({
        type: ToastType.error,
        text: t('visitError'),
        testid: 'rate-visit-error-toast',
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: visitContext,
        isInfiniteQuery: true,
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: visitContextV2,
        isInfiniteQuery: true,
      });
      if (isNoteUpdate) {
        onErrorUpdate<Note>({
          queryClient,
          context: noteContext,
          isInfiniteQuery: true,
        });
      }
    },
  });

  return {
    status,
    onRateVisit: (body: RateVisitBody) => mutate(body),
  };
};

export default useRateVisit;
