import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GroupedVirtuoso } from 'react-virtuoso';
import classNames from 'classnames';
import { find, get, isEmpty, toString } from 'lodash';
import ActivitiesGroupContent from 'ActivitiesApp/components/common/ActivitiesGroupContent';
import ActivityItemContent from 'ActivitiesApp/components/common/ActivityItemContent';
import TabSubComponent from 'ActivitiesApp/components/common/TabSubComponent';
import useActivitiesGroupCounts from 'ActivitiesApp/hooks/useActivitiesGroupCounts';
import type { ActivitiesTabBaseProps } from 'ActivitiesApp/models/Activities';
import { MyVisitFilterKeys } from 'ActivitiesApp/models/CustomerVisit';
import { getDateFilterParams } from 'ActivitiesApp/utils/helpers';
import Header from 'common/components/Header/Header';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { or } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import useFindActivitiesV2 from 'api/activities/useFindActivitiesV2';
import type { RootState } from 'store/reducers';
import { formatDate } from 'utils/date';
import DatePickerModal from 'components/DatePickerModal/DatePickerModal';
import type { FilterOption } from 'components/Filter/Filter';
import Filter from 'components/Filter/Filter';
import Text from 'components/Text/Text';
import classes from './MyVisitsTab.module.scss';

interface MyVisitsTabProps extends ActivitiesTabBaseProps, BaseComponentProps {
  triggerResetActivityTab?: number;
  selectedTab?: string;
  isSnapshotActivities: boolean;
}

const MyVisitsTab = ({
  className,
  onDetailClick,
  selectedTab,
  miLoc,
  id,
  triggerResetActivityTab,
  isSnapshotActivities,
}: MyVisitsTabProps) => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const loggedInUserId = get(userInfo, 'userid', '');
  const { t } = useTranslation(namespaces.activities);

  const [filterItem, setFilterItem] = useState<FilterOption>();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [customDate, setCustomDate] = useState<Date>();

  const setFilterData = (option?: FilterOption) => {
    if (!option || filterItem?.key === option.key) {
      if (option?.key === MyVisitFilterKeys.custom_date) {
        setIsCalendarOpen(true);
      } else {
        setFilterItem(undefined);
        setFilterItem({ key: MyVisitFilterKeys.all, name: t('all') });
      }
    } else if (option.key === MyVisitFilterKeys.custom_date) {
      setIsCalendarOpen(true);
    } else {
      if (option?.key !== MyVisitFilterKeys.custom_date) {
        setCustomDate(undefined);
      }
      setFilterItem(option);
    }
  };

  const activityFilterButtons: FilterOption[] = [
    {
      key: MyVisitFilterKeys.all,
      name: t('all'),
    },
    {
      key: MyVisitFilterKeys.today,
      name: t('today'),
    },
    {
      key: MyVisitFilterKeys.last_2weeks,
      name: t('last_2weeks'),
    },
    {
      key: MyVisitFilterKeys.this_month,
      name: t('thisMonth'),
    },
    {
      key: MyVisitFilterKeys.last_month,
      name: t('lastMonth'),
    },
    {
      key: MyVisitFilterKeys.custom_date,
      name:
        customDate && filterItem?.key === MyVisitFilterKeys.custom_date
          ? formatDate(customDate)
          : t('customDate'),
    },
  ];

  const selectedFilterItem = find(activityFilterButtons, {
    key: filterItem?.key,
  });

  const selectedFilters = useMemo(
    () => [selectedFilterItem],
    [selectedFilterItem]
  );

  const defaultFilter =
    !selectedFilterItem || selectedFilterItem?.key === 'all';

  const { startDate, endDate } = getDateFilterParams(
    selectedFilters?.[0],
    customDate
  );

  const {
    items: activities,
    totalItems: total,
    error,
    fetchNextPage,
    hasError,
    isLoading,
    isEmptyResponse,
    enableInfiniteScroll,
  } = useFindActivitiesV2({
    miLoc,
    id,
    filter: {
      showSnoozed: true,
      eventTagName: 'CustomerVisit',
      numDaysToLimitByShowAfter: -1,
    },
    startDate,
    endDate,
    sortField: 'visitDate',
    enabledDataCodesQuery: false,
    enabled: selectedTab === 'myVisits',
  });

  const { groupCounts } = useActivitiesGroupCounts(activities, true);

  useEffect(() => {
    setFilterItem(undefined);
  }, [triggerResetActivityTab]);

  const renderGroupContent = (index: number) => (
    <ActivitiesGroupContent index={index} groupCount={groupCounts[index]} />
  );

  const renderItemContent = (index: number) => {
    const activity = activities[index];

    return (
      <ActivityItemContent
        testid="my-activities-content"
        activity={activity}
        loggedInUserId={loggedInUserId}
        onDetailClick={onDetailClick}
        disableSwipe
        isSnapshotActivities={isSnapshotActivities}
      />
    );
  };

  return (
    <div className={classNames(classes.wrapper, className)}>
      <Header
        className={classNames(classes.header, classes.contentHeader)}
        collapse="condense"
        customTitle={
          <Text
            variant="title-screen-section"
            text={t('myVisitsCalls', { count: or(total, 0) })}
          />
        }
        testid="activities-content-header"
        endSlotComponent={
          <Filter
            className={classes.filter}
            variant="sort"
            selectedItems={selectedFilters}
            setFilterData={[setFilterData]}
            filterOptions={[
              {
                title: t('filterByVisitDate'),
                options: activityFilterButtons,
              },
            ]}
            customButton={{
              className: classNames(classes.filterButton, {
                [classes.filterNone]: defaultFilter,
                [classes.hasFilters]: !defaultFilter,
              }),
              text: defaultFilter ? t('common:filter') : '1',
              testid: 'actitivies-filter-button',
              children: undefined,
              rightIcon: undefined,
              icon: ['far', 'sliders'],
            }}
            isOpen={openFilterModal}
            setIsOpen={setOpenFilterModal}
            testid="activity-filters"
          />
        }
      />
      {!hasError && !isEmpty(activities) && (
        <GroupedVirtuoso
          className={classes.list}
          increaseViewportBy={{
            top: 500,
            bottom: 500,
          }}
          endReached={enableInfiniteScroll ? fetchNextPage : undefined}
          groupCounts={groupCounts}
          groupContent={renderGroupContent}
          itemContent={renderItemContent}
        />
      )}
      <TabSubComponent
        isMyVisitsTab
        filterName={toString(selectedFilters?.[0]?.name)}
        isEmptyResponse={isEmptyResponse}
        showLoader={isLoading}
        hasError={hasError}
        error={error}
      />
      <DatePickerModal
        isOpen={isCalendarOpen}
        setIsOpen={setIsCalendarOpen}
        onDone={(date) => {
          setCustomDate(date);
          setFilterItem({
            key: MyVisitFilterKeys.custom_date,
            name: t('customDate'),
          });
        }}
        date={or(customDate, new Date())}
        testid="expected-date-calendar"
      />
    </div>
  );
};

export default MyVisitsTab;
