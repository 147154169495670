import React from 'react';
import type { Dictionary } from 'lodash';
import { forEach, isNil, map, size } from 'lodash';
import Activities from 'ActivitiesApp/pages/Activities';
import ActivityDetail from 'ActivitiesApp/pages/ActivityDetail';
import ShareActivityNote from 'ActivitiesApp/pages/ShareActivityNote/ShareActivityNote';
import ShareVisitOrCall from 'ActivitiesApp/pages/ShareVisitOrCall/ShareVisitOrCall';
import CostSavingsEntry from 'CostSavingsApp/pages/CostSavingsEntry';
import CostSavingsInformation from 'CostSavingsApp/pages/CostSavingsInformation';
import CreateDocument from 'DocumentsApp/pages/CreateDocument';
import DocumentDetail from 'DocumentsApp/pages/DocumentDetail';
import DocumentList from 'DocumentsApp/pages/DocumentList';
import {
  NotificationListRouter,
  ShareProTipRouter,
} from 'HomeApp/navigation/routerConfig';
import { CountGroupListTypeEnum } from 'InventoryApp/models/InventoryPlanGroup';
import CountGroupList from 'InventoryApp/pages/CountGroupList/CountGroupList';
import CountPlanInventory from 'InventoryApp/pages/CountPlanInventory/CountPlanInventory';
import FindStartCustomer from 'InventoryApp/pages/FindStartCustomer/FindStartCustomer';
import HomeInventory from 'InventoryApp/pages/HomeInventory/HomeInventory';
import ShippingCustomerList from 'InventoryApp/pages/ShippingCustomerList/ShippingCustomerList';
import {
  announcementDetailURL,
  announcementsURL,
  bulletinDetailURL,
  bulletinsURL,
  createDocumentURL,
  customerActionRouteURL,
  customerAddEditNoteURL,
  customerExceptionsURL,
  customerProductDetailURL,
  devToolsURL,
  documentDetailsURL,
  feedbackURL,
  manageFavoritesURL,
  reportsDrillDownURL,
  searchCustomerURL,
  searchDetailURL,
  supplierActionRouteURL,
  supplierInventoryAnalysisURL,
  searchContactDetailURL,
  searchSupplierURL,
  salesPlayDetailURL,
  salesPlayProductURL,
  replenishmentStoreroomSearchURL,
  workOrderDetailsURL,
  searchLocationDetailURL,
  issueStoreroomListURL,
  notificationSettingsURL,
  newIssueURL,
  activityAddEditNoteURL,
  settingsURL,
  currencyURL,
  activityDetailURL,
  costSavingsURL,
  costSavingsDetailsURL,
  reviewIssuesURL,
  homeInventoryURL,
  findStartCustomerURL,
  shippingCustomersURL,
  countPlanURL,
  countGroupListURL,
  shareActivityByEmailURL,
  shareCustomerNoteByEmailURL,
} from 'navigation';
import {
  EmptyCartRouter,
  ProductSearchAppRouter,
} from 'ProductSearchApp/navigation/routerConfig';
import ProductSearchDetail from 'ProductSearchApp/pages/ProductSearchDetail';
import { ReportsAppRouter } from 'ReportsApp/navigation/routerConfig';
import { CorpQuickActionsRouter } from 'SearchApp/navigation/routerConfig';
import IssueItems from 'StoreroomsApp/pages/IssueItems';
import IssueStoreroomList from 'StoreroomsApp/pages/IssueStoreroomList';
import IssueStorerooms from 'StoreroomsApp/pages/IssueStorerooms';
import Replenishment from 'StoreroomsApp/pages/Replenishment';
import ReviewIssues from 'StoreroomsApp/pages/ReviewIssues';
import StoreroomSearch from 'StoreroomsApp/pages/StoreroomSearch';
import WorkOrderDetails from 'WorkOrders/pages/WorkOrderDetails';
import WorkOrdersList from 'WorkOrders/pages/WorkOrdersList';
import { AccessControlType } from 'hooks/useAccessControls';
import type { FeatureToggleType } from 'hooks/useFeatureToggles';
import type { SnapshotActionRoute, TabRoutes } from 'models/Navigation';
import { concatRoutes } from 'utils/navigations';
import PrivateRoute from 'navigation/PrivateRoute/PrivateRoute';
import ActivityAddEditNote from 'pages/Activities/ActivityNotes/ActivityAddEditNote';
import Contact from 'pages/Contact/Contact';
import Employee from 'pages/Employee/Employee';
import Favorites from 'pages/Favorites/Favorites';
import NewHome from 'pages/Home/NewHome';
import Leaderboard from 'pages/Leaderboard/Leaderboard';
import Location from 'pages/Location/Location';
import AnnouncementDetail from 'pages/Menu/Announcements/AnnouncementDetail';
import AnnouncementList from 'pages/Menu/Announcements/AnnouncementList';
import BulletinDetail from 'pages/Menu/Bulletins/BulletinDetail';
import BulletinList from 'pages/Menu/Bulletins/BulletinList';
import DevTools from 'pages/Menu/DevTools/DevTools';
import Feedback from 'pages/Menu/Feedback/Feedback';
import NotificationsSettings from 'pages/Menu/NotificationsSettings/NotificationsSettings';
import CurrencySelection from 'pages/Menu/Settings/Currency/CurrencySelection';
import Settings from 'pages/Menu/Settings/Settings';
import NewReports from 'pages/Reports/NewReports';
import Reports from 'pages/Reports/Reports';
import CustomerSalesPlays from 'pages/SalesPlays/CustomerSalesPlays';
import SalesPlayDetail from 'pages/SalesPlays/SalesPlayDetail/SalesPlayDetail';
import SalesPlays from 'pages/SalesPlays/SalesPlays';
import Search from 'pages/Search/Search';
import Contacts from 'pages/Snapshots/Contacts/Contacts';
import AccountsReceivable from 'pages/Snapshots/Customers/AccountsReceivable/AccountsReceivable';
import AgreementInformation from 'pages/Snapshots/Customers/AgreementInformation/AgreementInformation';
import Exceptions from 'pages/Snapshots/Customers/AgreementInformation/Exceptions';
import AddEditNote from 'pages/Snapshots/Customers/NotesAttachments/AddEditNote';
import NotesAttachmentList from 'pages/Snapshots/Customers/NotesAttachments/NotesAttachmentsList';
import ShareCustomerNote from 'pages/Snapshots/Customers/ShareCustomerNote/ShareCustomerNote';
import VisitHistory from 'pages/Snapshots/Customers/VisitHistory/VisitHistory';
import WebActivity from 'pages/Snapshots/Customers/WebActivity/WebActivity';
import Snapshots from 'pages/Snapshots/Snapshots';
import DCApprovedVendors from 'pages/Snapshots/Suppliers/DCApprovedVendors/DCApprovedVendors';
import Notebooks from 'pages/Snapshots/Suppliers/Notebooks/Notebooks';
import InventoryAnalysis from 'pages/Snapshots/Suppliers/SupplierInformation/InventoryAnalysis';
import SupplierInformation from 'pages/Snapshots/Suppliers/SupplierInformation/SupplierInformation';
import VendorIncreaseLetters from 'pages/Snapshots/Suppliers/VendorIncreaseLetters/VendorIncreaseLetters';

export const getRoutes = (
  rootPath: string,
  router: TabRoutes[] = [],
  rootAccessControl?: AccessControlType,
  rootFeatureToggles?: FeatureToggleType | FeatureToggleType[]
): JSX.Element[] => {
  let resultRoutes: JSX.Element[] = [];

  forEach(
    router,
    (
      {
        path,
        wrapper,
        component,
        props,
        routes,
        accessControl,
        featureToggles,
        routeName,
        track,
      },
      index
    ) => {
      if (size(routes) > 0) {
        resultRoutes = resultRoutes.concat(
          getRoutes(
            concatRoutes(rootPath, path),
            routes,
            isNil(accessControl) ? rootAccessControl : accessControl,
            isNil(featureToggles) ? rootFeatureToggles : featureToggles
          )
        );
      } else {
        resultRoutes.push(
          <PrivateRoute
            key={`${path}${index}`}
            exact
            path={concatRoutes(rootPath, path)}
            accessControl={
              isNil(accessControl) ? rootAccessControl : accessControl
            }
            featureToggles={
              isNil(featureToggles) ? rootFeatureToggles : featureToggles
            }
            routeName={routeName}
            track={track}
            render={(routeProps) => {
              const CWrapper = wrapper || React.Fragment;
              const Component = component as React.FC<Dictionary<unknown>>;

              return (
                <CWrapper>
                  <Component
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...(props as Dictionary<unknown>)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...routeProps}
                  />
                </CWrapper>
              );
            }}
          />
        );
      }
    }
  );

  return resultRoutes;
};

export const costSavingsRouter: TabRoutes[] = [
  {
    path: '',
    component: CostSavingsEntry as React.FC,
    props: { mode: 'entry' },
    routeName: 'Cost Savings Entry',
  },
  {
    path: costSavingsDetailsURL('edit'),
    component: CostSavingsEntry as React.FC,
    routeName: 'Edit Cost Savings Details',
    props: { mode: 'edit' },
  },
  {
    path: costSavingsDetailsURL('view'),
    component: CostSavingsEntry as React.FC,
    routeName: 'Review Cost Savings Details',
    props: { mode: 'view' },
  },
];

export const bulletinsRouter: TabRoutes[] = [
  { path: '', component: BulletinList, routeName: 'Bulletin List' },
  {
    path: bulletinDetailURL(),
    component: BulletinDetail,
    routeName: 'Bulletin Detail',
  },
];

export const announcementsRouter: TabRoutes[] = [
  { path: '', component: AnnouncementList, routeName: 'Announcement List' },
  {
    path: announcementDetailURL(),
    component: AnnouncementDetail,
    routeName: 'Announcement Detail',
  },
];

export const notificationSettingsRouter: TabRoutes[] = [
  {
    path: '',
    component: NotificationsSettings,
    routeName: 'Notification Settings',
  },
];

// #region mipro tabs
export const reportsRouter: TabRoutes[] = [
  { path: '', component: NewReports as React.FC, routeName: 'Reports' },
  {
    path: reportsDrillDownURL('pick12LeaderBoard'),
    component: Leaderboard as React.FC,
    routeName: 'Visit Pick 12 Leaderboard',
  },
  {
    path: reportsDrillDownURL('pick12'),
    component: Reports as React.FC,
    props: { reportType: 'pick12' },
    routeName: 'Reports Pick 12',
  },
  {
    path: reportsDrillDownURL('locationPick12'),
    component: Reports as React.FC,
    props: { reportType: 'locationPick12' },
    routeName: 'Reports Location Pick 12',
  },
  {
    path: reportsDrillDownURL('webSales'),
    component: Reports as React.FC,
    props: { reportType: 'webSales' },
    routeName: 'Reports Web Sales Performance',
  },
  {
    path: reportsDrillDownURL('unbilled'),
    component: Reports as React.FC,
    props: { reportType: 'unbilled' },
    routeName: 'Unbilled Orders Report',
  },
  ...ReportsAppRouter,
];

export const agreementInformationRouter: TabRoutes[] = [
  {
    path: '',
    component: AgreementInformation,
    routeName: 'Customer Agreement Information',
  },
  {
    path: customerExceptionsURL(),
    component: Exceptions,
    routeName: 'Customer Exceptions',
  },
];

const contactActionRoute: SnapshotActionRoute = {
  text: 'Contacts',
  translate: 'snapshot:contacts',
  action: 'contacts',
  component: Contacts as React.FC,
  props: { searchType: 'customer' },
  routeName: 'Customer Contacts',
};

const costSavingsActionRoute: SnapshotActionRoute = {
  text: 'Cost Savings',
  translate: 'costSavings:costSaving',
  action: 'cost-savings',
  routeName: 'Customer Cost Savings',
  component: CostSavingsInformation,
  accessControl: AccessControlType.CostSavingsAccessControls,
};

const arActionRoute: SnapshotActionRoute = {
  text: 'Agreement Information',
  translate: 'agreementInfo:agreementInfo',
  action: 'agreement-information',
  routeName: 'Customer Agreement Information',
  routes: agreementInformationRouter,
};

const noteAndAttachmentsActionRoute: SnapshotActionRoute = {
  text: 'Notes and Attachments',
  translate: 'snapshot:notesAttachments',
  action: 'notes-and-attachments',
  routeName: 'Customer Notes and Attachments',
  routes: [
    {
      path: '',
      component: NotesAttachmentList,
      routeName: 'Customer Notes And Attachment List',
    },
    {
      path: customerAddEditNoteURL(),
      routeName: 'Customer Note',
      routes: [
        {
          path: '',
          component: AddEditNote,
          routeName: 'Add Edit Note',
        },
        {
          path: shareCustomerNoteByEmailURL(),
          component: ShareCustomerNote,
          routeName: 'Share Note',
        },
      ],
    },
  ],
};

const visitHistoryActionRoute: SnapshotActionRoute = {
  text: 'Visit History',
  translate: 'snapshot:visitHistory',
  action: 'visit-history',
  component: VisitHistory,
  routeName: 'Customer Visit History',
};

export const reportsActionRoute: SnapshotActionRoute = {
  text: 'Reports',
  translate: 'common:reports',
  action: 'reports',
  routes: reportsRouter,
  routeName: 'Customer Reports',
};

export const corporateAccountActionRoutes: SnapshotActionRoute[] = [
  costSavingsActionRoute,
  { ...reportsActionRoute, action: 'corp-reports' },
  visitHistoryActionRoute,
  contactActionRoute,
  noteAndAttachmentsActionRoute,
  arActionRoute,
];

export const customerActionRoutes: SnapshotActionRoute[] = [
  {
    text: 'Sales Plays',
    translate: 'common:salesPlays',
    action: 'sales-plays',
    routeName: 'Customer Opportunities',
    routes: [
      {
        path: '',
        component: CustomerSalesPlays,
        routeName: 'Customer Opportunities',
      },
      {
        path: salesPlayDetailURL(),
        component: SalesPlayDetail,
        routeName: 'Customer SalesPlay Detail',
      },
      {
        path: salesPlayProductURL(),
        component: ProductSearchDetail,
        routeName: 'Customer Product Detail',
      },
    ],
  },
  costSavingsActionRoute,
  {
    text: 'Activities',
    translate: 'common:activities',
    action: 'activities',
    component: Activities,
    routeName: 'Customer Activities',
  },
  reportsActionRoute,
  {
    text: 'Accounts Receivable',
    translate: 'snapshot:accountsReceivable',
    action: 'accounts-receivable',
    component: AccountsReceivable,
    routeName: 'Customer Accounts Receivable',
    hideInSnapshot: true,
  },
  {
    text: 'Web Activity',
    translate: 'snapshot:webActivity',
    action: 'web-activity',
    routeName: 'Customer Web Activity',
    routes: [
      { path: '', component: WebActivity, routeName: 'Web Activity' },
      {
        path: customerProductDetailURL(),
        component: ProductSearchDetail,
        routeName: 'Customer Product Detail',
      },
    ],
  },
  visitHistoryActionRoute,
  {
    text: 'Log A Visit',
    translate: 'snapshot:logVisit',
    action: 'log-a-visit',
    component: ActivityDetail,
    routeName: 'Log A Visit',
    hideInSnapshot: true,
  },
  contactActionRoute,
  noteAndAttachmentsActionRoute,
  arActionRoute,
  ...ProductSearchAppRouter,
];

export const customersRouter: TabRoutes[] = [
  {
    path: '',
    component: Snapshots as React.FC,
    props: { searchType: 'customer' },
    routeName: 'Customer Snapshot',
  },
  ...map(
    customerActionRoutes,
    ({
      path,
      action,
      wrapper,
      component,
      props,
      routes,
      accessControl,
      routeName,
    }) => ({
      path: path || customerActionRouteURL(action),
      wrapper,
      component,
      props,
      routes,
      accessControl,
      routeName,
    })
  ),
  ...CorpQuickActionsRouter,
];

export const supplierActionRoutes: SnapshotActionRoute[] = [
  {
    text: 'Contacts',
    translate: 'snapshot:contacts',
    action: 'contacts',
    component: Contacts as React.FC,
    props: { searchType: 'supplier' },
    routeName: 'Supplier Contacts',
  },
  {
    text: 'Supplier Information',
    translate: 'snapshot:supplierInfo',
    action: 'supplier-information',
    routeName: 'Supplier Information',
    routes: [
      {
        path: '',
        component: SupplierInformation,
        routeName: 'Supplier Information',
      },
      {
        path: supplierInventoryAnalysisURL(),
        component: InventoryAnalysis,
        routeName: 'Supplier Inventory Analysis',
      },
    ],
  },
  {
    text: 'Vendor Increase Letters',
    translate: 'snapshot:vendorIncrease',
    action: 'vendor-increase-letters',
    component: VendorIncreaseLetters,
    routeName: 'Supplier Vendor Increase Letters',
  },
  {
    text: 'DC Approved Vendors',
    translate: 'snapshot:dcApprovedVendors',
    action: 'dc-approved-vendors',
    component: DCApprovedVendors,
    routeName: 'Supplier DC Approved Vendors',
  },
  {
    text: 'Notebooks',
    translate: 'snapshot:notebooks',
    action: 'notebooks',
    component: Notebooks,
    routeName: 'Supplier Notebooks',
  },
];

export const suppliersRouter: TabRoutes[] = [
  {
    path: '',
    component: Snapshots as React.FC,
    props: { searchType: 'supplier' },
    routeName: 'Supplier Snapshot',
  },
  ...map(
    supplierActionRoutes,
    ({ action, wrapper, component, props, routes, routeName }) => ({
      path: supplierActionRouteURL(action),
      wrapper,
      component,
      props,
      routes,
      routeName,
    })
  ),
];

export const employeesRouter: TabRoutes[] = [
  {
    path: '',
    component: Employee as React.FC,
    props: { searchType: 'employee' },
    routeName: 'Employee Snapshot',
  },
];

export const supplierContactRouter: TabRoutes[] = [
  {
    path: '',
    component: Contact as React.FC,
    props: { searchType: 'supplier' },
    routeName: 'Supplier Contact',
  },
];

export const customerContactRouter: TabRoutes[] = [
  {
    path: '',
    component: Contact as React.FC,
    props: { searchType: 'customer' },
    routeName: 'Customer Contact',
  },
];

export const locationDetailRouter: TabRoutes[] = [
  {
    path: '',
    component: Location as React.FC,
    routeName: 'LocationDetail Contact',
  },
];

export const favoritesRouter: TabRoutes[] = [
  { path: '', component: Favorites, routeName: 'Favorites' },
  {
    path: searchCustomerURL(),
    routes: customersRouter,
    accessControl: AccessControlType.viewCustomers,
    routeName: 'Customer Search',
  },
  {
    path: searchSupplierURL(),
    routes: suppliersRouter,
    routeName: 'Customer Search Detail',
  },
];

export const settingsRouter: TabRoutes[] = [
  { path: '', component: Settings, routeName: 'Settings' },
  {
    path: notificationSettingsURL(),
    routes: notificationSettingsRouter,
    routeName: 'NotificationSettings',
  },
  {
    path: manageFavoritesURL(),
    routes: favoritesRouter,
    routeName: 'Manage Favorites',
  },
  {
    path: currencyURL(),
    component: CurrencySelection,
    routeName: 'Select Currency',
  },
];

export const menuRoutes: TabRoutes[] = [
  {
    path: bulletinsURL(),
    routes: bulletinsRouter,
    routeName: 'Bulletins',
  },
  {
    path: announcementsURL(),
    routes: announcementsRouter,
    routeName: 'Announcements',
  },
  {
    path: settingsURL(),
    routes: settingsRouter,
    routeName: 'Settings',
  },
  {
    path: notificationSettingsURL(),
    routes: notificationSettingsRouter,
    routeName: 'NotificationSettings',
  },
  { path: feedbackURL(), component: Feedback, routeName: 'Feedback' },
  { path: devToolsURL(), component: DevTools, routeName: 'Developer Tools' },
  ...ShareProTipRouter,
  ...NotificationListRouter,
];

export const activitiesRouter: TabRoutes[] = [
  { path: '', component: Activities, routeName: 'Activities' },
  {
    path: activityAddEditNoteURL(),
    routeName: 'Activity Note',
    routes: [
      {
        path: '',
        component: ActivityAddEditNote,
        routeName: 'Add Edit Note',
      },
      {
        path: shareActivityByEmailURL(),
        component: ShareActivityNote,
        routeName: 'Share Note',
      },
    ],
  },
  {
    path: activityDetailURL(),
    routeName: 'Activity Detail',
    routes: [
      {
        path: '',
        component: ActivityDetail,
        routeName: 'Activity Detail',
      },
      {
        path: shareActivityByEmailURL(),
        component: ShareVisitOrCall,
        routeName: 'Share Visit Or Call',
      },
    ],
  },
];

export const homeRouter: TabRoutes[] = [
  { path: '', component: NewHome, routeName: 'Home' },
  {
    path: manageFavoritesURL(),
    routes: favoritesRouter,
    routeName: 'Manage Favorites',
  },
  ...menuRoutes,
];

export const searchRouter: TabRoutes[] = [
  { path: '', component: Search, routeName: 'Search' },
  {
    path: manageFavoritesURL(),
    routes: favoritesRouter,
    routeName: 'Manage Favorites',
  },
  {
    path: searchCustomerURL(),
    routes: customersRouter,
    accessControl: AccessControlType.viewCustomers,
    routeName: 'Search Customer',
  },
  {
    path: searchSupplierURL(),
    routes: suppliersRouter,
    routeName: 'Search Supplier Detail',
  },
  {
    path: searchDetailURL('employee'),
    routes: employeesRouter,
    routeName: 'Search Employee Detail',
  },
  {
    path: searchContactDetailURL('supplier'),
    routes: supplierContactRouter,
    routeName: 'Search Supplier Contact Detail',
  },
  {
    path: searchContactDetailURL('customer'),
    routes: customerContactRouter,
    routeName: 'Search Customer Contact Detail',
  },
  {
    path: searchLocationDetailURL(),
    routes: locationDetailRouter,
    routeName: 'Search Location Detail',
  },
  {
    path: costSavingsURL(),
    routes: costSavingsRouter,
    routeName: 'Cost Savings',
  },
  ...EmptyCartRouter,
];

export const salesPlaysRouter: TabRoutes[] = [
  {
    path: '',
    component: SalesPlays,
    routeName: 'Sales Plays',
  },
  {
    path: salesPlayDetailURL(),
    component: SalesPlayDetail,
    routeName: 'Sales Play Detail',
  },
  {
    path: salesPlayProductURL(),
    component: ProductSearchDetail,
    routeName: 'Opportunity Product Detail',
  },
];

// #endregion

// #region miproservices tabs
export const documentsRouter: TabRoutes[] = [
  {
    path: '',
    component: DocumentList,
    routeName: 'Document List',
    accessControl: AccessControlType.viewDocuments,
  },
  {
    path: createDocumentURL(),
    component: CreateDocument,
    routeName: 'Create Document',
    accessControl: AccessControlType.editDocuments,
  },
  {
    path: documentDetailsURL(),
    component: DocumentDetail,
    routeName: 'Document Detail',
    accessControl: AccessControlType.viewDocuments,
  },
  ...menuRoutes,
];

export const workOrdersRouter: TabRoutes[] = [
  { path: '', component: WorkOrdersList, routeName: 'Work Order List' },
  {
    path: workOrderDetailsURL(),
    component: WorkOrderDetails,
    routeName: 'Work Order Detail',
  },
  ...menuRoutes,
];

export const replenishmentViewRouter: TabRoutes[] = [
  {
    path: '',
    component: Replenishment,
    routeName: 'Replenishments',
    accessControl: AccessControlType.viewItemReplenishment,
  },
  {
    path: replenishmentStoreroomSearchURL(),
    component: StoreroomSearch,
    routeName: 'Replenishment Storeroom Search',
    accessControl: AccessControlType.viewItemReplenishment,
  },
  ...menuRoutes,
];

export const issueViewRouter: TabRoutes[] = [
  {
    path: '',
    component: IssueStorerooms,
    routeName: 'Issue',
    accessControl: AccessControlType.viewIssueProcessing,
  },
  {
    path: issueStoreroomListURL(),
    component: IssueStoreroomList,
    routeName: 'Issue Storeroom List',
    accessControl: AccessControlType.viewIssueProcessing,
  },
  {
    path: newIssueURL(),
    component: IssueItems,
    routeName: 'New Issue',
    accessControl: AccessControlType.editIssueProcessing,
  },
  {
    path: reviewIssuesURL(),
    component: ReviewIssues,
    routeName: 'Review Issues',
    accessControl: AccessControlType.viewIssueProcessing,
  },
  ...menuRoutes,
];
// #endregion

// #region inventory
export const inventoryRouter: TabRoutes[] = [
  {
    path: homeInventoryURL(),
    component: HomeInventory as React.FC,
    routeName: 'Inventory',
  },
  {
    path: findStartCustomerURL(),
    component: FindStartCustomer as React.FC,
    routeName: 'Find Start Customer',
  },
  {
    path: shippingCustomersURL(),
    component: ShippingCustomerList as React.FC,
    routeName: 'Shipping Customer List',
  },
  {
    path: countGroupListURL(CountGroupListTypeEnum.pending),
    component: CountGroupList as React.FC,
    props: { countListType: CountGroupListTypeEnum.pending },
    routeName: 'Pending Count Group List',
  },
  {
    path: countGroupListURL(CountGroupListTypeEnum.newInventory),
    component: CountGroupList as React.FC,
    props: { countListType: CountGroupListTypeEnum.newInventory },
    routeName: 'New Inventory Count Group List',
  },
  {
    path: countPlanURL(),
    component: CountPlanInventory as React.FC,
    routeName: 'Count Plan Inventory',
  },
];
// #endregion
