import { head } from 'lodash';
import type { InfiniteData } from '@tanstack/react-query';
import type { BaseQueryProps } from 'common/api/utils/apiHelpers';
import { getURLParams } from 'common/api/utils/apiHelpers';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import type { ActivityNote } from 'models/Notebook';
import { pageSize } from 'utils/constants';
import { findActivityNotesAPIv2 } from '.';

export const findActivityNotesV2QueryKey = 'activity-notes-v2';

interface UseActivityNotesProps extends BaseQueryProps {
  historyId?: number;
  eventUserId?: string;
  sortCol?: string;
}

export interface UseFindActivityNotesResponse {
  rows: ActivityNote[];
  totalRows: number;
  subscriptionCount: number;
  subscribed: boolean;
  thumbCount?: unknown;
}

const useFindActivityNotesV2 = ({
  historyId,
  sortCol = 'lastUpdatedTimestamp',
  sortDir = 'DESC',
  enabled = true,
  limit = pageSize(),
}: UseActivityNotesProps) => {
  const { axios } = useAxios();
  const params = {
    historyId,
    showAttachments: true,
    sortCol,
    sortDir,
    limit,
  };

  const response = useQuerySetup<ActivityNote, UseFindActivityNotesResponse>({
    queryKey: findActivityNotesV2QueryKey,
    queryParams: params,
    isInfiniteQuery: true,
    enabled,
    dataPath: 'rows',
    totalDataPath: 'totalRows',
    onAPIRequest: async ({ pageParam = 1, signal }) => {
      const { data: apiData } = await axios.get<UseFindActivityNotesResponse>(
        findActivityNotesAPIv2(getURLParams({ ...params, page: pageParam })),
        { signal }
      );
      return apiData;
    },
  });

  const data = response.data as InfiniteData<UseFindActivityNotesResponse>;
  const responseData = head(data?.pages);

  return {
    activityNotes: response.items,
    commentsCount: responseData?.totalRows,
    subscriptionCount: responseData?.subscriptionCount,
    subscribed: responseData?.subscribed,
    ...response,
  };
};

export default useFindActivityNotesV2;
