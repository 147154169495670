import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import { findIndustrySegmentsAPI } from '.';

export const findIndustrySegmentsQueryKey = 'find-industry-segments';

export interface IndustrySegment {
  id: number;
  industryName: string;
  sicCode: string;
}

const useFindIndustrySegments = () => {
  const { axios } = useAxios();

  const response = useQuerySetup<IndustrySegment, IndustrySegment[]>({
    queryKey: findIndustrySegmentsQueryKey,
    isInfiniteQuery: false,
    onAPIRequest: async ({ signal }) => {
      const { data } = await axios.get<IndustrySegment[]>(
        findIndustrySegmentsAPI(),
        { signal }
      );
      return data;
    },
  });

  return { industrySegments: response.items, ...response };
};

export default useFindIndustrySegments;
