import { API_URL } from 'api';

export const findIndustrySegmentsAPI = () =>
  `${API_URL}/fastfind/industrySegments`;

export const findManufacturersAPI = (params: string) =>
  `${API_URL}/fastfind/manufacturer?${params}`;

export const findEmployeesAPI = (params: string) =>
  `${API_URL}/employee/search?${params}`;

export const getIndustryUserCountAPI = (params: string) =>
  `${API_URL}/task/proTip/sharedUserCount?${params}`;

export const submitProTipAPI = () => `${API_URL}/task/proTip`;
