import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCommentCardConfigs } from 'HomeApp/components/CommentCard/CommentCard';
import classes from 'HomeApp/components/NotificationActionCard/NotificationActionCard.module.scss';
import type { NotificationItem } from 'HomeApp/models/Notifications';
import { NotificationTypeEnum } from 'HomeApp/models/Notifications';
import { getNotificationType } from 'HomeApp/utils/helpers';
import Text from 'components/Text/Text';

interface UseGetNotificationCardConfigProps {
  notificationItem: NotificationItem;
}

export interface UseGetNotificationCardConfigResponse {
  title: React.ReactNode;
  description: React.ReactNode;
  icon: string;
  iconColor: string;
  href?: string;
}

const useGetNotificationCardConfig = ({
  notificationItem,
}: UseGetNotificationCardConfigProps): UseGetNotificationCardConfigResponse => {
  const { t } = useTranslation();

  const type = getNotificationType(notificationItem.notification.className);

  let title = (
    <Text
      text={t('HomeApp-Notifications:unSupportedFormatTitle', { type })}
      variant="title-info-card-activity"
      className={classes.defaultCardTitle}
    />
  );
  let description = (
    <Text
      text={t('HomeApp-Notifications:unSupportedFormatSubTitle')}
      variant="label-micro"
    />
  );
  let icon = 'triangle-exclamation';
  let iconColor = 'red';
  let href = '';

  switch (type) {
    case NotificationTypeEnum.CommentNotification:
    case NotificationTypeEnum.CommentTask:
      ({ title, description, icon, iconColor, href } = getCommentCardConfigs(
        notificationItem,
        type
      ));
      break;
    default:
  }

  return { title, description, icon, iconColor, href };
};

export default useGetNotificationCardConfig;
