import { last, replace, split } from 'lodash';
import type { NotificationTypeEnum } from 'HomeApp/models/Notifications';

export const getNotificationType = (className: string) =>
  last(split(className, '.')) as NotificationTypeEnum;

export const isCommentTypeMentioned = (notificationType: string) =>
  notificationType === 'Mentioned';

export const convertComment = (comment: string) =>
  replace(comment, /<[^<>]+>\(([^()]+)\)/g, '<strong>@$1</strong>');
