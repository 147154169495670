import React from 'react';
import { extend, isNil } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import classNames from 'classnames/bind';
import { IconColor } from 'models/Legend';
import { getUserInitials } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './CardAvatar.module.scss';

interface CardAvatarProps {
  icon?: IconName;
  svgIcon?: string;
  color?: IconColor | string;
  backgroundColor?: IconColor | string;
  name: string;
  testid: string;
  transform?: string;
  onClick?: () => void;
}

const CardAvatar = ({
  icon,
  svgIcon,
  color,
  backgroundColor,
  name,
  className,
  testid,
  transform,
  onClick,
}: CardAvatarProps & IonicReactProps): JSX.Element => {
  const avatarClasses = {
    greyBackground: backgroundColor === IconColor.grey,
    whiteBackground: backgroundColor === IconColor.white,
  };

  if (!isNil(color)) {
    extend(avatarClasses, { [color]: true });
  }

  const cx = classNames.bind(classes);

  return (
    <Button
      className={cx(className, classes.CardAvatar, avatarClasses)}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
        }
        onClick?.();
      }}
      testid={`card-avatar-${testid}`}
    >
      {isNil(icon) && (
        <Text
          variant="title-screen-section"
          text={getUserInitials(name)}
          testid={`card-avatar-fallback-${testid}`}
        />
      )}
      {!isNil(icon) && (
        <FontAwesomeIcon
          className={classes.icon}
          icon={findIcon(icon)}
          data-testid={`card-avatar-icon-${testid}`}
          transform={transform}
        />
      )}
      {!isNil(svgIcon) && (
        <img
          alt=""
          className={classes.icon}
          src={svgIcon}
          data-testid={`card-avatar-svg-icon-${testid}`}
        />
      )}
    </Button>
  );
};

export default CardAvatar;
