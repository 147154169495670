import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, isNil, map, toString } from 'lodash';
import {
  IonCol,
  IonContent,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
} from '@ionic/react';
import Header from 'common/components/Header/Header';
import useDownloadCountPlanOptions from 'InventoryApp/api/useDownloadCountPlanOptions';
import useDownloadGroupItems from 'InventoryApp/api/useDownloadGroupItems';
import useFindShippingCustomers from 'InventoryApp/api/useFindShippingCustomers';
import useSubmitInventoryPlan from 'InventoryApp/api/useSubmitInventoryPlan';
import InventoryCustomTitle from 'InventoryApp/components/InventoryCustomTitle/InventoryCustomTitle';
import ListItem from 'InventoryApp/components/ListItem/ListItem';
import useInventoryHeaderActions from 'InventoryApp/hooks/useInventoryHeaderActions';
import type { CountGroup } from 'InventoryApp/models/InventoryPlanGroup';
import type {
  ShippingCustomer,
  ShippingCustomerURLParams,
} from 'InventoryApp/models/StartProfile';
import { getInventoryPlanIcon } from 'InventoryApp/util/inventoryUtil';
import { countPlanURL, inventoryURL } from 'navigation';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import useGetCustomer from 'api/customer/useGetCustomer';
import { formatSnoozeDate, parseDate } from 'utils/date';
import { concatRoutes } from 'utils/navigations';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import Refresher from 'components/Refresher/Refresher';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './ShippingCustomerList.module.scss';

const ShippingCustomerList = (): JSX.Element => {
  const { miLoc, customerNo } = useParams<ShippingCustomerURLParams>();
  const history = useHistory();
  const { isOnline } = useNetworkStatus();
  const { t } = useTranslation();
  const [selectedCustomer, setSelectedCustomer] = useState<ShippingCustomer>();
  const [resumeGroup, setResumeGroup] = useState<CountGroup>();
  const [planCreated, setPlanCreated] = useState(false);
  const [planResumed, setPlanResumed] = useState(false);
  const [resumeModal, setResumeModal] = useState(false);

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id: customerNo,
  });

  const {
    shippingCustomers,
    showLoader,
    lastUpdatedAt,
    refetch,
    fetchNextPage,
    enableInfiniteScroll,
    isEmptyResponse,
  } = useFindShippingCustomers({
    miLoc,
    customerNo,
  });

  const {
    status: createPlanStatus,
    countPlanId,
    group: createdGroup,
    onCheckInProgress,
    onSubmitInventoryPlan,
  } = useSubmitInventoryPlan();

  const { onDownloadGroupItems, status: downloadItemsStatus } =
    useDownloadGroupItems();
  const { onDownloadCountPlanOptions, status: downloadOptionsStatus } =
    useDownloadCountPlanOptions();

  useEffect(() => {
    if (
      downloadItemsStatus === 'success' &&
      downloadOptionsStatus === 'success'
    ) {
      setPlanResumed(true);
    }
  }, [downloadOptionsStatus, downloadItemsStatus]);

  useEffect(() => {
    if (planResumed && resumeGroup) {
      setPlanResumed(false);
      setResumeGroup(undefined);
      history.push(
        concatRoutes(
          inventoryURL(),
          countPlanURL(
            resumeGroup.countPlanId,
            resumeGroup.groupId,
            resumeGroup.miLoc,
            resumeGroup.uniqueId,
            resumeGroup.customerNo || ''
          )
        )
      );
    }
  }, [history, planResumed, resumeGroup]);

  const isSubmitting = createPlanStatus === 'loading';

  useEffect(() => {
    if (createPlanStatus === 'success') {
      setPlanCreated(true);
    }
  }, [createPlanStatus]);

  useEffect(() => {
    if (planCreated && countPlanId && createdGroup) {
      setPlanCreated(false);
      history.push(
        concatRoutes(
          inventoryURL(),
          countPlanURL(
            countPlanId,
            createdGroup.groupId,
            miLoc,
            createdGroup.uniqueId,
            customerNo || ''
          )
        )
      );
    }
  }, [history, countPlanId, customerNo, createdGroup, miLoc, planCreated]);

  const onSubmitItem = (customerItem = selectedCustomer) => {
    if (customerItem) {
      onSubmitInventoryPlan({
        planName: customerItem.name,
        miLoc: customerItem.miLoc,
        customerNo: customerItem.customerNo,
        billCustomerNo: customerNo,
        billCustomerName: toString(customerData?.name),
        countType: customerItem.countType,
      });
    }
  };

  const { headerActions } = useInventoryHeaderActions({});

  return (
    <IonPage data-testid="shipping-customer-list-page">
      <Header
        hideMenuButton
        hideCartButton
        hideNoticesBellButton
        testid="shipping-customer-list"
        customTitle={<InventoryCustomTitle title={customerData?.name} />}
        headerActions={headerActions}
      />
      <IonContent className={classes.content}>
        <Refresher
          slot="fixed"
          onRefresh={refetch}
          hidden
          testid="shipping-customer-refresher"
          disabled={showLoader || isSubmitting}
          lastUpdatedAt={lastUpdatedAt}
        />
        <IonRow className={classes.contentTitle}>
          <IonCol className={classes.col}>
            <Text
              text={t('inventory:startProfiles')}
              variant="mipro-h4-headline"
            />
          </IonCol>
        </IonRow>
        <IonLoading
          isOpen={isSubmitting}
          message={t('inventory:planGroupCreation')}
        />
        <IonLoading
          isOpen={
            downloadItemsStatus === 'loading' ||
            downloadOptionsStatus === 'loading'
          }
          message={t('inventory:downloadingData')}
        />
        <IonList>
          {isOnline &&
            map(shippingCustomers, (customerItem, index) => {
              const isLastCount =
                isNil(customerItem.lastOpenUser) &&
                !isNil(customerItem.lastCompletedUser);
              const isInProgress = !isNil(customerItem.lastOpenUser);
              const lastUser = isLastCount
                ? customerItem.lastCompletedUser
                : customerItem.lastOpenUser;
              const isAssigned = !isNil(customerItem.lastAssignedUser);
              const assignedUser = customerItem.lastAssignedUser;
              return (
                <ListItem
                  key={`${customerItem.customerNo}-${index}`}
                  withoutHrefArrow
                  testid="shipping-customer-item"
                  planIcon={getInventoryPlanIcon(customerItem.countType)}
                  onClick={async () => {
                    if (isSubmitting) {
                      return;
                    }
                    setSelectedCustomer(customerItem);
                    const inProgressGroup = await onCheckInProgress(
                      customerItem
                    );
                    if (inProgressGroup) {
                      setResumeGroup(inProgressGroup);
                      setResumeModal(true);
                    } else {
                      onSubmitItem(customerItem);
                    }
                  }}
                  className={classNames({
                    [classes.item]: !isLastCount,
                  })}
                  title={customerItem.name}
                  badge={{
                    text: isInProgress ? t('inventory:inProgressNoColon') : '',
                    icon: 'loader',
                  }}
                  alert={isInProgress ? t('inventory:inProgressMessage') : ''}
                  secondaryTextArray={
                    isLastCount || isInProgress
                      ? [
                          {
                            text: `${toString(
                              isLastCount
                                ? t('inventory:lastCount')
                                : t('inventory:inProgress')
                            )} ${formatSnoozeDate(
                              parseDate(
                                isLastCount
                                  ? customerItem.lastCompletedDate
                                  : customerItem.lastOpenDate
                              )
                            )}`,
                          },
                          {
                            text: t('common:lastUpdatedBy'),
                            children: (
                              <Text
                                variant="list-item-secondaryText"
                                text={toString(lastUser?.name)}
                                className={classes.nameText}
                              />
                            ),
                          },
                          {
                            text: isAssigned
                              ? t('inventory:primary')
                              : t('inventory:noPrimaryTeamMember'),

                            children: (
                              <Text
                                variant="list-item-secondaryText"
                                text={toString(assignedUser?.name)}
                                className={classes.nameText}
                              />
                            ),
                          },
                        ]
                      : []
                  }
                />
              );
            })}
        </IonList>
        {isEmptyResponse && (
          <WarningMessage
            icon={['far', 'info-circle']}
            title={t('inventory:warningMsg')}
            className={classes.warngMessage}
          />
        )}
        {!isOnline && (
          <WarningMessage
            icon={['far', 'info-circle']}
            title={t('inventory:offlineMsg')}
            className={classes.warngMessage}
          />
        )}
        <DiscardModal
          title={t('inventory:resumeCountTitle')}
          discardMsg={t('inventory:resumeCountMessage')}
          isOpen={resumeModal}
          setIsOpen={setResumeModal}
          initialBreakpoint={0.4}
          withRightCloseButton
          testid="resume-in-progress-modal"
          goBackButtonTitle={t('common:yes')}
          discardButtonTitle={t('common:no')}
          onGoBackClick={() => {
            if (resumeGroup) {
              onDownloadCountPlanOptions({
                countPlanId: resumeGroup.countPlanId,
              });
              onDownloadGroupItems({
                miLoc: resumeGroup.miLoc,
                countPlanId: resumeGroup.countPlanId,
                groupId: resumeGroup.groupId,
                groupUniqueId: resumeGroup.uniqueId,
                storeroomNo: resumeGroup.storeroomNo,
              });
            }
          }}
          onDiscardClick={onSubmitItem}
        />
        <Loader
          isOpen={showLoader && isEmpty(shippingCustomers)}
          text={t('inventory:loadingProfiles')}
          className={classes.loader}
        />
        <InfiniteScroll
          onLoadMore={fetchNextPage}
          testid="start-customer-infinite-scroll"
          disabled={!enableInfiniteScroll}
        />
      </IonContent>
    </IonPage>
  );
};

export default ShippingCustomerList;
