import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import { useMiLocOrTeamId } from 'api/helpers';
import type { Employee } from 'models/Employee';
import { pageSize } from 'utils/constants';

export const findFollowersQuerykey = 'find-followers';

interface UseFindFollowersProps {
  enabled?: boolean;
  query?: string;
  limit?: number;
  historyId: string;
}

interface UseFindFollowersResponse extends QueryFlags {
  items: Employee[];
  totalItems?: number;
}

const useFindFollowers = ({
  historyId,
  query,
  limit = pageSize(),
  enabled = false,
}: UseFindFollowersProps): UseFindFollowersResponse => {
  const { axios } = useAxios();
  const { findFollowersAPI } = useAPIUrl();
  const { getURLParams } = useMiLocOrTeamId({});
  const params: QueryParamsType = {
    query,
    limit,
    historyId,
  };

  const response = useQuerySetup<Employee, UseFindFollowersResponse>({
    queryKey: findFollowersQuerykey,
    queryParams: params,
    isInfiniteQuery: true,
    enabled,
    dataPath: 'rows',
    totalDataPath: 'totalRows',
    onAPIRequest: async ({ pageParam = 1, signal }) => {
      const { data } = await axios.get<UseFindFollowersResponse>(
        findFollowersAPI(
          historyId,
          getURLParams({ ...params, page: pageParam })
        ),
        {
          signal,
        }
      );

      return data;
    },
  });

  const { items, totalItems, ...queryFlags } = response;
  return { ...queryFlags, items: items as Employee[], totalItems };
};

export default useFindFollowers;
