import React, { useEffect, useImperativeHandle, useMemo } from 'react';
import classNames from 'classnames';
import {
  filter,
  includes,
  isArray,
  isEmpty,
  map,
  min,
  size,
  toNumber,
  toString,
} from 'lodash';
import type { PluginListenerHandle } from '@capacitor/core';
import type { InputChangeEventDetail } from '@ionic/react';
import {
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonTextarea,
} from '@ionic/react';
import Alert, { AlertVariantEnum } from 'common/components/Alert/Alert';
import type {
  BaseComponentProps,
  OptionalComponentProps,
  OptionalRenderProp,
} from 'common/components/utils/renderHelpers';
import {
  addKeyboardListener,
  onKeypadScroll,
  removeKeypadScroll,
  useNodeRef,
} from 'common/components/utils/renderHelpers';
import {
  and,
  choose,
  ifFunction,
  ifRender,
  or,
} from 'common/utils/logicHelpers';
import { scaleNumber } from 'common/utils/numberHelper';
import { useField } from 'formik';
import IMask from 'imask';
import type { FactoryOpts, MaskedNumberOptions } from 'imask';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './Input.module.scss';
import useInputMask from './useInputMask';
import useInputMaxLength from './useInputMaxLength';

/* eslint-disable react/require-default-props */
export interface InputMaskOptions {
  mask?: FactoryOpts['mask'];
  maskOptions?: FactoryOpts;
  numberMask?: OptionalRenderProp<MaskedNumberOptions, boolean>;
  currencyMask?: OptionalRenderProp<MaskedNumberOptions, boolean>;
  currencyType?: string;
}

interface InputProps extends InputMaskOptions, BaseComponentProps {
  name: string;
  customLabel?: React.ReactNode;
  // DOC: to support mask inputs, value is always a string
  value?: string;
  setValue?: (v: string) => void;
  // DOC: when using Yup array transformation the meta.error value becomes an array
  error?: string | string[];
  hideError?: boolean;
  warning?: string;
  info?: string;
  leftButton?: OptionalComponentProps<React.ComponentProps<typeof Button>>;
  rightButton?: OptionalComponentProps<React.ComponentProps<typeof Button>>;
  textarea?: boolean;
  rows?: number;
  autogrow?: boolean;
  viewonly?: boolean;
  toUpperCase?: boolean;
  focusScroll?: boolean;
  focusable?: boolean;
}
/* eslint-enable react/require-default-props */

export interface InputRef {
  focusInput: () => void;
  focusInputWithoutSelect: () => void;
}

const Input = React.forwardRef(
  (
    {
      className,
      label = '',
      customLabel,
      value = '',
      setValue,
      error,
      hideError = false,
      warning,
      info,
      leftButton,
      rightButton,
      onFocus,
      onBlur,
      disabled,
      required,
      textarea,
      viewonly,
      toUpperCase = false,
      focusScroll = true,
      // DOC: fields with errors should focus on user interaction
      focusable = true,
      ...props
    }: InputProps & React.ComponentProps<typeof IonInput>,
    outerRef: React.ForwardedRef<InputRef>
  ): JSX.Element => {
    const { node: inputRef, nodeRef } = useNodeRef<HTMLIonInputElement>();
    const { node: itemRef, nodeRef: setItemRef } =
      useNodeRef<HTMLIonItemElement>();

    let { autocapitalize, spellcheck, rows } = props;
    ifFunction(and(!textarea, spellcheck), () => {
      if (!rows) {
        rows = 1;
      }
      autocapitalize = 'sentences';
    });
    rows ||= 6;
    ifFunction(textarea, () => {
      autocapitalize = 'sentences';
      spellcheck = true;
    });
    if (viewonly) {
      rows = 1;
    }

    const Component = choose(
      or(textarea, spellcheck, viewonly),
      IonTextarea,
      IonInput
    ) as typeof IonInput | typeof IonTextarea;
    const { name, type, testid = name } = props;
    const readonly = or(viewonly, props.readonly);
    const autogrow = or(viewonly, props.autogrow);

    const {
      inputmode,
      isMasked,
      maskedInputRef,
      maskedValue,
      unmaskedValue,
      imaskOptions,
      numberMaskedBlock,
    } = useInputMask({ ...props, value, setValue, inputRef });

    const numericInput = or(
      type === 'number',
      includes(['numeric', 'decimal'], inputmode)
    );

    useEffect(() => {
      let keyboardListener: PluginListenerHandle | undefined;
      const doKeyboadListener = async () => {
        if (focusScroll) {
          keyboardListener = await addKeyboardListener('keyboardWillHide', () =>
            removeKeypadScroll(itemRef)
          );
        }
      };
      void doKeyboadListener();
      return () => {
        void keyboardListener?.remove();
      };
    }, [focusScroll, itemRef]);

    const errorValues = useMemo(() => {
      if (!error) {
        return [];
      }
      return filter(choose(isArray(error), error, [error])) as string[];
    }, [error]);

    const hasLabel = or(!!label, !!customLabel);
    const inputValue = toString(choose(isMasked, maskedValue, value));

    useEffect(() => {
      const autogrowInput = async () => {
        const inputEl = await inputRef?.getInputElement();
        ifFunction(!!inputEl, () => {
          inputEl.style.height = 'auto';
          inputEl.style.height = `${toString(
            choose(
              viewonly,
              inputEl.scrollHeight,
              min([inputEl.scrollHeight, 200])
            )
          )}px`;
        });
      };
      ifFunction(autogrow, () => {
        void autogrowInput();
      });
    }, [autogrow, inputRef, inputValue, viewonly]);

    const maxlength = toNumber(props.maxlength);
    const { showMaxLengthWarn, setCharCounterRef, playCharCounterAnimation } =
      useInputMaxLength({
        value,
        maxlength,
      });

    useImperativeHandle(
      outerRef,
      () => ({
        focusInput: async () => {
          const inputEl = await inputRef?.getInputElement();
          inputEl?.focus();
          inputEl?.select();
        },
        focusInputWithoutSelect: async () => {
          const inputEl = await inputRef?.getInputElement();
          inputEl?.focus();
        },
      }),
      [inputRef]
    );

    return (
      <IonItem
        ref={setItemRef}
        className={classNames(classes.input, className, {
          [classes.error]: error,
          [classes.warning]: warning,
          [classes.disabled]: disabled,
          [classes.readonly]: readonly,
          [classes.viewonly]: viewonly,
        })}
        lines="none"
        disabled={!!disabled}
        data-mipro-focusable={focusable}
        data-mipro-error={!isEmpty(error)}
        data-testid={testid}
        tabIndex={choose(disabled, -1)}
      >
        <IonCol>
          {ifRender(
            or(hasLabel, showMaxLengthWarn),
            <IonRow>
              {ifRender(
                hasLabel,
                <IonLabel className={classes.labelWrapper}>
                  <Text className={classes.label} text={label} testid="label" />
                  {ifRender(
                    required,
                    <Text
                      className={classes.requiredLabel}
                      text="*"
                      testid="required-label"
                    />
                  )}
                  {customLabel}
                </IonLabel>
              )}
              {ifRender(
                showMaxLengthWarn,
                <div ref={setCharCounterRef}>
                  <Text
                    className={classes.charCounter}
                    text={`${size(value)}/${maxlength}`}
                    testid="char-counter-text"
                  />
                </div>
              )}
            </IonRow>
          )}
          <IonRow className={classes.wrapper}>
            {ifRender(
              !!leftButton,
              <Button
                tabIndex={-1}
                variant="clear"
                testid="left-button"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...leftButton}
                disabled={or(disabled, leftButton?.disabled)}
              />
            )}
            <Component
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              readonly={readonly}
              aria-label={or(label, props.placeholder, name)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={(ref: HTMLIonInputElement) => {
                if (ref === null) {
                  return;
                }
                ifFunction(!inputRef, () => {
                  nodeRef(ref);
                });
                ifFunction(!maskedInputRef.current, () => {
                  maskedInputRef.current = ref;
                });
              }}
              className={classNames(classes.component, {
                [classes.toUpperCase]: toUpperCase,
              })}
              value={inputValue}
              onIonInput={(e: CustomEvent<InputChangeEventDetail>) => {
                ifFunction(!isMasked, () => {
                  const newValue = toString(e.detail.value);
                  setValue?.(newValue);
                });
              }}
              onIonFocus={async (e: CustomEvent<FocusEvent>) => {
                onFocus?.(
                  e as unknown as React.FocusEvent<HTMLIonInputElement>
                );
                if (numericInput) {
                  const inputEl = await inputRef?.getInputElement();
                  inputEl?.select();
                }
                ifFunction(focusScroll, () => {
                  onKeypadScroll(itemRef);
                });
              }}
              onIonBlur={async (e: CustomEvent<FocusEvent>) => {
                onBlur?.(e as unknown as React.FocusEvent<HTMLIonInputElement>);
                if (and(numericInput, isMasked, !!unmaskedValue)) {
                  const newValue = scaleNumber({
                    ...numberMaskedBlock,
                    number: unmaskedValue,
                  });
                  const masked = IMask.createMask(imaskOptions);
                  masked.resolve(newValue);
                  const inputEl = await inputRef?.getInputElement();
                  inputEl.value = masked.value;
                }
              }}
              onKeyDown={() => playCharCounterAnimation()}
              onWheel={() => {
                ifFunction(numericInput, () => {
                  (document.activeElement as HTMLElement)?.blur();
                });
              }}
              rows={rows}
              inputmode={inputmode}
              spellcheck={spellcheck}
              autocapitalize={autocapitalize}
              data-testid="input"
            />
            {ifRender(
              !!rightButton,
              <Button
                tabIndex={-1}
                variant="clear"
                testid="right-button"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rightButton}
                disabled={or(disabled, rightButton?.disabled)}
              />
            )}
          </IonRow>
          {ifRender(
            !hideError,
            map(errorValues, (err, idx) => (
              <Alert
                key={idx}
                text={err}
                variant={AlertVariantEnum.danger}
                className={classes.helperWrapper}
                testid="error-text"
              />
            ))
          )}
          {ifRender(
            warning,
            <Alert
              text={warning}
              className={classes.helperWrapper}
              testid="warning-text"
            />
          )}
          {ifRender(
            info,
            <Alert
              text={info}
              className={classes.helperWrapper}
              testid="warning-text"
              variant={AlertVariantEnum.info}
            />
          )}
        </IonCol>
      </IonItem>
    );
  }
);

export default Input;

export const FormikInput = (
  props: React.ComponentProps<typeof Input>
): JSX.Element => {
  // eslint-disable-next-line react/prop-types
  const { name, value, error, onBlur, setValue } = props;
  const [field, meta, helpers] = useField<string>({ name });

  return (
    <Input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={toString(or(field.value, value))}
      setValue={(v) => {
        setTimeout(() => {
          helpers.setTouched(true);
          helpers.setValue(v, true);
          setValue?.(v);
        });
      }}
      onBlur={(e) => {
        field.onBlur(e);
        onBlur?.(e);
      }}
      error={or(error, choose(meta.touched, meta.error, ''))}
    />
  );
};
