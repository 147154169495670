import { useSelector } from 'react-redux';
import { includes, isArray, isNil, pick, values } from 'lodash';
import type { RootState } from 'store/reducers';

export enum FeatureToggleType {
  viewVmiFeatureToggle,
  viewInventoryFeatureToggle,
  // currently it is used to view new EOM report
  viewNewReportToggle,
  shareProTip,
}

const accessFeatureToggles = (
  pFeatureToggles: FeatureToggleType | FeatureToggleType[]
) => {
  const response = [];
  let featureToggles = pFeatureToggles;
  if (!isArray(featureToggles)) {
    featureToggles = [featureToggles];
  }
  if (includes(featureToggles, FeatureToggleType.viewVmiFeatureToggle)) {
    response.push('VMI');
  }
  if (includes(featureToggles, FeatureToggleType.viewInventoryFeatureToggle)) {
    response.push('INVENTORY');
  }

  if (includes(featureToggles, FeatureToggleType.viewNewReportToggle)) {
    response.push('REPORTS_V2');
  }
  if (includes(featureToggles, FeatureToggleType.shareProTip)) {
    response.push('CR_PRO_TIP');
  }

  return response;
};

interface UseHasFeatureTogglesResponse {
  hasAccess: boolean;
  hasFeatureToggle: (featureToggles?: FeatureToggleType) => boolean;
}

export const useHasFeatureToggles = (
  propFeatureToggles?: FeatureToggleType | FeatureToggleType[]
): UseHasFeatureTogglesResponse => {
  const { featureToggles: stateFeatureToggles } = useSelector(
    (state: RootState) => state.user
  );

  // TODO: extract handling of environment variables to a utility function
  if (process.env.REACT_APP_DISABLE_FEATURE_TOGGLE === 'true') {
    return { hasAccess: true, hasFeatureToggle: () => true };
  }

  const hasFeatureToggle = (
    pFeatureToggles?: FeatureToggleType | FeatureToggleType[]
  ) => {
    const featureToggles = pFeatureToggles || propFeatureToggles;
    // if there is an access feature toggle request, it verifies an active value 'Y' for it
    // if not it defaults to true (the user has access by default)
    // this scenario applies to navigation, where some pages don't have feature toggle validation
    if (!isNil(featureToggles)) {
      return values(
        pick(stateFeatureToggles, accessFeatureToggles(featureToggles))
      ).includes('Y');
    }
    return true;
  };

  return { hasAccess: hasFeatureToggle(), hasFeatureToggle };
};

const useFeatureToggles = (
  featureToggles?: FeatureToggleType | FeatureToggleType[]
): boolean => {
  const { hasAccess } = useHasFeatureToggles(featureToggles);
  return hasAccess;
};

export default useFeatureToggles;
