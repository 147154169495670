import { toString } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { UpdateTaskBody } from 'ActivitiesApp/models/CrmActivity';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { getActivityQueryKey } from 'api/activities/useGetActivity';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { ActionCardActivity } from 'models/ActivityModels';
import { ToastType } from 'models/Toast';

interface UseUpdateActivityProps {
  userId?: string;
  taskId: number;
}

const useUpdateTask = ({ taskId }: UseUpdateActivityProps) => {
  const { axios } = useAxios();
  const { updateTaskAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const doUpdateTask = (body: UpdateTaskBody) => {
    const payload = {
      ...body,
      historyId: toString(body.historyId),
    };
    return doPromiseAPI(async (cancelToken) => {
      await axios.put(updateTaskAPI(taskId), payload, {
        cancelToken,
      });
    });
  };

  const onOptimisticUpdateTask = async ({ historyId }: UpdateTaskBody) =>
    onMutateUpdate<ActionCardActivity>({
      queryClient,
      queryKey: findActivitiesQueryKey,
      updatedItems: [{ historyId }],
      findPredicates: [{ historyId }],
      isInfiniteQuery: true,
    });

  const rollbackUpdateTask = (
    context?: UpdateMutationContext<ActionCardActivity>[]
  ) =>
    onErrorUpdate<ActionCardActivity>({
      queryClient,
      context,
      isInfiniteQuery: true,
    });

  const { status, mutate } = useMutation(doUpdateTask, {
    onSuccess: () => {
      void onSuccessMutation(queryClient, getActivityQueryKey, {
        taskId,
      });
      void onSuccessMutation(queryClient, findActivitiesQueryKey);
    },
    onError: (_error, _vars, context) => {
      addToast({
        type: ToastType.error,
        text: 'Update activity operation failed. Please try again later.',
        testid: 'update-activity-error-toast',
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: context as UpdateMutationContext<ActionCardActivity>[],
        isInfiniteQuery: false,
      });
    },
  });

  return {
    status,
    onUpdateTask: (body: UpdateTaskBody) => mutate(body),
    onOptimisticUpdateTask,
    rollbackUpdateTask,
  };
};

export default useUpdateTask;
