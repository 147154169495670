import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import Text from 'components/Text/Text';
import classes from './ActivitiesGroupContent.module.scss';

interface ActivitiesGroupContentProps {
  index: number;
  groupCount: number;
}

const ActivitiesGroupContent = ({
  index,
  groupCount,
}: ActivitiesGroupContentProps) => {
  const { t } = useTranslation('ActivityGroupContent');
  const titleMap: Dictionary<string> = {
    0: t('upcoming'),
    1: t('today'),
    2: t('last2Weeks'),
    3: t('older'),
  };
  return (
    <div
      key={index}
      className={classNames(
        {
          [classes.divider]: groupCount !== 0,
          [classes.hidden]: groupCount === 0,
        },
        classes.text
      )}
    >
      <Text variant="mipro-h6-headline" text={titleMap[index]} />
    </div>
  );
};

export default ActivitiesGroupContent;
