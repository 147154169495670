import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { head, includes, startCase, toString } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { and } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import useDisablePick12 from 'ReportsApp/hooks/useDisablePick12';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { SearchItemType } from 'models/Search';
import { SearchSuggestionTypeEnum } from 'models/Search';
import { colorHash, getUserInitials, withStringProp } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { getIsCorpAccount } from 'utils/search';
import ActionRow from 'components/ActionRow/ActionRow';
import Pick12Icon from 'components/Pick12Icon/Pick12Icon';
import Text from 'components/Text/Text';
import classes from './SearchSuggestionCard.module.scss';

type SearchSuggestionIconMap = Record<SearchSuggestionTypeEnum, IconName>;

interface SearchSuggestionCardProps {
  searchSuggestionType: SearchSuggestionTypeEnum;
  text: string;
  customerNo?: string;
  description?: string;
  address?: string;
  account?: string;
  searchType?: SearchItemType;
  isMainCard?: boolean;
  customerPick12?: boolean;
  showAvatar?: boolean;
}

const SearchSuggestionCard = ({
  id,
  className,
  searchSuggestionType,
  text,
  customerNo,
  textQuery,
  description = '',
  account = '',
  address = '',
  onClick,
  href,
  customerPick12,
  testid,
  searchType,
  isMainCard,
  showAvatar = true,
}: SearchSuggestionCardProps &
  React.ComponentProps<typeof ActionRow> &
  IonicReactProps): JSX.Element => {
  const ac = useAccessControls(AccessControlType.viewCustomers);
  const { t } = useTranslation(namespaces.search);
  const iconsMap: SearchSuggestionIconMap = {
    hint: 'history',
    search: 'search',
    result: 'chevron-right',
  };
  const withDescription =
    searchSuggestionType === SearchSuggestionTypeEnum.result &&
    withStringProp(description);
  const withAddress =
    searchSuggestionType === SearchSuggestionTypeEnum.result &&
    withStringProp(address);
  const withAccount =
    searchSuggestionType === SearchSuggestionTypeEnum.result &&
    withStringProp(account);

  let isCorpAccount = false;
  const isLinkedCorpAccount = withAccount && id === 'customers';
  if (searchType === 'customer' || searchType === 'corporateAccounts') {
    isCorpAccount = getIsCorpAccount(customerNo, account);
  }
  if (!isCorpAccount) {
    isCorpAccount = isLinkedCorpAccount;
  }

  const disabled = !ac && searchType === 'customer';

  const isPick12Disabled = useDisablePick12();

  return (
    <ActionRow
      className={classNames(className, classes.item, {
        [classes.resultItem]: withDescription,
      })}
      icon={findIcon(iconsMap[searchSuggestionType])}
      href={href}
      onClick={onClick}
      testid={testid}
      withArrow={false}
      disabled={disabled}
    >
      <IonRow className={classes.container}>
        {showAvatar && (
          <div
            className={classes.avatar}
            style={{
              backgroundColor: colorHash.hex(startCase(text)),
            }}
          >
            <Text
              className={classes.avatarTitle}
              text={
                includes(
                  ['employee', 'customerContact', 'supplierContact'],
                  searchType
                )
                  ? getUserInitials(text)
                  : toString(head(text))
              }
              variant="title-screen-section"
              testid={`${testid}-avatar`}
            />
          </div>
        )}
        <div className={classes.wrapper}>
          <div className={classes.firstLine}>
            {and(customerPick12, !isPick12Disabled) && (
              <Pick12Icon testid={`${testid}-search-suggestion-card`} />
            )}
            {isCorpAccount && (
              <FontAwesomeIcon
                icon={['fas', 'globe']}
                className={classes.corpIcon}
              />
            )}
            <Text
              className={classNames(classes.text, classes.title, {
                [classes.withMargin]: withDescription,
              })}
              text={text}
              textQuery={textQuery}
              testid={`${testid}-text`}
            />
          </div>
          {withDescription && (
            <IonRow>
              <Text
                className={classNames(classes.description, {
                  [classes.withMargin]: withAddress,
                })}
                variant="content-small"
                text={description}
                testid={`${testid}-description`}
                textQuery={textQuery}
              />
              {isMainCard && (
                <Text
                  className={classes.mainContact}
                  variant="content-small"
                  text={t('snapshot:mainContact')}
                  testid="contact-main-id"
                />
              )}
            </IonRow>
          )}
          {isLinkedCorpAccount && (
            <div className={classes.corpDesc}>
              <Text
                className={classNames(classes.text, classes.address)}
                variant="content-small"
                text={t('corpAcctDesc', { account })}
                testid={`${testid}-account`}
              />
            </div>
          )}
          {withAddress && (
            <Text
              className={classNames(classes.text, classes.address)}
              variant="content-small"
              text={address}
              testid={`${testid}-address`}
              textQuery={textQuery}
            />
          )}
        </div>
      </IonRow>
    </ActionRow>
  );
};

export default SearchSuggestionCard;
